import React from "react";
import { Card, Row, Col, Switch, Popconfirm } from "antd";
import styled from "styled-components";
import moment from "moment";

const StyledEventCard = styled(Card)`
  background-image: url(${(props) => props.item.thumbnail_cover_image});
  background-repeat: no-repeat;
  background-size: cover;
  height:${(props) => (!props.myEvent ? "11rem" : "12.5rem")};
  &.ant-card .ant-card-body {
    padding: 0;
  }
  .card-col p {
    margin: 0;
  }
  .card-col {
    border: solid lightgrey 1px;
    background: rgb(0, 169, 157, 0.5);
    border-bottom: none;
    border-top: none;
    margin-top: 6.9rem;
    border-right: none;
    height: 4rem;
  }
  .event-heading {
    color: white;
    margin: 0;
    text-align: left;
    margin-left: 1rem;
  }
  .event-description {
    color: white;
    font-size: 11.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  .event-date {
    font-size: 15px;
    font-weight: bold;
    color: white;
  }
  .event-date-time{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.ant-switch-handle::before{
  background-color: #15b9bb !important;
}
  }
`;
function EventCard(props) {
  const { item, myEvent, updateStatus } = props;
  return (
    <StyledEventCard {...props}>
      {myEvent && (
        <Row justify="end">
          <Col
            span={4}
            align="right"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Popconfirm
              title="Are you sure you want to mark this event as complete?"
              okText="Yes"
              disabled={item.status === "completed"}
              cancelText="No"
              onConfirm={function () {
                updateStatus(item.id);
              }}
            >
              <Switch
                style={{ backgroundColor: "white" }}
                disabled={item.status === "completed"}
                checked={item.status === "completed"}
              />
            </Popconfirm>
          </Col>{" "}
        </Row>
      )}

      <Row>
        <Col lg={16} md={16} sm={14} xs={14} xl={16} className="card-col">
          <h3 className="event-heading">{item?.name}</h3>
          <p
            style={{ marginLeft: "1rem" }}
            className="event-description"
            title={item?.description}
          >
            {item?.description}
          </p>
        </Col>
        <Col lg={8} md={8} sm={10} xs={10} xl={8} className="card-col">
          <div className="event-date-time">
            <span className="event-date">Date:</span>
            <span className="event-description">
              {moment(item?.event_date).format("L")}
            </span>
          </div>
          <div className="event-date-time">
            <span className="event-date">Time:</span>
            <span className="event-description">
              {moment(item.event_date).utc().format("LT")}
            </span>
          </div>
        </Col>
      </Row>
    </StyledEventCard>
  );
}
export default EventCard;
