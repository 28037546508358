import Axios from "axios";
// import AsyncStorage from "@react-native-community/async-storage";
import { AuthAction } from "../../store/actions";
import { Store } from "../../store";
// import { NavigationService } from "..";
import variables from "../variables";
import info from "../../components/info";
const { baseUrl } = variables;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  ({ response }) => {
    
    if (response.data.status == 401) {
      try {
        window.localStorage.removeItem("eventAuth");
        Store.dispatch(AuthAction.SignoutSuccess());
        // NavigationService.reset_0("SignIn")

        
      } catch (err) {}
    }
    if (response.data.status == 400) {
      try {
        // window.localStorage.removeItem("eventAuth").then(() => {
        //     Store.dispatch(AuthAction.SignoutSuccess());
        //     // NavigationService.reset_0("SignIn")
        // })
        if(response.data.error.message!=='Linked account is already created'){

          info("error", `${response.data.error.message}`);
        }

      } catch (err) {}
    }
    
    return response;
  }
);
Axios.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("eventAuth")) {
      config.headers = {
        ...config?.headers,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PATCH, DELETE",
        "user-id": JSON.parse(localStorage.getItem("eventAuth"))?.user?.id,
        "x-auth-token": JSON.parse(localStorage.getItem("eventAuth"))
          ?.x_auth_token,
      };
      return config;
    } else {
      return config;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default class ApiCaller {
  static Get = (endPoint = "", headers = {}, customUrl = "") => {
    if (headers["user_id"]) {
      headers["user-id"] = headers["user_id"];
      headers["access-token"] = headers["access_token"];
    }
    return Axios.get(customUrl ? customUrl : `${baseUrl}${endPoint}`, {
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Post = (endPoint = "", body = {}, headers = {}) => {
    if (headers["user_id"]) {
      headers["user-id"] = headers["user_id"];
      headers["access-token"] = headers["access_token"];
    }
    return Axios.post(`${baseUrl}${endPoint}`, body, {
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Put = (endPoint = "", body = {}, headers = {}) => {
    if (headers["user_id"]) {
      headers["user-id"] = headers["user_id"];
      headers["access-token"] = headers["access_token"];
    }
    return Axios.put(`${baseUrl}${endPoint}`, body, {
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Delete = (endPoint = "", headers = {}, body = {}) => {
    if (headers["user_id"]) {
      headers["user-id"] = headers["user_id"];
      headers["access-token"] = headers["access_token"];
    }
    return Axios.delete(`${baseUrl}${endPoint}`, {
      headers,
      data: body,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
  static Patch = (endPoint = "", headers = {}, body = {}) => {
    if (headers["user_id"]) {
      headers["user-id"] = headers["user_id"];
      headers["access-token"] = headers["access_token"];
    }
    return Axios.patch(`${baseUrl}${endPoint}`, {
      headers,
      data: body,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
}
