import {
  UPDATE_PROFILE_REQ,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  GET_NOTIFICATION_REQ,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  CREATE_SUBSCRIPTION_ERROR,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_REQ,
  CHECK_ACCOUNT_ERROR,
  CHECK_ACCOUNT_REQ,
  CHECK_ACCOUNT_SUCCESS,
  GET_SUBSCRIPTION_PLANE_REQ,
  GET_SUBSCRIPTION_PLANE_SUCCESS,
  GET_SUBSCRIPTION_PLANE_ERROR,
} from "../constants";
const initialState = {
  isLoading: false,
  profileDetail: {},
  error: null,
  notification: [],
  isGettingNotifiaction: false,
  isLoading: false,
  data: {},
  subscriptionPlan:[]
};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROFILE_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
        profileDetail: {},
      };
    }
    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        profileDetail: action.res,
      };
    }
    case UPDATE_PROFILE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        profileDetail: {},
      };
    }
    case GET_NOTIFICATION_REQ: {
      return {
        ...state,
        isGettingNotifiaction: true,
        error: null,
        notification: [],
      };
    }
    case GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isGettingNotifiaction: false,
        error: null,
        notification: action.res,
      };
    }
    case GET_NOTIFICATION_ERROR: {
      return {
        ...state,
        isGettingNotifiaction: false,
        error: action.error,
        notification: [],
      };
    }
    case CREATE_SUBSCRIPTION_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case CREATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case CREATE_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case CHECK_ACCOUNT_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case CHECK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.res,
      };
    }
    case CHECK_ACCOUNT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case GET_SUBSCRIPTION_PLANE_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_SUBSCRIPTION_PLANE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        subscriptionPlan: action.res,
      };
    }
    case GET_SUBSCRIPTION_PLANE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    default: {
      return { ...state };
    }
  }
}
