import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import Colors from "../../config/colors";

const StyledBackButton = styled(Button)`
  background-color: transparent;
  cursor: pointer;
  width:${props => props.width ? props.width : '100%'};
  height: 2rem;
  border:none;
  box-shadow:none;

  &.ant-btn:hover, .ant-btn:focus{
      background:none
  }
`;

const BackButton = (props) => {
  return <StyledBackButton {...props}></StyledBackButton>;
};

export default BackButton;
