import React from "react";
import { Row, Col } from "antd";
import { Checkbox } from "antd";

// function onChange(e) {
// }

const AddUserClass = (props) => {
  const { item } = props;
  console.log("ItemEventPlus", item);
  return (
    <Row gutter={[10, 0]}>
      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
        <Checkbox
          onChange={props.onChange}
          value={item.id}
          checked={props.isChecked}
        ></Checkbox>
      </Col>
      <Col xs={20} sm={12} md={20} lg={12} xl={12} title={item.name}>
        {item.name.length < 10
          ? `${item.name}`
          : `${item.name.substring(0, 10)}...`}
        {console.log("itemlength", item.name.length)}
      </Col>
    </Row>
  );
};

export default AddUserClass;
