import { Col, Row } from "antd";
import React from "react";
import { FaRegEdit } from "react-icons/fa";

import DeleteComponent from "../deleteComponent";
function DressageListing(props) {
  const { deleteItem, data, isUpdate,editedIndex,editDressage } = props;
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col lg={22} xs={24} sm={12} md={8}>
          <Row>
            <Col lg={4} sm={24} xs={24}>
              <h4>{data?.DressageTest?.name}</h4>
            </Col>
            <Col lg={4} sm={24} xs={24}>
              <p>{data?.DressageClass?.UserClass?.name}</p>
            </Col>{" "}
            <Col lg={4} sm={24} xs={24}>
              <p style={{ textAlign: "center" }}>
                Judge : {data?.Judge?.firstName} {data?.Judge?.lastName}
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={2} xs={12} sm={12} md={4} style={{margin:"auto"}}>
          {/* {isUpdate && <FaRegEdit color="#00A99D" size={20}/>} */}
          <Row align='middle'>
            <Col span={4} style={{margin:'auto'}}>{isUpdate &&  <FaRegEdit className='pointer-cursor' onClick={()=>editDressage(editedIndex)} color="#00A99D"  size={25}/>}</Col>
            <Col span={16}>
              {" "}
              <DeleteComponent
                onClick={() => deleteItem({ dressageId: data.id })}
              />
            </Col>
          </Row>{" "}
        </Col>
      </Row>
    </>
  );
}
export default DressageListing;
