import * as Yup from "yup";

export const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Full Name is Too Short! it must be between 3 to 10 characters")
    .max(10, "Full Name is Too Long! it must be between 3 to 10 characters")
    .required("First Name required"),
  lastName: Yup.string()
    .min(3, "Last Name is Too Short! it must be between 3 to 10 characters")
    .max(10, "Last Name is Too Long! it must be between 3 to 10 characters")
    .required("Last Name required"),
  email: Yup.string().email("Invalid email").required("Email required"),
  companyName: Yup.string()
    .min(5, "Company Name is Too Short! it must be between 5 to 50 character")
    .max(50, "Company Name is Too Long! it must be between 5 to 50 character")
    .required("Company Name required"),
  dob: Yup.string().required("Date of Birth reuqired"),
  image: Yup.string().required("Image is Required"),
  gender: Yup.string().required("Gender is required"),
});
