import React from "react";
import { Row, Col } from "antd";

function HorizontalLine({ width }) {
  return (
    <Row justify="center" style={{ width, margin: "auto" }}>
      <Col lg={24} sm={24} xs={24} md={24} className="hr-line">
        <hr
          style={{
            height: "3px",
            backgroundColor: "#E9E9E9",
            border: "none",
          }}
        />
      </Col>
    </Row>
  );
}
export default HorizontalLine;
