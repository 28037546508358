import { loginSchema } from "./login";
import { profileSchema } from "./signup";
import { verificationCodeSchema } from "./verificationCode";
import { createEvent1Schema } from "./createEvent1";
import { createEvent2Schema } from "./createEvent2";
import { createEvent3Schema } from "./createEvent3";
import { createEvent4Schema } from "./createEvent4";
import { fp1Schema } from "./fp1";
import { fp2Schema } from "./fp2";
import { fp3Schema } from "./fp3";
import { socialSignupSchema } from "./socialSignup";
import { Showjumping } from "./showjumping";
import { CrossCountry } from "./crosscountry";
import { DressageSchema } from "./dressage";
import { EditProfileSchema } from "./editProfileEvent";
const loginValidations = loginSchema;
const profileValidations = profileSchema;
const verificationValdations = verificationCodeSchema;
const createEvent1Validations = createEvent1Schema;
const createEvent2Validations = createEvent2Schema;
const createEvent3Validations = createEvent3Schema;
const createEvent4Validations = createEvent4Schema;
const fp1Validations = fp1Schema;
const fp2Validations = fp2Schema;
const fp3Validations = fp3Schema;
const socialSignupValidations = socialSignupSchema;
const showJumpingValidation = Showjumping;
const crossCountryValidations = CrossCountry;
const DressageSchemas = DressageSchema;
const editProfileEvents = EditProfileSchema;
export {
  loginValidations,
  profileValidations,
  verificationValdations,
  socialSignupValidations,
  createEvent1Validations,
  createEvent2Validations,
  createEvent3Validations,
  createEvent4Validations,
  fp1Validations,
  fp2Validations,
  fp3Validations,
  showJumpingValidation,
  crossCountryValidations,
  DressageSchemas,
  editProfileEvents
};
