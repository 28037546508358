import React from "react";
import PaymentForm from "./paymentForm";
import { Row, Col } from "antd";
import EventHeading from "../../components/eventsHeading";
function Payment_form(props) {
  return (
    <>
      <Row>
        <Col  sm={24} md={24} xs={24} lg={24}>
          <EventHeading
            goBack={() => props.history.push("/subscription")}
            arrowBtn={true}
            title={"Payment"}
          />
        </Col>
      </Row>

      <PaymentForm {...props} />
    </>
  );
}
export default Payment_form;
