import { createStore, applyMiddleware } from "redux";
import RootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
// import { createLogger } from "redux-logger";
import { Sagas } from "./sagas";

const sagaMiddleware = createSagaMiddleware();

export const Store = createStore(
  RootReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(Sagas);

