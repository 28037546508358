import React from "react";
import { Row, Col } from "antd";

function SubscriptionCard(props) {
  return (
    <Row
      style={{
        height: "400px",
        width: "50%",
        margin: "auto",
        backgroundColor: "white",
        borderRadius: "8px",
      }}
    >
      <Col span={24} style={{ marginTop: "2rem" }}>
        <Row justify="center">
          <Col span={24}>
            <h1>{props.plan}</h1>
          </Col>
        </Row>
        <Row
          justify="center"
          style={{
            backgroundColor: props.color,
            height: "4rem",
            marginTop: "2rem",
          }}
        >
          <Col>
            <h1
              style={{ color: "white", fontSize: "2.7rem", fontWeight: "700" }}
            >
              {props.price}
            </h1>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: "2rem" }}>
          <Col>
            <p className="m-zero">{props.description1} </p>
            <p className="m-zero">{props.description2}</p>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: "grey",
            height: "4rem",
            position: "absolute",
            top: "82.7%",
            bottom: 0,
            right: 0,
            left: 0,
            // borderRadius: "8px",
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            width: "100%",
          }}
          justify="center"
          align='center'
        >
          <Col onClick={props.push} className='pointer-cursor'>
            <h1
              style={{ color: "white", fontSize: "1.8rem", fontWeight: "5 00" }}
            >
              BUY NOW
            </h1>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default SubscriptionCard;
