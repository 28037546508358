import { AuthAction } from "../actions";
// import { Alert } from "react-native"
import { Functions, ApiCaller, Variables } from "../../config";
import info from "../../components/info";
import { put, delay } from "redux-saga/effects";
import { UPDATE_PASSWORD } from "../../config/variables";
// import AsyncStorage from '@react-native-community/async-storage';

export default class AuthMiddleware {
  static *SignUp(params) {
    const { payload, cb } = params;
    const { dob } = payload;
    const dateOfBirth = dob.split("/").reverse().join("-");
    try {
      let res = yield ApiCaller.Post(
        `auth/signup`,
        { ...payload, role: "event manager", dob: dateOfBirth },
        {
          Accept: "multipart/form-data",

          "Content-Type": "application/json",
        }
      );
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(AuthAction.SignUpSuccess());

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        yield put(AuthAction.SignUpFailure());
        // Functions.showToast(res["data"]["error"]["message"], "danger")
        info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(AuthAction.SignUpFailure());
    }
  }

  static *SignOut(params) {
    const { payload, cb } = params;
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    try {
      let res = yield ApiCaller.Post(
        `auth/logout`,
        {},
        {
          Accept: "multipart/form-data",

          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        info("success", res.data.data);
        localStorage.removeItem("eventAuth");
        localStorage.removeItem("eventId");
        localStorage.removeItem("eventClassId");
        localStorage.removeItem("countryId");
        localStorage.removeItem("showJumpingId");
        yield put(AuthAction.SignoutSuccess());

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        // info("error", res.data.error);
        yield put(AuthAction.SignoutFailure());
        // Functions.showToast(res["data"]["error"]["message"], "danger")
      }
    } catch (err) {
      yield put(AuthAction.SignoutFailure());
    }
  }

  static *SignIn(params) {
    const { payload, cb, callback } = params;
    let firebaseToken = localStorage.getItem("FirebaseToken");
    const body = {
      ...payload,
      deviceToken: firebaseToken || "",
      deviceType: "web",
    };
    if (!firebaseToken) {
      delete body.deviceToken;
    }
    try {
      let res = yield ApiCaller.Post(`auth/login`, body);
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(AuthAction.SignInSuccess(res.data));
        cb && cb();
        // replace("Snapshot")
        localStorage.setItem("eventAuth", JSON.stringify(res.data.data));
      } else {
        yield put(AuthAction.SignInFailure());
        info("error", res.data.error.message);

        if (res.data.data.status == 0) {
          let newPayload = {
            email: payload.email,
            cb: callback,
          };

          yield put(
            AuthAction.ForgotPassword({ email: payload.email, cb: callback() })
          );
          // callback && callback()
        }
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }

  static *VerifyCode(params) {
    const { payload, cb } = params;
    // cb()
    try {
      let res = yield ApiCaller.Post(`auth/verify-code`, payload);
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(AuthAction.VerficationCodeSuccess(res.data));
        cb && cb();
        // replace("Snapshot")
      } else {
        info("error", res.data.error.message);
        yield put(AuthAction.VerficationCodeFailure());
        let msg = res["data"]["error"]["message"];
        if (msg == "Unverified email.") {
        } else {
          yield put(AuthAction.VerifyCode());
        }
      }
    } catch (err) {
      yield put(AuthAction.VerficationCodeFailure());
    }
  }

  static *ForgotPassword(params) {
    const { payload, cb } = params;

    try {
      let res = yield ApiCaller.Post(`auth/forget-password`, payload);
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(AuthAction.ForgotPasswordSuccess(res.data));
        cb && cb();
        // callback && callback();
        // replace("Snapshot")
      } else {
        info("error", res.data.error.message);
        yield put(AuthAction.ForgotPasswordFailure());
        let msg = res["data"]["error"]["message"];
        if (msg == "Unverified email.") {
          // Alert.alert(
          //     "Email Verification",
          //     "Your email is not verified, do you want to resend email for verification?",
          //     [
          //         {
          //             text: "Resend",
          //             onPress: () => {
          //                 Store.dispatch(AuthAction.SendCode({
          //                     email: payload.email,
          //                     current_time: Functions.currentTime()
          //                 }, "SignUpVerify"))
          //             },
          //             style: "default"
          //         },
          //         {
          //             text: "Cancel",
          //             onPress: () => {
          //                 Functions.showToast(msg, "danger")
          //             },
          //             style: "cancel"
          //         }
          //     ],
          //     {
          //         cancelable: false
          //     }
          // )
        } else {
          yield put(AuthAction.ForgotPasswordFailure());
        }
      }
    } catch (err) {
      yield put(AuthAction.ForgotPasswordFailure());
    }
  }

  static *ResetPassword(params) {
    const { payload, cb } = params;

    let rest = {
      ...payload,
    };
    delete rest.confirmPassword;

    try {
      let res = yield ApiCaller.Post(`auth/change-password`, rest);
      if (res.status == 200) {
        info("success", `Success`);
        yield put(AuthAction.ResetPasswordSuccess(res.data));
        cb && cb();
        // replace("Snapshot")
      } else {
        info("error", res.data.error.message);
        yield put(AuthAction.ResetPasswordFailure());
        let msg = res["data"]["error"]["message"];
        if (msg == "Unverified email.") {
          // Alert.alert(
          //     "Email Verification",
          //     "Your email is not verified, do you want to resend email for verification?",
          //     [
          //         {
          //             text: "Resend",
          //             onPress: () => {
          //                 Store.dispatch(AuthAction.SendCode({
          //                     email: payload.email,
          //                     current_time: Functions.currentTime()
          //                 }, "SignUpVerify"))
          //             },
          //             style: "default"
          //         },
          //         {
          //             text: "Cancel",
          //             onPress: () => {
          //                 Functions.showToast(msg, "danger")
          //             },
          //             style: "cancel"
          //         }
          //     ],
          //     {
          //         cancelable: false
          //     }
          // )
        } else {
          yield put(AuthAction.ResetPasswordFailure());
        }
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }

  static *SocialSignIn(params) {
    const { payload, cb } = params;
    console.log("PARAMS==>", params);

    try {
      let res = yield ApiCaller.Post(
        `auth/social`,
        { ...payload, deviceType: "web" },
        {
          "Content-Type": "application/json",
        }
      );
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(AuthAction.SocialSignInSuccess(res.data.data));
        cb && cb(res.data.data);

        localStorage.setItem("eventAuth", JSON.stringify(res.data.data));
        yield put(AuthAction.SignInSuccess(res.data.data));


        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        // if(cb){
        //   cb(res.data.data)

        // }
      } else {
        yield put(AuthAction.SocialSignInError());
        // Functions.showToast(res["data"]["error"]["message"], "danger")
        info("%c{res}", "color: red", " => ", res);
      }
    } catch (err) {
      yield put(AuthAction.SocialSignInError());
    }
  }

  static *SocialExtraDetails(params) {
    const { payload, cb } = params;
    const dateOfBirth = payload.dob.split("/").reverse().join("-");

    try {
      let res = yield ApiCaller.Put(
        `auth/social-detail`,
        {
          gender: payload.gender,
          dob: dateOfBirth,
          companyName: payload.companyName,
          role: "event manager",
        },
        {
          "Content-Type": "application/json",
          "x-auth-token": payload.token,
        }
      );
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(AuthAction.SocialExtraDetailsSuccess(res.data.data.user));
        localStorage.setItem("eventAuth", JSON.stringify(res.data.data));

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        yield put(AuthAction.SocialExtraDetailsError());
        // Functions.showToast(res["data"]["error"]["message"], "danger")
        info("%c{res}", "color: red", " => ", res);
      }
    } catch (err) {
      yield put(AuthAction.SocialExtraDetailsError());
    }
  }

  static *updatePassword(params) {
    const { payload, cb, push } = params;

    try {
      let res = yield ApiCaller.Post(
        `${UPDATE_PASSWORD}`,
        { ...payload },
        {
          "Content-Type": "application/json",
        }
      );
      if (res.status == 200) {
        info("success", res.data.data.message);
        // yield put(AuthAction.SocialSignInSuccess(res.data.data));

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
        push() && push();
      } else {
        cb && cb();

        // Functions.showToast(res["data"]["error"]["message"], "danger")
        info("%c{res}", "color: red", " => ", res);
      }
    } catch (err) {}
  }
}
