import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  LOADER_TRUE,
  LOADER_FALSE,
  VERIFICATION_CODE,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_CODE_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SIGNUP,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SIGNOUT,
  SIGNOUT_FAILURE,
  SIGNOUT_SUCCESS,
  SOCIAL_SIGN_IN_REQ,
  SOCIAL_SIGN_IN_SUCCESS,
  SOCIAL_SIGN_IN_ERROR,
  SOCIAL_EXTRA_DETAILS_REQ,
  SOCIAL_EXTRA_DETAILS_SUCCESS,
  SOCIAL_EXTRA_DETAILS_ERROR,
} from "../constants";

const initialState = {
  user: localStorage.getItem("eventAuth")
    ? JSON.parse(localStorage.getItem("eventAuth"))?.user
    : null,
  loader: false,
  socialAuth: {},
  isAuthenticated: localStorage.getItem("eventAuth") ? true : false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP:
      state = {
        ...state,
        loader: true,
      };
      break;

    case SIGNUP_SUCCESS:
      state = {
        ...state,
        loader: false,
      };
      break;

    case SIGNUP_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;

    case SIGNIN:
      state = {
        ...state,
        loader: true,
      };
      break;

    case SIGNIN_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        loader: false,
        isAuthenticated: true,
      };
      break;

    case SIGNIN_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;

    case SIGNOUT:
      state = {
        ...state,
        loader: true,
      };
      break;

    case SIGNOUT_SUCCESS:
      state = {
        ...state,
        user: null,
        loader: false,
        isAuthenticated: false,
      };
      break;

    case SIGNOUT_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;

    case VERIFICATION_CODE:
      state = {
        ...state,
        loader: true,
      };
      break;
    case VERIFICATION_CODE_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;
    case VERIFICATION_CODE_SUCCESS:
      state = {
        ...state,
        loader: false,
      };
      break;

    case RESET_PASSWORD:
      state = {
        ...state,
        loader: true,
      };
      break;
    case RESET_PASSWORD_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loader: false,
      };
      break;

    case FORGOT_PASSWORD:
      state = {
        ...state,
        loader: true,
      };
      break;
    case FORGOT_PASSWORD_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;
    case FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        loader: false,
      };
      break;

    case SOCIAL_SIGN_IN_REQ:
      state = {
        ...state,
        loader: true,
      };        
      break;
    case SOCIAL_SIGN_IN_ERROR:
      state = {
        ...state,
        loader: false,
      };

      break;
    case SOCIAL_SIGN_IN_SUCCESS:
     state.isAuthenticated= localStorage.getItem("eventAuth") ? true : false
      state = {
        ...state,
        loader: false,
        socialAuth: action.payload,
        
      };
      break;

    case SOCIAL_EXTRA_DETAILS_REQ:
      state = {
        ...state,
        loader: true,
      };
      break;
    case SOCIAL_EXTRA_DETAILS_SUCCESS:
      state = {
        ...state,
        loader: false,
        socialAuth: action.payload,
      };

      break;
    case SOCIAL_EXTRA_DETAILS_ERROR:
      state = {
        ...state,
        loader: false,
      };
      break;

    case LOADER_TRUE:
      state = {
        ...state,
        loader: true,
      };
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        loader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
