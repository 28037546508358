import React, { Component } from "react";
import { connect } from "react-redux";
import { EventAction } from "../../store/actions";
import EventHeading from "../../components/eventsHeading";
import { Row, Col } from "antd";
import Select, { components } from "react-select";
class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectedEvent: false,
      testId:''
    };
  }
  componentDidMount() {
    this.props.getCompletedEvent();
  }
  render() {
    const customStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma('lightblue');
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "lightblue"
            : isFocused
            ? "#eaeaea"
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? "#eaeaea"
              ? "black"
              : "black"
            : "black",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? "lightblue" : "#eaeaea"),
          },
        };
      },
      input: (styles) => ({ ...styles }),
      placeholder: (styles) => ({ ...styles }),
      singleValue: (styles, { data }) => ({ ...styles }),
    };
    const eventOptions = (this.props.completedEvent||[]).map((item) => {
      return { label: item.name, value: item.id };
    });
    const userClass = (this.props.eventClass || []).map((item) => {
      return { label: item.UserClass?.name, value: item.id };
    });
    return (
      <>
        <Row justify="space-around">
          <Col span={12}>
            <EventHeading title="Leaderboard" />
          </Col>
          <Col span={12}>
            <Row justify="space-around">
              <Col span={10}>
                {" "}
                <Select
                  options={userClass}
                  className="userSelect"
                  //placeholder="Class"
                  name="testId"
                  isDisabled={!this.state.isSelectedEvent}
                  value={this.state.testId}
                  onChange={(e) => {
                    this.setState({
                      testId: e,
                    });
                    this.props.getLeaderboard(e.value);
                  }}
                />
              </Col>
              <Col span={10}>
                <Select
                  options={eventOptions}
                  className="userSelect"
                  placeholder="Select Event Name"
                  name="testId"
                  onChange={(e) => {
                    this.props.getClassByEvent(e.value);
                    this.setState({
                      isSelectedEvent: true,
                      testId:''
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "3rem" }}>
          <Col span={24}>
            <table className="rider-table-leaderboard m-auto">
              <tr>
                <th className="text-capitilize tableRow">ID</th>
                <th className="text-capitilize tableRow">Rider Name</th>
                <th className="text-capitilize tableRow">Dressage</th>
                <th className="text-capitilize tableRow">ShowJumping</th>
                <th className="text-capitilize tableRow">Cross Country</th>
                <th className="text-capitilize tableRow">Total</th>
              </tr>
          
              {this.props.leaderboard.map((item) => {
                return (
                  <tr>
                    <td>{item.id} </td>
                    <td>{item.fullName} </td>
                    <td>{item.DressagesMarks} </td>
                    <td>{item.ShowJumpingMarks} </td>
                    <td>{item.CrossCountryMarks} </td>
                    <td>
                      {+item.DressagesMarks +
                        +item.CrossCountryMarks +
                        +item.ShowJumpingMarks}
                    </td>
                  </tr>
                );
              })}
            </table>
            {!this.state.isSelectedEvent&&<h2>Select Event Name </h2>}
            {this.state.isSelectedEvent && !this.state.testId&& <h2>Select Event Class</h2>}

            {!this.props.leaderboard.length && this.state.testId&&<h2>Results not published yet</h2>}

          </Col>
        </Row>
      </>
    );
  }
}
function mapStateToProps(store) {
  
  return {
    completedEvent: store.EventReducer?.completeEvent,
    eventClass: store.EventReducer.classByEvent,
    leaderboard: store.EventReducer.leaderBoard,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getCompletedEvent: () => dispatch(EventAction.getCompleteEventReq()),
    getClassByEvent: (id) => dispatch(EventAction.getClassByEventReq(id)),
    getLeaderboard: (id) => dispatch(EventAction.getLeaderboardReq(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
