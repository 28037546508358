import React from "react";
import { Row, Col } from "antd";
import EventHeading from "../../components/eventsHeading";
import moment from "moment";
import Button from "../../components/button/Button";
import { UserAction } from "../../store/actions";
import { connect } from "react-redux";
class Upgrade_payment extends React.Component {
  render() {
    const userSubscriptionDetail = JSON.parse(
      localStorage.getItem("eventAuth")
    );
    return (
      <Row justify="center">
        <Col span={24} sm={24} md={24} xs={24} lg={24}>
          <EventHeading
            arrowBtn={true}
            title={"Subscription"}
            goBack={() => this.props.history.push("/settings")}
          />
        </Col>
        <Col span={24} style={{ marginTop: "4rem" }}>
          <Row justify="center">
            <Col
              span={10}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1rem",
                borderRight: "1px grey solid",
              }}
            >
              <div>
                <h1>
                  {userSubscriptionDetail?.user?.Subscriptions?.[0]
                    ?.subscriptionPlanId == 1
                    ? "Monthly Plan"
                    : "Annual Plan"}
                </h1>
                <span style={{fontSize:'24px'}}>
                  {" "}
                  £{userSubscriptionDetail?.user?.Subscriptions?.[0]?.price/100}
                </span>
              </div>
            </Col>

            <Col
              span={10}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div>
                <Row align="center">
                  <Col span={14}>
                    <h2>Start Date:</h2>
                  </Col>
                  <Col span={10} className="d-flex align-item-middle">
                    <span>
                      {moment(
                        userSubscriptionDetail?.user?.Subscriptions?.[0]
                          ?.createdAt
                      ).format("MMM Do YY")}
                    </span>
                  </Col>
                  <Col span={14}>
                    <h2>Expiry Date:</h2>
                  </Col>
                  <Col span={10} className="d-flex align-item-middle">
                    <span>
                      {" "}
                      {moment(
                        userSubscriptionDetail?.user?.Subscriptions?.[0]
                          ?.endDate
                      ).format("MMM Do YY")}{" "}
                    </span>
                  </Col>
                </Row>
              </div>
              {/* <h2>Expiry Date:</h2> */}
            </Col>
          </Row>
        </Col>
        <Col
          lg={4}
          xl={4}
          md={6}
          sm={12}
          style={{ marginTop: "4rem", marginRight: "1rem" }}
        >
          <Button onClick={() => this.props.history.push("/subscription")}>
            Buy again
          </Button>
        </Col>
        <Col lg={4} xl={4} md={6} sm={12} style={{ marginTop: "4rem" }}>
          <Button onClick={() => this.props.canSubscription()}>
            Cancel Subscription
          </Button>
        </Col>
      </Row>
    );
  }
}
export default connect(null, (dispatch) => {
  return {
    canSubscription: () => dispatch(UserAction.cancelSubscription()),
  };
})(Upgrade_payment);
