import {
  CREATE_EVENT_ERROR,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_REQ,
  CREATE_USER_CLASS_ERROR,
  CREATE_USER_CLASS_REQ,
  CREATE_USER_CLASS_SUCCESS,
  CREATE_EVENT_QUESTION_REQ,
  CREATE_EVENT_QUESTION_SUCCESS,
  CREATE_EVENT_QUESTION_ERROR,
  CREATE_DRESSAGE_REQ,
  CREATE_DRESSAGE_ERROR,
  CREATE_DRESSAGE_SUCCESS,
  CREATE_EVENT_CLASS_ERROR,
  CREATE_EVENT_CLASS_SUCCESS,
  CREATE_EVENT_CLASS_REQ,
  CREATE_SHOW_JUMPING_ERROR,
  CREATE_SHOW_JUMPING_REQ,
  CREATE_SHOW_JUMPING_SUCCESS,
  CREATE_CROSS_COUNTRY_SUCCESS,
  CREATE_CROSS_COUNTRY_REQ,
  CREATE_CROSS_COUNTRY_ERROR,
  CREATE_CROSS_COUNTRY_FENCE_REQ,
  CREATE_CROSS_COUNTRY_FENCE_SUCCESS,
  CREATE_CROSS_COUNTRY_FENCE_ERROR,
  GET_CLASS_ERROR,
  GET_CLASS_SUCCESS,
  GET_CLASS_REQ,
  GET_TEST_ERROR,
  GET_TEST_SUCCESS,
  GET_TEST_REQ,
  GET_CROSS_COUNTRY_SUCCESS,
  GET_CROSS_COUNTRY_REQ,
  GET_CROSS_COUNTRY_ERROR,
  GET_EVENT_CLASS_DETAILS_REQ,
  GET_EVENT_CLASS_DETAILS_SUCCESS,
  GET_EVENT_CLASS_DETAILS_ERROR,
  SEARCH_JUDGE_REQ,
  SEARCH_JUDGE_SUCCESS,
  SEARCH_JUDGE_ERROR,
  GET_EVENT_CLASS_REQ,
  GET_EVENT_CLASS_SUCCESS,
  GET_EVENT_CLASS_ERROR,
  GET_EVENT_QUESTION_REQ,
  GET_EVENT_QUESTION_SUCCESS,
  GET_EVENT_QUESTION_ERROR,
  DELETE_DRESSAGE_REQ,
  DELETE_DRESSAGE_SUCCESS,
  DELETE_DRESSAGE_ERROR,
  DELETE_EVENT_CLASS_REQ,
  DELETE_EVENT_CLASS_SUCCESS,
  DELETE_EVENT_CLASS_ERROR,
  DELETE_EVENT_QUESTION_REQ,
  DELETE_EVENT_QUESTION_SUCCESS,
  DELETE_EVENT_QUESTION_ERROR,
  GET_DRESSAGE_REQUEST,
  GET_DRESSAGE_SUCCESS,
  GET_DRESSAGE_ERROR,
  GET_SHOW_JUMPING_REQ,
  GET_SHOW_JUMPING_SUCCESS,
  GET_SHOW_JUMPING_ERROR,
  DELETE_SHOW_JUMPING_REQ,
  DELETE_SHOW_JUMPING_SUCCESS,
  DELETE_CROSS_COUNTRY_REQ,
  DELETE_CROSS_COUNTRY_SUCCESS,
  DELETE_CROSS_COUNTRY_ERROR,
  CONFIRM_EVENT_REQ,
  CONFIRM_EVENT_SUCCESS,
  CONFIRM_EVENT_ERROR,
  UPDATE_EVENT_REQ,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  DELETE_EVENT_REQ,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  GET_EVENT_REQ,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  GET_MY_EVENT_REQ,
  GET_MY_EVENT_SUCCESS,
  GET_MY_EVENT_ERROR,
  GET_EVENT_BY_ID_REQ,
  GET_EVENT_BY_ID_SUCCESS,
  GET_EVENT_BY_ID_ERROR,
  UPDATE_EVENT_COVER_IMAGE_REQ,
  UPDATE_EVENT_COVER_IMAGE_ERROR,
  UPDATE_EVENT_COVER_IMAGE_SUCCESS,
  UPDATE_EVENT_CLASS_REQ,
  UPDATE_EVENT_CLASS_SUCCESS,
  UPDATE_EVENT_CLASS_ERROR,
  UPDATE_EVENT_QUESTION_REQ,
  UPDATE_EVENT_QUESTION_SUCCESS,
  UPDATE_EVENT_QUESTION_ERROR,
  UPDATE_DRESSAGE_REQ,
  UPDATE_DRESSAGE_SUCCESS,
  UPDATE_DRESSAGE_ERROR,
  UPDATE_CROSS_COUNTRY_REQ,
  UPDATE_CROSS_COUNTRY_SUCCESSS,
  UPDATE_CROSSS_COUNTRY_ERROR,
  UPDATE_CROSS_COUNTRY_FENCES_REQ,
  UPDATE_CROSS_COUNTRY_FENCES_SUCCESS,
  UPDATE_CROSS_COUNTRY_FENCES_ERROR,
  UPDATE_SHOW_JUMPING_REQ,
  UPDATE_SHOW_JUMPING_SUCCESS,
  UPDATE_SHOW_JUMPING_ERROR,
  CREATE_SHOW_JUMPING_FENCS_REQ,
  CREATE_SHOW_JUMPING_FENCS_ERROR,
  UPDATE_SHOW_JUMPING_FENCES_REQ,
  UPDATE_SHOW_JUMPING_FENCES_SUCCESS,
  UPDATE_SHOW_JUMPING_FENCES_ERROR,
  GET_COMPLETE_EVENT_REQ,
  GET_COMPLETE_EVENT_SUCCESS,
  GET_COMPLETE_EVENT_ERROR,
  GET_CLASS_BY_EVENT_REQ,
  GET_CLASS_BY_EVENT_SUCCESS,
  GET_CLASS_BY_EVENT_ERROR,
  GET_LEADERBOARD_REQ,
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_ERROR,
  DELETE_USER_CLASS_REQ,
  DELETE_USER_CLASS_SUCCESS,
  DELETE_USER_CLASS_ERROR,
  UPDATE_USER_CLASS_REQ,
  UPDATE_USER_CLASS_SUCCESS,
  UPDATE_USER_CLASS_ERROR
} from "../constants";

export default class EventAction {
  static createEventRequest(payload, cb) {
    return {
      type: CREATE_EVENT_REQ,
      payload,
      cb,
    };
  }
  static createEventSuccess(res) {
    return {
      type: CREATE_EVENT_SUCCESS,
      res,
    };
  }
  static createEventError(error) {
    return {
      type: CREATE_EVENT_ERROR,
      error,
    };
  }
  static createUserClassReq(payload, cb, callback) {
    return {
      type: CREATE_USER_CLASS_REQ,
      payload,
      cb,
      callback,
    };
  }
  static createUserClassSuccess(res) {
    return {
      type: CREATE_USER_CLASS_SUCCESS,
      res,
    };
  }
  static createUserClassError(error) {

    return {
      type: CREATE_USER_CLASS_ERROR,
      error,
    };
  }
  static createEventQuestionReq(payload) {
    return {
      type: CREATE_EVENT_QUESTION_REQ,
      payload,
    };
  }
  static createEventQuestionSuccess(res) {
    return {
      type: CREATE_EVENT_QUESTION_SUCCESS,
      res,
    };
  }
  static createEventQuestionError(error) {
    return {
      type: CREATE_EVENT_QUESTION_ERROR,
      error,
    };
  }

  static createEventClassReq(payload, cb) {
    return {
      type: CREATE_EVENT_CLASS_REQ,
      payload,
      cb,
    };
  }
  static createEventClassSuccess(res) {
    return {
      type: CREATE_EVENT_CLASS_SUCCESS,
      res,
    };
  }
  static createEventClassError(error) {
    return {
      type: CREATE_EVENT_CLASS_ERROR,
      error,
    };
  }

  static createDressageRequest(payload, cb) {
    return {
      type: CREATE_DRESSAGE_REQ,
      payload,
      cb,
    };
  }
  static createDressageSuccess(res) {
    return {
      type: CREATE_DRESSAGE_SUCCESS,
      res,
    };
  }
  static createDressageError(error) {
    return {
      type: CREATE_DRESSAGE_ERROR,
    };
  }

  static createShowJumpingReq(payload, cb) {
    return {
      type: CREATE_SHOW_JUMPING_REQ,
      payload,
      cb,
    };
  }
  static createShowJumpingSuccess(res) {
    return {
      type: CREATE_SHOW_JUMPING_SUCCESS,
      res,
    };
  }
  static createShowJumpingError(error) {
    return {
      type: CREATE_SHOW_JUMPING_ERROR,
      error,
    };
  }
  static createCrossCountryReq(payload, cb) {
    return {
      type: CREATE_CROSS_COUNTRY_REQ,
      payload,
      cb,
    };
  }
  static createCrossCountrySuccess(res) {
    return {
      type: CREATE_CROSS_COUNTRY_SUCCESS,
      res,
    };
  }
  static createCrossCountryError(error) {
    return {
      type: CREATE_CROSS_COUNTRY_ERROR,
      error,
    };
  }
  static createCrossCountryFenceReq(payload, cb) {
    return {
      type: CREATE_CROSS_COUNTRY_FENCE_REQ,
      payload,
      cb,
    };
  }
  static createCrossCountryFenceSuccess(res) {
    return {
      type: CREATE_CROSS_COUNTRY_FENCE_SUCCESS,
      res,
    };
  }
  static createCrossCountryFenceError(error) {
    return {
      type: CREATE_CROSS_COUNTRY_FENCE_ERROR,
      error,
    };
  }

  static getClassRequest() {
    return {
      type: GET_CLASS_REQ,
    };
  }
  static getClassSuccess(res) {
    return {
      type: GET_CLASS_SUCCESS,
      res,
    };
  }
  static getClassError(error) {
    return {
      type: GET_CLASS_ERROR,
      error,
    };
  }

  static getEventClassReq(payload) {
    return {
      type: GET_EVENT_CLASS_REQ,
      payload,
    };
  }
  static getEventClassSuccess(res) {
    return {
      type: GET_EVENT_CLASS_SUCCESS,
      res,
    };
  }
  static getEventClassError(error) {
    return {
      type: GET_EVENT_CLASS_ERROR,
      error,
    };
  }

  static getTestRequest() {
    return {
      type: GET_TEST_REQ,
    };
  }
  static getTestSuccess(res) {
    return {
      type: GET_TEST_SUCCESS,
      res,
    };
  }
  static getTestError(error) {
    return {
      type: GET_TEST_ERROR,
      error,
    };
  }
  static searchJudgeRequest(payload) {
    return {
      type: SEARCH_JUDGE_REQ,
      payload,
    };
  }
  static searchJudgeSuccess(res) {
    return {
      type: SEARCH_JUDGE_SUCCESS,
      res,
    };
  }
  static searchJudgeError(error) {
    return {
      type: SEARCH_JUDGE_ERROR,
      error,
    };
  }
  static getEventQuestionReq(payload) {
    return {
      type: GET_EVENT_QUESTION_REQ,
      payload,
    };
  }
  static getEventQuestionSuccess(res) {
    return {
      type: GET_EVENT_QUESTION_SUCCESS,
      res,
    };
  }
  static getEventQuestionError(error) {
    return {
      type: GET_EVENT_QUESTION_ERROR,
      error,
    };
  }
  static deleteDressage(payload, cb) {
    return {
      type: DELETE_DRESSAGE_REQ,
      payload,
      cb,
    };
  }
  static deleteDressageSuccess(res) {
    return {
      type: DELETE_DRESSAGE_SUCCESS,
      res,
    };
  }
  static deleteDressageError(error) {
    return {
      type: DELETE_DRESSAGE_ERROR,
      error,
    };
  }

  static deleteEventClass(payload, cb) {
    return {
      type: DELETE_EVENT_CLASS_REQ,
      payload,
      cb,
    };
  }
  static deleteEventClassSuccess(res) {
    return {
      type: DELETE_EVENT_CLASS_SUCCESS,
      res,
    };
  }
  static deleteEventClassError(error) {
    return {
      type: DELETE_EVENT_CLASS_ERROR,
      error,
    };
  }

  static deleteEventQuestion(payload, cb) {
    return {
      type: DELETE_EVENT_QUESTION_REQ,
      payload,
      cb,
    };
  }
  static deleteEventQuestionSuccess(res) {
    return {
      type: DELETE_EVENT_QUESTION_SUCCESS,
      res,
    };
  }
  static deleteEventQuestionError(error) {
    return {
      type: DELETE_EVENT_QUESTION_ERROR,
      error,
    };
  }
  static getDressageReq(payload, cb) {
    return {
      type: GET_DRESSAGE_REQUEST,
      payload,
      cb,
    };
  }
  static getDressageSuccess(res) {
    return {
      type: GET_DRESSAGE_SUCCESS,
      res,
    };
  }
  static getDressageError(error) {
    return {
      type: GET_DRESSAGE_ERROR,
      error,
    };
  }

  static getShowJumpingReq(payload, cb) {
    return {
      type: GET_SHOW_JUMPING_REQ,
      payload,
      cb,
    };
  }
  static getShowJumpingSuccess(res) {
    return {
      type: GET_SHOW_JUMPING_SUCCESS,
      res,
    };
  }
  static getShowJumpingError(error) {
    return {
      type: GET_SHOW_JUMPING_ERROR,
      error,
    };
  }
  static deleteShowJumpingReq(payload, cb) {
    return {
      type: DELETE_SHOW_JUMPING_REQ,
      payload,
      cb,
    };
  }
  static deleteShowJumpingSuccess(res) {
    return {
      type: DELETE_SHOW_JUMPING_SUCCESS,
      res,
    };
  }
  static deleteShowJumpingError(error) {
    return {
      type: DELETE_EVENT_CLASS_ERROR,
    };
  }
  static getCrossCountryRequest(payload) {
    return {
      type: GET_CROSS_COUNTRY_REQ,
      payload,
    };
  }
  static getCrossCountrySuccess(res) {
    return {
      type: GET_CROSS_COUNTRY_SUCCESS,
      res,
    };
  }
  static getCrossCountryError(error) {
    return {
      type: GET_CROSS_COUNTRY_ERROR,
      error,
    };
  }

  static deletCrossCountryReq(payload) {
    return {
      type: DELETE_CROSS_COUNTRY_REQ,
      payload,
    };
  }
  static deletCrossCountrySuccess(res) {
    return {
      type: DELETE_CROSS_COUNTRY_SUCCESS,
      res,
    };
  }
  static deletCrossCountryError(error) {
    return {
      type: DELETE_CROSS_COUNTRY_ERROR,
      error,
    };
  }

  static confirmEventReq(payload, cb, status) {
    console.log(payload, cb, status, "ACTION==>");
    return {
      type: CONFIRM_EVENT_REQ,
      payload,
      cb,
      status,
    };
  }
  static confirmEventSuccess(res) {
    return {
      type: CONFIRM_EVENT_SUCCESS,
      res,
    };
  }
  static confirmEventError(error) {
    return {
      type: CONFIRM_EVENT_ERROR,
      error,
    };
  }
  static updateEventRequest(payload, cb, callBack) {
    return {
      type: UPDATE_EVENT_REQ,
      payload,
      cb,
      callBack,
    };
  }
  static updateEventSuccess(res) {
    return {
      type: UPDATE_EVENT_SUCCESS,
      res,
    };
  }
  static updateEventError(error) {
    return {
      type: UPDATE_EVENT_ERROR,
      error,
    };
  }

  static deletEventRequest(payload, cb) {
    return {
      type: DELETE_EVENT_REQ,
      payload,
      cb,
    };
  }

  static deletEventSuccess(res) {
    return {
      type: DELETE_EVENT_SUCCESS,
      res,
    };
  }
  static deletEventError(error) {
    return {
      type: DELETE_EVENT_ERROR,
      error,
    };
  }
  static updateEventCoverRequest(payload, cb) {
    console.log("eventImage", payload, cb);
    return {
      type: UPDATE_EVENT_COVER_IMAGE_REQ,
      payload,
      cb,
    };
  }
  static updateEventCoverSuccess(res) {
    return {
      type: UPDATE_EVENT_COVER_IMAGE_SUCCESS,
      res,
    };
  }
  static updateEventCoverError(error) {
    return {
      type: UPDATE_EVENT_COVER_IMAGE_ERROR,
      error,
    };
  }
  static getEventReq(params) {
    return {
      type: GET_EVENT_REQ,
      params,
    };
  }
  static getEventSuccess(res) {
    return {
      type: GET_EVENT_SUCCESS,
      res,
    };
  }
  static getEventError(error) {
    return {
      type: GET_EVENT_ERROR,
      error,
    };
  }

  static getMyEventReq(params) {
    return {
      type: GET_MY_EVENT_REQ,
      params,
    };
  }
  static getMyEventSuccess(res) {
    return {
      type: GET_MY_EVENT_SUCCESS,
      res,
    };
  }
  static getMyEventError(error) {
    return {
      type: GET_MY_EVENT_ERROR,
      error,
    };
  }
  static getEventByIdReq(params) {
    return {
      type: GET_EVENT_BY_ID_REQ,
      params,
    };
  }
  static getEventByIdSuccess(res) {
    return {
      type: GET_EVENT_BY_ID_SUCCESS,
      res,
    };
  }
  static getEventByIdError(error) {
    return {
      type: GET_EVENT_BY_ID_ERROR,
      error,
    };
  }
  static getEventClassDetailsReq(params) {
    return {
      type: GET_EVENT_CLASS_DETAILS_REQ,
      params,
    };
  }
  static getEventClassDetailsSuccess(res) {
    return {
      type: GET_EVENT_CLASS_DETAILS_SUCCESS,
      res,
    };
  }
  static getEventClassDetailsError(error) {
    return {
      type: GET_EVENT_CLASS_DETAILS_ERROR,
      error,
    };
  }

  static updateEventClassReq(payload, eventClassId, callback) {
    return {
      type: UPDATE_EVENT_CLASS_REQ,
      payload,
      eventClassId,
      callback,
    };
  }
  static updateEventClassSuccess(res) {
    return {
      type: UPDATE_EVENT_CLASS_SUCCESS,
      res,
    };
  }
  static updateEventClassError(error) {
    return {
      type: UPDATE_EVENT_CLASS_ERROR,
      error,
    };
  }
  static updateEventQuestionReq(payload, cb, callback) {
    return {
      type: UPDATE_EVENT_QUESTION_REQ,
      payload,
      cb,
      callback,
    };
  }
  static updateEventQuestionSuccess(res) {
    return {
      type: UPDATE_EVENT_QUESTION_SUCCESS,
      res,
    };
  }
  static updateEventQuestionError(error) {
    return {
      type: UPDATE_EVENT_QUESTION_ERROR,
      error,
    };
  }
  static updateDressageReq(payload, cb, callback) {
    return {
      type: UPDATE_DRESSAGE_REQ,
      payload,
      cb,
      callback,
    };
  }
  static updateDressageSuccess(res) {
    return {
      type: UPDATE_DRESSAGE_SUCCESS,
      res,
    };
  }
  static updateDressageError(error) {
    return {
      type: UPDATE_DRESSAGE_ERROR,
      error,
    };
  }
  static updateCrossCountryReq(payload, cb) {
    return {
      type: UPDATE_CROSS_COUNTRY_REQ,
      payload,
      cb,
    };
  }
  static updateCrossCountrySuccess(res) {
    return {
      type: UPDATE_CROSS_COUNTRY_SUCCESSS,
      res,
    };
  }
  static updateCrossCountryError(error) {
    return {
      type: UPDATE_CROSSS_COUNTRY_ERROR,
      error,
    };
  }

  static updateCrossCountryFencsReq(payload, crossCountryFencsId, cb) {
    return {
      type: UPDATE_CROSS_COUNTRY_FENCES_REQ,
      payload,
      crossCountryFencsId,
      cb,
    };
  }
  static updateCrossCountryFencsSuccess(res) {
    return {
      type: UPDATE_CROSS_COUNTRY_FENCES_SUCCESS,
      res,
    };
  }
  static updateCrossCountryFencsError(error) {
    return {
      type: UPDATE_CROSS_COUNTRY_FENCES_ERROR,
      error,
    };
  }
  static updateShowJumpingReq(payload, showJumpingId, callBack, clearImage) {
    return {
      type: UPDATE_SHOW_JUMPING_REQ,
      payload,
      showJumpingId,
      callBack,
      clearImage,
    };
  }
  static updateShowJumpingSuccess(res) {
    return {
      type: UPDATE_SHOW_JUMPING_SUCCESS,
      res,
    };
  }
  static updateShowJumpingError(error) {
    return {
      type: UPDATE_SHOW_JUMPING_ERROR,
      error,
    };
  }
  static createShowJumpingFencsReq(payload, cb, callback) {
    return {
      type: CREATE_SHOW_JUMPING_FENCS_REQ,
      payload,
      cb,
      callback,
    };
  }
  static createShowJumpingFencsSuccess(res) {
    return {
      type: CREATE_SHOW_JUMPING_SUCCESS,
      res,
    };
  }
  static createShowJumpingFencsError(e) {
    return {
      type: CREATE_SHOW_JUMPING_FENCS_ERROR,
      e,
    };
  }

  static updateShowJumpingFencsReq(
    payload,
    showJumpingId,
    callback,
    clearImage
  ) {
    return {
      type: UPDATE_SHOW_JUMPING_FENCES_REQ,
      payload,
      showJumpingId,
      callback,
      clearImage,
    };
  }
  static updateShowJumpingFencsSuccess(res) {
    return {
      type: UPDATE_SHOW_JUMPING_FENCES_SUCCESS,
      res,
    };
  }
  static updateShowJumpingFencsError(error) {
    return {
      type: UPDATE_SHOW_JUMPING_FENCES_ERROR,
      error,
    };
  }
  static getCompleteEventReq() {
    return {
      type: GET_COMPLETE_EVENT_REQ,
    };
  }
  static getCompleteEventSuccess(res) {
    return {
      type: GET_COMPLETE_EVENT_SUCCESS,
      res,
    };
  }
  static getCompleteEventError(e) {
    return {
      type: GET_COMPLETE_EVENT_ERROR,
      error: e,
    };
  }
  static getClassByEventReq(payload) {
    return {
      type: GET_CLASS_BY_EVENT_REQ,
      payload,
    };
  }
  static getClassByEventSuccess(res) {
    return {
      type: GET_CLASS_BY_EVENT_SUCCESS,
      res,
    };
  }
  static getClassByEventError(e) {
    return {
      type: GET_CLASS_BY_EVENT_ERROR,
      e,
    };
  }
  static getLeaderboardReq(payload) {
    return {
      type: GET_LEADERBOARD_REQ,
      payload,
    };
  }
  static getLeaderboardSuccess(res) {
    return {
      type: GET_LEADERBOARD_SUCCESS,
      res,
    };
  }
  static getLeaderboardError(res) {
    return {
      type: GET_LEADERBOARD_ERROR,
      res,
    };
  }
  static deleteUserClassReq(payload) {
    return {
      type: DELETE_USER_CLASS_REQ,
      payload,
    };
  }
  static deleteUserClassSuccess(res) {
    return {
      type: DELETE_USER_CLASS_SUCCESS,
      res,
    };
  }
  static deleteUserClassError(error) {
    return {
      type: DELETE_USER_CLASS_ERROR,
      error,
    };
  }
  static updateUserClassReq(payload,id,cb) {
    return {
      type: UPDATE_USER_CLASS_REQ,
      payload,
      id,
      cb
    };
  }
  static updateUserClassSuccess(res) {
    return {
      type: UPDATE_USER_CLASS_SUCCESS,
      res,
    };
  }
  static updateUserClassError(error) {
    return {
      type: UPDATE_USER_CLASS_ERROR,
      error,
    };
  }
}
