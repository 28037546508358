import React, { useMemo, useEffect, useState } from "react";
// import { STRIPE_PUBLISHABLE_API_KEY } from "./configurations/Constants"
// import { setStripe } from "./actions/App"
import { connect } from "react-redux";
import { Store } from "./store";
// import { StripeProvider } from 'react-stripe-elements';
import { Provider } from "react-redux";
import "antd/dist/antd.css";
// import { ThemeProvider } from "styled-components";
// import { theme as appTheme } from "./configurations/Theme";
import Routes from "./config/routes/AppRoutes";
import "./App.css";
// import { onMessageListener } from "./config/firebase/firebase-config";
//import Notifications from "./components/Notification/Notifications";
import firebase from "./firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import ReactNotifications from "./components/Notification/ReactNotification";
import {UserAction} from './store/actions'

//import ReactNotificationComponent from "./components/Notification/ReactNotification";
// import FormView from "./components/formikwrapper/FormView"
//import { getMessaging, onMessage } from "firebase/messaging";
//let messaging = firebase.messaging();

function App(props) {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [show, setShow] = useState(false);
  // const messaging = getMessaging();
  // onMessage(messaging, (payload) => {
  //   console.log('Message received. HERREEE ', payload);
  //   // ...
  // });
  // const [toastmsg, setToastmsg] = useState(notification ? notification : "");
  // useEffect(() => {
  //   setToastmsg(notification);
  //   setShow(true);
  //   console.log("Notification; ", notification);
  // }, [notification]);
  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  useEffect(async () => {
    const { checkAccount } = props;

    try {
      // let token = await messaging.getToken();
      // console.log("TOKEN: ", token);
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("./firebase-messaging-sw.js")
          .then(function (registration) {
            console.log("Service Worker Registered");
            let messaging = firebase.messaging();
            messaging
              .getToken({ serviceWorkerRegistration: registration })
              .then((token) => {
                localStorage.setItem("FirebaseToken", token);
              })
              .catch((err) => console.log(err));
            firebase.messaging().onMessage(
              (payload) => {
                console.log("MESSAGE PAYLOAD IS: ", payload);
                setNotification({
                  title: payload?.notification?.title,
                  body: payload?.notification?.body,
                });
              },
              (e) => {
                console.log(e);
              }
            );
          });
      }

      // let messaging = firebae.messaging()
      // messaging.requestPermission().then(function(){
      //   console.log("Have Permission");
      //   return messaging.getToken();
      //   }).then(function(token){
      //   console.log('tokennnnnnn',token)

      //   }).catch(function(error){
      //   console.log('eroooroorrrrr========',error);
      //   })
    } catch (error) {
      console.log("errorr", error);
    }
    checkAccount({ data: "true" });

    // console.log('messsgingss', messaging)
  }, []);
  //console.log("toastmsg IS: ", toastmsg);
  // useEffect(()=> {
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. HERREEE ', payload);
  //     // ...
  //   });
  // }, [messaging])
  return (
    <>
      <div
        className="App"
        id={props.modal?.modals.length !== 0 ? "filterModal" : undefined}
      >
        <ReactNotifications
          title={notification.title}
          body={notification.body}
        />
        {/* 
        <Notifications/> */}
        <Routes />
      </div>
      {/* <RootModal /> */}
    </>
    // <StripeProvider

    //apiKey="pk_test_rBeOTa2VkXZ16aKpzBQiA8qT00SmODzsPk"
    //stripe={props.app.stripe}>

    //</StripeProvider>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    checkAccount: (data) => dispatch(UserAction.checkAccountReq(data)),
  };
}
export default connect(
  (storeState) => ({
    app: storeState.App,
    modal: storeState?.Modal,
  }),
  mapDispatchToProps
)(App);
