import { Modal, Button } from "antd";
import React, { Component } from "react";
import { Row, Col } from "antd";
import ButtonForm from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";

export default class Modals extends React.Component {
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };
  handleCancel = () => {
      let { visible } = this.props;
      //visible = !visible;
      this.setState({ loading: false, visible: false }); 
  }
  render() {
    const { visible, title, lable, onChange,onSubmitModal, onCancelModal,confirmloading,className } = this.props;
    return (
      <>
        <Modal
          visible={visible}
          title={title}
          lable={lable}
          confirmLoading={confirmloading}
          onOk={this.handleOk}
          onCancel={()=>onCancelModal()}
          closeable={true}
          maskClosable={true}
          footer={[]}
        >
          <Row justify="center" gutter={[0,20]}>
            <Col span={24}>
              <lable>{lable}</lable>
            </Col>
            <Col span={24}>
              <DefaultInput onChange={(e)=>onChange(e)} name="name" style={{ borderColor: "#00A99D" }} value={className} />
            </Col>
            <Col xs={10} md={6}>
              <ButtonForm loading={confirmloading} onClick={() => onSubmitModal()}>
                Submit
              </ButtonForm>
              </Col>
          </Row>
        </Modal>
      </>
    );
  }
}
