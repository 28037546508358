import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

import google from "../../assets/images/google.png";
import apple from "../../assets/images/apple.png";
import info from "../../components/info";
import { Formik, Form } from "formik";
import { loginValidations } from "../../config/schemas";

import facebook from "../../assets/images/facebook.png";
import SignInContainer from "../../components/containers/SignInContainer";

import Image from "../../components/image";
import { Row, Col } from "antd";
import DefaultInput from "../../components/input/Input";
import ButtonForm from "../../components/button/Button";
import { Metrix, Images, Fonts, Colors } from "../../config";
import { AuthMiddleware } from "../../store/middlewares";
import { AuthAction } from "../../store/actions";
import socialMediaAuth from "../../config/service/Auth";
import {
  googleProvider,
  facebookProvider,
} from "../../config/firebase/authMethods";

class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      validEmail: true,
      validPass: true,
    };
  }

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let validEmail = re.test(String(email).toLowerCase());

    this.setState({ email, validEmail });
  };

  validatePass = (password) => {
    let validPass = password.length > 5;

    this.setState({ password, validPass });
  };
  handleOnClick = async (provider) => {
    try {
      const res = await socialMediaAuth(provider);
      const { email, uid } = res;
      console.log('res==>',res)
      this.props.socialSignIn(
        { email, socialId: uid, platform: "google" },
        (success) => {
          if (success.user.companyName) {
            this.props.history.push({
              pathname: "/",
              state: { fromLogin: true },
            });
          } else {
            this.props.history.push({
              pathname: "/social-login",
              state: { token: success.x_auth_token },
            });
          }
        }
      );
      // get(payload,(succs)=>{
      //   if(succsss.company){
      //     this.props.
      //   }
      // })
    } catch (e) {}

    //
  };

  onHandleFacebook = async (provider) => {
    try {
      const res = await socialMediaAuth(provider);
      const { email, uid } = res;
      console.log('resFacebook==>',res)

      this.props.socialSignIn(
        { email, socialId: uid, platform: "facebook" },
        (success) => {
          if (success.user.companyName) {
            this.props.history.push("/");
          } else {
            this.props.history.push({
              pathname: "/social-login",
              state: { token: success.x_auth_token },
            });
          }
        }
      );
      // get(payload,(succs)=>{
      //   if(succsss.company){
      //     this.props.
      //   }
      // })
    } catch (e) {}
  };
  render() {
    // const { email, password, validEmail, validPass } = this.state;
    const { signin, loader } = this.props;

    return (
      <SignInContainer>
        <Row
          justify="center"
          align="middle"
          style={{ height: "100%", overflowY: "auto" }}
        >
          <Col span={24} align="middle">
            <Image style={{ maxWidth: "30%" }} src={logo} />
          </Col>
          <Col xs={24} md={13} lg={22} xl={24} align="middle">
            <Row justify="center">
              <Col span={24}>
                <h1 style={{ color: Colors.White }}>Welcome</h1>
              </Col>
              <Col span={24}>
                <p style={{ color: "white" }}>Please Sign In To Continue</p>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={24} lg={24} xl={14} align="middle">
            <Row justify="center" gutter={[0, 15]}>
              <Col xs={24} sm={13} md={20} lg={14} xl={18} align="center">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginValidations}
                  onSubmit={(values) => {
                    signin(
                      values,
                      () => {
                        this.props.history.push({
                          pathname: "/",
                          state: { fromLogin: true },
                        });
                      },
                      () => {
                        this.props.history.push({
                          pathname: "/verify-code",
                          state: {
                            email: values.email,
                          },
                        });
                      }
                    );
                  }}
                  render={({ values, touched, handleChange, errors }) => (
                    <Form>
                      <Row justify="center" gutter={[0, 10]}>
                        <Col
                          xs={16}
                          sm={20}
                          md={13}
                          lg={14}
                          xl={13}
                          align="middle"
                        >
                          <DefaultInput
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                          />
                          <label style={{ color: Colors.Red }}>
                            {errors.email && touched.email && errors.email}
                          </label>
                        </Col>
                        <Col
                          xs={16}
                          sm={20}
                          md={13}
                          lg={14}
                          xl={13}
                          align="middle"
                        >
                          <DefaultInput
                            password={true}
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                          />
                          <label style={{ color: Colors.Red }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </label>
                        </Col>
                        <Col
                          xs={16}
                          sm={20}
                          md={13}
                          lg={14}
                          xl={13}
                          align="middle"
                        >
                          <ButtonForm htmlType="submit" loading={loader}>
                            Login
                          </ButtonForm>
                        </Col>
                        <Col
                          xs={16}
                          sm={20}
                          md={13}
                          lg={14}
                          xl={13}
                          align="end"
                        >
                          <Link
                            to="/forgot-password"
                            style={{ color: "#707070" }}
                          >
                            Forgot Password?
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={13} align="middle">
            <Row justify="center" gutter={[0, 10]}>
              <Col span={24}>
                <p style={{ color: Colors.White }}>
                  if you don`t have an account?{" "}
                  <Link to="/signUp" style={{ color: "#707070" }}>
                    Sign Up?
                  </Link>
                </p>
              </Col>
              <Col span={24}>
                <p style={{ color: Colors.White }}>OR</p>
              </Col>
              <Col span={24}>
                <p style={{ color: Colors.White }}>Sign in with</p>
              </Col>
              <Col span={24}>
                <Row justify="center" align="middle" gutter={[20, 0]}>
                  {/* <Col
                    xs={4}
                    md={3}
                    lg={3}
                    xl={2}
                    style={{ cursor: "pointer" }}
                  >
                    <Image src={apple} />
                  </Col> */}

                  <Col
                    xs={4}
                    md={3}
                    lg={3}
                    xl={2}
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      src={google}
                      onClick={() => this.handleOnClick(googleProvider)}
                    />
                  </Col>
                  <Col
                    xs={4}
                    md={3}
                    lg={3}
                    xl={2}
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      src={facebook}
                      onClick={() => this.onHandleFacebook(facebookProvider)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </SignInContainer>
    );
  }
}

function mapStateToProps(storeState) {
  return {
    loader: storeState?.AuthReducer.loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signin: (payload, cb, callback) => {
      dispatch(AuthAction.SignIn(payload, cb, callback));
    },

    socialSignIn: (payload, cb) => {
      dispatch(AuthAction.SocialSignInReq(payload, cb));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
