import * as Yup from "yup";

export const fp3Schema = Yup.object().shape({
  password: Yup.string()
  .min(8, "Password length should be atleast 8")
  // .max(16, "Password is too long! it must be between 6 and 16 characters")
  // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=_()]).*$/,
    "Password must contain at least eight characters,one uppercase letter,one number and one symbol"
  )
  .required("Password required"),
  confirmPassword: Yup.mixed()
    .oneOf([Yup.ref("password")], "Passwords do not match!")
    .required("Confirm password required"),
});
