import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import DefaultInput from "../../components/input/Input";
import BackButton from "../../components/button/BackButton";
import { Formik, Form } from "formik";
import { BiArrowBack } from "react-icons/bi";
import "./Styles.css";
import DefaultButton from "../../components/button/Button";
import { AuthAction } from "../../store/actions";
import { Button } from "antd";
import background from "../../assets/images/background.png";
import { fp1Validations } from "../../config/schemas";
import FPContainer from "../../components/containers/FPContainer";
import Image from "../../components/image";
import { Input } from "antd";
import { Row, Col } from "antd";
import { connect } from "react-redux";
//import { withRouter } from "react-router";
import { Colors } from "../../config";
import { AuthMiddleware } from "../../store/middlewares";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  render() {
    const { forgotPassword1,loader } = this.props;
    return (
      <FPContainer>
        <Row justify="center" style={{width:"100%",overflowY:"auto"}} align="middle">
          <Col xs={10} md={10} lg={10} xl={14}align="middle">
            <Image src={logo} />
          </Col>
          <Col xs={20} sm={18} md={16} lg={16} xl={15}>
            <Row justify="center" align="middle">
              <Col xs={4} sm={4} xs={2} xl={2}>
                <Link to="/signIn" style={{ color: "#707070" }}>
                  <BackButton icon={<BiArrowBack color="white" size={20} />} />
                </Link>
              </Col>
              <Col xs={14} sm={12} md={10} lg={9} xl={8} align="start">
                <h1 style={{ color: Colors.White }}>Enter your Email</h1>
              </Col>
              <Col md={13}lg={24} xl={24}>
                <p style={{ color: Colors.White }}>
                  Please enter you email address for code
                </p>
              </Col>
            </Row>
          </Col>

          <Col xs={16} sm={12} md={10} lg={13} xl={10}>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={fp1Validations}
              onSubmit={(values) => {
                forgotPassword1(values, () => {
                  this.props.history.push({
                    pathname: "/verify-code",
                    state:{
                      email:values.email
                    }
                  });
                });
              }}
              render={({ values, touched, handleChange, errors }) => (
                <Form>
                  <Row justify="center" gutter={[0, 30]}>
                    <Col xs={18} md={18} lg={13} align="start">
                      <p style={{ color: "white" }}>Email*</p>
                      <DefaultInput
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                      />
                      <label style={{ color: Colors.Red }}>
                        {errors.email && touched.email && errors.email}
                      </label>
                    </Col>
                    <Col xs={18} md={18} lg={13} align="middle">
                      <DefaultButton htmlType="submit" loading={loader}>Continue</DefaultButton>
                    </Col>
                  </Row>
                </Form>
              )}
            />
          </Col>
        </Row>
      </FPContainer>
    );
  }
}

function mapStateToProps(storeState) {
  return {
    loader: storeState?.AuthReducer.loader
  };
}

function mapDispatchToProps(dispatch) {
  return {
    forgotPassword1: (payload, cb) => {
      dispatch(AuthAction.ForgotPassword(payload, cb));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
