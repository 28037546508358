import React, { useState } from "react";
import { Row, Col } from "antd";
import HeadingComponent from "../eventsHeading/index";
import { CgFileDocument } from "react-icons/cg";
import { AiOutlineUser, AiOutlinePoweroff, AiFillLock } from "react-icons/ai";
import { BsTrash, BsInfoCircle } from "react-icons/bs";
import { BiWallet } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import CustomModal from "../../components/modals/customModal";
import ButtonForm from "../../components/button/Button";
import AuthAction from "../../store/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../store/actions/UserAction";
import { FiExternalLink } from "react-icons/fi";
function Settings() {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((store) => store.UserReducer.data);

  const closeModal = () => {
    setVisible(false);
  };
  const closeVisible = () => {
    setIsVisible(false);
  };
  const userSubDetail = JSON.parse(localStorage.getItem("eventAuth"));
  return (
    <Row style={{ height: "auto" }} gutter={[0, 30]}>
      <Col span={24}>
        <HeadingComponent title="Settings" />
      </Col>
      <Col
        span={20}
        className="m-auto"
        style={{
          boxShadow: "0px 1px 26px lightgrey",
          borderRadius: "8px",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <CgFileDocument size={30} color="#00A99D" />
          </Col>
          <Col
            lg={4}
            xs={16}
            sm={8}
            md={8}
            xl={8}
            onClick={() => history.push("/manage-class")}
          >
            <p className="text-left setting-font">Manage Classes</p>
          </Col>
        </Row>
        <hr className="setting-hr" />
        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <AiOutlineUser size={30} color="#00A99D" />
          </Col>
          <Col
            lg={4}
            xs={16}
            sm={8}
            md={8}
            xl={8}
            onClick={() => history.push("/edit-profile")}
          >
            <p className="text-left setting-font">Edit Profile</p>
          </Col>
        </Row>
        <hr className="setting-hr" />

        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <BiWallet size={30} color="#00A99D" />
          </Col>
          <Col
            lg={4}
            xs={16}
            sm={8}
            md={8}
            xl={8}
            onClick={() =>
              history.push(
                userSubDetail?.user?.Subscriptions?.length
                  ? "/upgrade_payment"
                  : "subscription"
              )
            }
          >
            <p className="text-left setting-font">Subscription</p>
          </Col>
        </Row>
        <hr className="setting-hr" />

        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <BsInfoCircle size={30} color="#00A99D" />
          </Col>
          <Col lg={4} xs={16} sm={8} md={8} xl={8}>
            <a
              href="https://eventpluz.com/terms-and-conditions"
              target="_blank"
              style={{ color: "black" }}
            >
              <p className="text-left setting-font">Terms &#38; Conditions</p>
            </a>
          </Col>
        </Row>
        <hr className="setting-hr" />
        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <AiFillLock size={30} color="#00A99D" />
          </Col>
          <Col
            lg={4}
            xs={16}
            sm={8}
            md={8}
            xl={8}
            onClick={() => history.push("/change-password")}
          >
            <p className="text-left setting-font">Change Password</p>
          </Col>
        </Row>
        <hr className="setting-hr" />

        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <BsTrash size={30} color="#00A99D" />
          </Col>
          <Col
            lg={4}
            xs={16}
            sm={8}
            md={8}
            xl={8}
            onClick={() => setIsVisible(true)}
          >
            <p className="text-left setting-font">Delete Account</p>
          </Col>
        </Row>
        <hr className="setting-hr" />
        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <FiExternalLink size={30} color="#00A99D" />
          </Col>
          <Col
            lg={4}
            xs={16}
            sm={8}
            md={8}
            xl={8}
            onClick={() => dispatch(userAction.checkAccountReq())}
          >
            <p className="text-left setting-font">
              Link To Stripe{" "}
              {data.status === "pending" || data.status === "inactive" ? (
                <sup>
                  <span
                    style={{
                      width: "1%",
                      height: "1px",
                      background: "red",
                      color: "red",
                      borderRadius: "50%",
                      fontSize: "7px",
                    }}
                  >
                    o a
                  </span>
                </sup>
              ) : (
                ""
              )}
            </p>
          </Col>
        </Row>
        <hr className="setting-hr" />

        <Row justify="start" className="setting-padding pointer-cursor">
          <Col lg={2} xs={4} sm={2} md={2}>
            <AiOutlinePoweroff size={30} color="#00A99D" />
          </Col>
          <Col
            lg={4}
            xs={16}
            sm={8}
            md={8}
            xl={8}
            onClick={() => setVisible(true)}
          >
            <p className="text-left setting-font">Logout</p>
          </Col>
        </Row>
      </Col>
      <CustomModal visible={visible} onCancelModal={closeModal}>
        <Row justify="center" gutter={[0, 20]}>
          <Col span={24}></Col>
          <Col span={24}>
            <h1 className="text-center">Logout</h1>
            <p className="text-center"> Are you sure you want to logout?</p>
          </Col>
          <Col span={8} style={{ marginBottom: "10px" }}>
            <ButtonForm onClick={() => dispatch(AuthAction.Signout())}>
              Yes
            </ButtonForm>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={8}>
            <ButtonForm color="#C5C3C3" onClick={() => closeModal()}>
              No
            </ButtonForm>
          </Col>
        </Row>{" "}
      </CustomModal>
      <CustomModal visible={isVisible} onCancelModal={closeVisible}>
        <Row justify="center" gutter={[0, 20]}>
          <Col span={24}></Col>
          <Col span={24}>
            <h1 className="text-center">Delete Account</h1>
            <p className="text-center">
              {" "}
              Are you sure you want to delete your account?
            </p>
          </Col>
          <Col span={8} style={{ marginBottom: "10px" }}>
            <ButtonForm onClick={() => dispatch(userAction.delteAccount())}>
              Yes
            </ButtonForm>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={8}>
            <ButtonForm color="#C5C3C3" onClick={() => closeVisible()}>
              No
            </ButtonForm>
          </Col>
        </Row>{" "}
      </CustomModal>
    </Row>
  );
}
export default Settings;
