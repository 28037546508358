import React, { Component } from "react";
import { Row, Col, Table } from "antd";
import { connect } from "react-redux";
import EventHeading from "../../components/eventsHeading";
import { withRouter, Link } from "react-router-dom";
import "./login.css";
import { EventAction } from "../../store/actions";

class Login extends Component {
  componentDidMount() {
    const { geteventclassdetailsreq, location } = this.props;
    geteventclassdetailsreq(location?.state?.classId);
  }
  render() {
    const { eventClassDetails = {} } = this.props;
    let tableData = {};
    

    return (
      <>
        <Col span={24} sm={24} md={24} xs={24} lg={24}>
          <EventHeading
            goBack={() =>
              this.props.history.push(
                `/my-event/${this.props.location?.state?.eventId}`
              )
            }
            arrowBtn={true}
            title={"Rider Detail"}
          />
        </Col>
        {/* <h1 style={{ fontWeight: "700", fontSize: "35px",textAlign:'left' }}>
              { "Rider Detail"}
            </h1> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            height: "100vh",
          }}
        >
          {Boolean(!eventClassDetails?.questions?.length) && (
            <h1>No Data Found!</h1>
          )}
          {Boolean(eventClassDetails?.questions?.length) && (
            <table className="rider-table">
            

              <tr>
                {eventClassDetails?.questions?.map((item, index) => {
                  return (
                    <>
                      <th className='text-capitilize'>{item}</th>
                    </>
                  );
                })}
              </tr>
              {eventClassDetails?.answers?.map((item, index) => {
                return (
                  <tr>
                    {(() => {
                      let answerArr = Object.entries(item).sort(
                        (a, b) => a[0][a[0].length - 1] - b[0][b[0].length - 1]
                      );
                      return answerArr.map((v, i) => {
                        return <td>{v[1]}</td>;
                      });
                    })()}
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    geteventclassdetailsreq: (id) => {
      dispatch(EventAction.getEventClassDetailsReq(id));
    },
  };
}
export default withRouter(
  connect(
    (storeState) => ({
      eventClassDetails: storeState?.EventReducer?.eventClassDetails,
    }),
    mapDispatchToProps
  )(Login)
);
