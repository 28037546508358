import React from "react";
import Styled from "styled-components";

const StyledVerticalLine = Styled.div`
border-left: 3px solid lightgrey;
height: 60px
`;

export default function VerticalLine(props) {
  return <StyledVerticalLine {...props}></StyledVerticalLine>;
}
