import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import { DatePicker } from "antd";

const StyledDatePicker = styled(DatePicker)`
  border-radius: 5px;
  height: 2.5rem;
  width:100%;
`;

const DefaultDatePicker = (props) => {
  // const { value } = props;
  // const formatted = useMemo(
  //   () => value && moment(value).isValid() && moment(value),
  //   [value]
  // );

  // value={formatted}
  return <StyledDatePicker {...props} ></StyledDatePicker>;
};

export default DefaultDatePicker;
