import { Select } from "antd";

const { Option } = Select;

const selectInput = (props) => {
  const { placeholder, onChange, onFocus, onBlur, onSearch, options } = props;
  // const objarr = [{1:"abc", 2:"cba"}, {}]
  return (
    <Select
      showSearch
      style={{ width: 200, color: "black" }}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {(options || []).map((item) => (
        <Option value={item}>{item.name}</Option>
      ))}
    </Select>
  );
};
export default selectInput;
