const appUrlAndroid = "";
const appUrlIOS = "";
const appUrlAndroidForReview = "";
const appUrlIOSForReview = "";
// const appUrl = Platform.OS == "android" ? appUrlAndroid : appUrlIOS;
// const appUrlForReview = Platform.OS == "android" ? appUrlAndroidForReview : appUrlIOSForReview;

// Dev base URL
// var baseUrl = "";

// UAT base URL
// baseUrl = "";

// Live URL
export const PASSWORD_REGX=/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=_()]).*$/

var baseUrl = "https://api.eventpluz.com:3009/dev/api/v1/";

const configVariables = {
  // appUrl,
  // appUrlForReview,
  baseUrl: `${baseUrl}`,
  termsUrl: "",
  privacyUrl: "https://www.google.com/",
  contactSupportMail: "",
  instructionsUrl: `${baseUrl}`,
};

const constantVariables = {
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  messageForAppShare: "",
  subjectForAppShare: "",
};
export const EVENT = "event";
export const EVENT_QUESTION = "eventQuestion";
export const USER_CLASS = "userclass";
export const DRESSAGE = "dressage";
export const EVENT_CLASS = "eventClass";
export const SHOWJUMPING = "showJumping";
export const CROSS_COUNTRY = "crossCountry";
export const CROSS_COUNTRY_FENCES = "crossCountryFence";
export const CSV_TEST = "csv/tests";
export const SEARCH_JUDGE = "admin/search";
export const STATUS = "status";
export const COVER = "cover";
export const DETAILS = "details";
export const USER_EVENTS = "userEvents";
export const SHOWJUMPING_FENCES = "showJumpingFence";
export const UPDATE_PROFILE = "user/profile";
export const GET_COMPLETE_EVENT = "getCompletedEvents";
export const GET_LEADERBOARD = "leaderboard";
export const NOTTIFICATION = "notifications";
export const USER = "user";
export const DEACTIVE_USER = "auth/delete-account";
export const SUBSCRIPTION='subscription'
export const CREATE_ACCOUNT='createAccount'
export const CREATE_LINK='createLink'
export const CHECK_ACCOUNT='checkAccount'
export const UPDATE_PASSWORD='auth/update-password'
export const CANCEL_SUBSCRIPTION='subscription/cancel'
export default {
  ...configVariables,
  ...constantVariables,
};
