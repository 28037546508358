import {
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_REQ,
  CREATE_USER_CLASS_REQ,
  CREATE_USER_CLASS_ERROR,
  CREATE_USER_CLASS_SUCCESS,
  CREATE_EVENT_QUESTION_REQ,
  CREATE_EVENT_QUESTION_SUCCESS,
  CREATE_EVENT_QUESTION_ERROR,
  CREATE_DRESSAGE_REQ,
  CREATE_DRESSAGE_SUCCESS,
  CREATE_DRESSAGE_ERROR,
  CREATE_EVENT_CLASS_REQ,
  CREATE_EVENT_CLASS_SUCCESS,
  CREATE_SHOW_JUMPING_REQ,
  CREATE_SHOW_JUMPING_SUCCESS,
  CREATE_SHOW_JUMPING_ERROR,
  CREATE_EVENT_CLASS_ERROR,
  CREATE_CROSS_COUNTRY_REQ,
  CREATE_CROSS_COUNTRY_SUCCESS,
  CREATE_CROSS_COUNTRY_ERROR,
  CREATE_CROSS_COUNTRY_FENCE_REQ,
  CREATE_CROSS_COUNTRY_FENCE_SUCCESS,
  CREATE_CROSS_COUNTRY_FENCE_ERROR,
  GET_TEST_REQ,
  GET_TEST_SUCCESS,
  GET_TEST_ERROR,
  GET_EVENT_CLASS_REQ,
  GET_EVENT_CLASS_SUCCESS,
  GET_EVENT_CLASS_ERROR,
  GET_EVENT_QUESTION_REQ,
  GET_EVENT_QUESTION_SUCCESS,
  GET_EVENT_QUESTION_ERROR,
  GET_EVENT_CLASS_DETAILS_REQ,
  GET_EVENT_CLASS_DETAILS_ERROR,
  GET_EVENT_CLASS_DETAILS_SUCCESS,
  GET_CLASS_REQ,
  GET_CLASS_SUCCESS,
  GET_CLASS_ERROR,
  GET_CROSS_COUNTRY_REQ,
  GET_CROSS_COUNTRY_SUCCESS,
  GET_CROSS_COUNTRY_ERROR,
  SEARCH_JUDGE_REQ,
  SEARCH_JUDGE_SUCCESS,
  SEARCH_JUDGE_ERROR,
  GET_DRESSAGE_REQUEST,
  GET_DRESSAGE_SUCCESS,
  GET_DRESSAGE_ERROR,
  GET_SHOW_JUMPING_REQ,
  GET_SHOW_JUMPING_SUCCESS,
  GET_SHOW_JUMPING_ERROR,
  DELETE_SHOW_JUMPING_REQ,
  DELETE_SHOW_JUMPING_SUCCESS,
  DELETE_SHOW_JUMPING_ERROR,
  UPDATE_EVENT_REQ,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  DELETE_EVENT_REQ,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  UPDATE_EVENT_COVER_IMAGE_REQ,
  UPDATE_EVENT_COVER_IMAGE_SUCCESS,
  UPDATE_EVENT_COVER_IMAGE_ERROR,
  GET_EVENT_SUCCESS,
  GET_EVENT_REQ,
  GET_EVENT_ERROR,
  GET_MY_EVENT_REQ,
  GET_MY_EVENT_SUCCESS,
  GET_MY_EVENT_ERROR,
  GET_EVENT_BY_ID_REQ,
  GET_EVENT_BY_ID_SUCCESS,
  GET_EVENT_BY_ID_ERROR,
  UPDATE_EVENT_CLASS_REQ,
  UPDATE_EVENT_CLASS_SUCCESS,
  UPDATE_EVENT_CLASS_ERROR,
  UPDATE_EVENT_QUESTION_REQ,
  UPDATE_EVENT_QUESTION_SUCCESS,
  UPDATE_EVENT_QUESTION_ERROR,
  UPDATE_DRESSAGE_REQ,
  UPDATE_DRESSAGE_SUCCESS,
  UPDATE_DRESSAGE_ERROR,
  UPDATE_CROSS_COUNTRY_REQ,
  UPDATE_CROSS_COUNTRY_SUCCESSS,
  UPDATE_CROSSS_COUNTRY_ERROR,
  CREATE_SHOW_JUMPING_FENCS_REQ,
  CREATE_SHOW_JUMPING_FENCS_SUCCESS,
  CREATE_SHOW_JUMPING_FENCS_ERROR,
  GET_COMPLETE_EVENT_REQ,
  GET_COMPLETE_EVENT_SUCCESS,
  GET_COMPLETE_EVENT_ERROR,
  GET_CLASS_BY_EVENT_REQ,
  GET_CLASS_BY_EVENT_SUCCESS,
  GET_CLASS_BY_EVENT_ERROR,
  GET_LEADERBOARD_REQ,
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_ERROR,
} from "../constants";

const initialState = {
  isLoading: false,
  error: null,
  eventData: {},
  userClass: [],
  eventQuestion: [],
  eventClass: [],
  showJumping: [],
  dressage: [],
  crossCountry: [],
  crossCountryFence: [],
  eventClassDetails: [],
  tests: [],
  createUserClassData: {},
  judge: [],
  crossCountryId: "",
  createEventClassLoading: false,
  createQuestionLoading: false,
  createUserClassLoading: false,
  isEventLoading: false,
  myEvent: [],
  isMyEventLodaing: false,
  viewEvent: {},
  viewEventLoading: false,
  loadingQuestion: false,
  isCreating: false,
  completeEvent: [],
  isGetCompleteEvent: false,
  classByEvent: [],
  isClassByEvent: false,
  leaderBoard: [],
  isLeaderBoard: false,
};

export default function EventReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_EVENT_REQ: {
      return {
        ...state,
        isCreating: true,
        error: null,
        eventData: {},
      };
    }
    case CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        isCreating: false,
        error: null,
        eventData: action.res,
        // eventId:action.res.data.event
      };
    }
    case CREATE_EVENT_ERROR: {
      return {
        ...state,
        isCreating: false,
        error: action.error,
        eventData: {},
      };
    }
    case CREATE_USER_CLASS_REQ: {
      return {
        ...state,
        createUserClassLoading: true,
        error: null,
        createUserClassData: {},
      };
    }
    case CREATE_USER_CLASS_SUCCESS: {
      state.userClass.push(action.res);
      return {
        ...state,
        createUserClassLoading: false,
        error: null,
        createUserClassData: action.res,
      };
    }
    case CREATE_USER_CLASS_ERROR: {
      return {
        ...state,
        createUserClassLoading: false,
        error: action.error,
        createUserClassData: {},
      };
    }
    case CREATE_EVENT_QUESTION_REQ: {
      return {
        ...state,
        createQuestionLoading: true,
        error: null,
        // eventQuestion: [],/
      };
    }
    case CREATE_EVENT_QUESTION_SUCCESS: {
      state.eventQuestion.push(action.res);

      return {
        ...state,
        createQuestionLoading: false,
        error: null,
        // eventQuestion: action.res,
      };
    }
    case CREATE_EVENT_QUESTION_ERROR: {
      return {
        ...state,
        createQuestionLoading: false,
        error: action.error,
      };
    }
    case CREATE_DRESSAGE_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case CREATE_DRESSAGE_SUCCESS: {
      state.dressage.push(action.res);
      return {
        ...state,
        isLoading: false,
        error: null,
        // dressage: action.res,
      };
    }
    case CREATE_DRESSAGE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        dressage: [],
      };
    }

    case CREATE_EVENT_CLASS_REQ: {
      return {
        ...state,
        createEventClassLoading: true,
        error: null,
      };
    }
    case CREATE_EVENT_CLASS_SUCCESS: {
      return {
        ...state,
        createEventClassLoading: false,
        error: null,
        eventClass: action.res,
      };
    }
    case CREATE_EVENT_CLASS_ERROR: {
      return {
        ...state,
        createEventClassLoading: false,
        error: action.error,
        // eventClass: [],
      };
    }
    case CREATE_SHOW_JUMPING_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
        showJumping: [],
      };
    }
    case CREATE_SHOW_JUMPING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        showJumping: action.res,
      };
    }
    case CREATE_SHOW_JUMPING_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        showJumping: [],
      };
    }
    case CREATE_CROSS_COUNTRY_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
        crossCountryId: "",
      };
    }
    case CREATE_CROSS_COUNTRY_SUCCESS: {
      
      return {
        ...state,
        isLoading: false,
        error: null,
        crossCountry: [],
        crossCountryId: action.id,
      };
    }
    case CREATE_CROSS_COUNTRY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        crossCountry: [],
        crossCountryId: "",
      };
    }
    case CREATE_CROSS_COUNTRY_FENCE_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
        crossCountryFence: [],
      };
    }
    case CREATE_CROSS_COUNTRY_FENCE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        crossCountryFence: action.res,
      };
    }
    case CREATE_CROSS_COUNTRY_FENCE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        crossCountryFence: [],
      };
    }
    case GET_TEST_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
        tests: [],
      };
    }
    case GET_TEST_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        tests: action.res,
      };
    }
    case GET_TEST_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        tests: [],
      };
    }

    case GET_CROSS_COUNTRY_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
        crossCountry: [],
      };
    }
    case GET_CROSS_COUNTRY_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        crossCountry: action,
      };
    }
    case GET_CROSS_COUNTRY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        crossCountry: [],
      };
    }

    case GET_EVENT_CLASS_DETAILS_REQ: {
      return {
        ...state,
      };
    }
    case GET_EVENT_CLASS_DETAILS_ERROR: {
      return {
        ...state,
      };
    }
    case GET_EVENT_CLASS_DETAILS_SUCCESS: {
      
      return {
        ...state,
        eventClassDetails: action?.res?.details,
      };
    }

    case GET_CLASS_REQ: {
      return {
        ...state,
        isLoading: true,
        error: null,
        userClass: [],
      };
    }
    case GET_CLASS_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        userClass: action.res,
      };
    }
    case GET_CLASS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        userClass: [],
      };
    }
    case SEARCH_JUDGE_REQ: {
      return {
        ...state,
        // isLoading: true,
        error: null,
        judge: [],
      };
    }
    case SEARCH_JUDGE_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        judge: action.res,
      };
    }
    case SEARCH_JUDGE_ERROR: {
      return {
        isLoading: false,
        error: action.error,
        judge: [],
      };
    }

    case GET_EVENT_CLASS_REQ: {
      return {
        ...state,
        // isLoading: true,
        error: null,
        eventClass: [],
      };
    }
    case GET_EVENT_CLASS_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        eventClass: action.res,
      };
    }
    case GET_EVENT_CLASS_ERROR: {
      return {
        isLoading: false,
        error: action.error,
        eventClass: [],
      };
    }

    case GET_EVENT_QUESTION_REQ: {
      return {
        ...state,
        // isLoading: true,
        loadingQuestion: true,
        error: null,
        eventQuestion: [],
      };
    }
    case GET_EVENT_QUESTION_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        loadingQuestion: false,
        error: null,
        eventQuestion: action.res,
      };
    }
    case GET_EVENT_QUESTION_ERROR: {
      return {
        loadingQuestion: false,
        error: action.error,
        eventQuestion: [],
      };
    }

    case GET_DRESSAGE_REQUEST: {
      return {
        ...state,
        // isLoading: true,
        isLoading: true,
        error: null,
        dressage: [],
      };
    }
    case GET_DRESSAGE_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        dressage: action.res,
      };
    }
    case GET_DRESSAGE_ERROR: {
      return {
        isLoading: false,
        error: action.error,
        dressage: [],
      };
    }

    case GET_SHOW_JUMPING_REQ: {
      return {
        ...state,
        // isLoading: true,
        isLoading: true,
        error: null,
        showJumping: [],
      };
    }
    case GET_SHOW_JUMPING_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      // 
      const abc = action.res.map((item) => {
        const ShowJumpingFences = item.ShowJumpingFences.filter(
          (el) => el.fenceNum !== "Time Fault"
        );
        return { ...item, ShowJumpingFences };
      });
      return {
        ...state,
        isLoading: false,
        error: null,
        showJumping: abc,
      };
    }
    case GET_SHOW_JUMPING_ERROR: {
      return {
        ...state,

        isLoading: false,
        error: action.error,
        showJumping: [],
      };
    }

    case DELETE_SHOW_JUMPING_REQ: {
      return {
        ...state,
        // isLoading: true,
        isLoading: true,
        error: null,
        showJumping: [],
      };
    }
    case DELETE_SHOW_JUMPING_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        showJumping: action.res,
      };
    }
    case DELETE_SHOW_JUMPING_ERROR: {
      return {
        isLoading: false,
        error: action.error,
        showJumping: [],
      };
    }
    case UPDATE_EVENT_REQ: {
      return {
        ...state,
        // isLoading: true,
        updateEventLoading: true,
        error: null,
        event: [],
      };
    }
    case UPDATE_EVENT_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        updateEventLoading: false,
        error: null,
        event: action.res,
      };
    }
    case UPDATE_EVENT_ERROR: {
      return {
        ...state,
        updateEventLoading: false,
        error: action.error,
        event: [],
      };
    }
    case DELETE_EVENT_REQ: {
      return {
        ...state,
        // isLoading: true,
        error: null,
        event: [],
      };
    }
    case DELETE_EVENT_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        error: null,
        event: action.res,
      };
    }
    case DELETE_EVENT_ERROR: {
      return {
        ...state,

        error: action.error,
        event: [],
      };
    }

    case UPDATE_EVENT_COVER_IMAGE_REQ: {
      return {
        ...state,
        // isLoading: true,
        isLoading: true,
        error: null,
        event: [],
      };
    }
    case UPDATE_EVENT_COVER_IMAGE_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLoading: false,
        error: null,
        event: action.res,
      };
    }
    case UPDATE_EVENT_COVER_IMAGE_ERROR: {
      return {
        ...state,

        isLoading: false,
        error: action.error,
        event: [],
      };
    }

    case GET_EVENT_REQ: {
      return {
        ...state,
        // isLoading: true,
        isEventLoading: true,
        error: null,
        event: [],
      };
    }
    case GET_EVENT_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isEventLoading: false,
        error: null,
        event: action.res,
      };
    }
    case GET_EVENT_ERROR: {
      return {
        ...state,
        isEventLoading: false,
        error: action.error,
        event: [],
      };
    }
    case GET_MY_EVENT_REQ: {
      return {
        ...state,
        isMyEventLodaing: true,
        error: null,
        myEvent: [],
      };
    }
    case GET_MY_EVENT_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isMyEventLodaing: false,
        error: null,
        myEvent: action.res,
      };
    }
    case GET_MY_EVENT_ERROR: {
      return {
        ...state,
        isMyEventLodaing: false,
        error: action.error,
        myEvent: [],
      };
    }

    case GET_EVENT_BY_ID_REQ: {
      return {
        ...state,
        // isLoading: true,
        viewEventLoading: true,
        error: null,
        viewEvent: {},
      };
    }
    case GET_EVENT_BY_ID_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        viewEventLoading: false,
        error: null,
        viewEvent: action.res,
      };
    }
    case GET_EVENT_BY_ID_ERROR: {
      return {
        ...state,
        viewEventLoading: false,
        error: action.error,
        viewEvent: [],
      };
    }

    case UPDATE_EVENT_CLASS_REQ: {
      return {
        ...state,
        // isLoading: true,
        updateEventClassLoading: true,
        error: null,
        viewEvent: {},
      };
    }
    case UPDATE_EVENT_CLASS_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        updateEventClassLoading: false,
        error: null,
        viewEvent: action.res,
      };
    }
    case UPDATE_EVENT_CLASS_ERROR: {
      return {
        ...state,
        updateEventClassLoading: false,
        error: action.error,
        // viewEvent: [],
      };
    }

    case UPDATE_EVENT_QUESTION_REQ: {
      return {
        ...state,
        // isLoading: true,
        updateEventQuestionLoading: true,
        error: null,
        viewEvent: {},
      };
    }
    case UPDATE_EVENT_QUESTION_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        updateEventQuestionLoading: false,
        error: null,
        viewEvent: action.res,
      };
    }
    case UPDATE_EVENT_QUESTION_ERROR: {
      return {
        ...state,
        updateEventQuestionLoading: false,
        error: action.error,
        viewEvent: [],
      };
    }
    case UPDATE_DRESSAGE_REQ: {
      return {
        ...state,
        // isLoading: true,
        updateDressageLoading: true,
        error: null,
        viewEvent: {},
      };
    }
    case UPDATE_DRESSAGE_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        updateDressageLoading: false,
        error: null,
        viewEvent: action.res,
      };
    }
    case UPDATE_DRESSAGE_ERROR: {
      return {
        ...state,
        updateDressageLoading: false,
        error: action.error,
        viewEvent: [],
      };
    }
    case UPDATE_CROSS_COUNTRY_REQ: {
      return {
        ...state,
        // isLoading: true,
        updateCrossCountryLodaing: true,
        error: null,
        viewEvent: {},
      };
    }
    case UPDATE_CROSS_COUNTRY_SUCCESSS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        updateCrossCountryLodaing: false,
        error: null,
        viewEvent: action.res,
      };
    }
    case UPDATE_CROSSS_COUNTRY_ERROR: {
      return {
        ...state,
        updateCrossCountryLodaing: false,
        error: action.error,
        viewEvent: [],
      };
    }

    case CREATE_SHOW_JUMPING_FENCS_REQ: {
      return {
        ...state,
        // isLoading: true,
        createFencsLoading: true,
        error: null,
        viewEvent: {},
      };
    }
    case CREATE_SHOW_JUMPING_FENCS_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        createFencsLoading: false,
        error: null,
        viewEvent: action.res,
      };
    }
    case CREATE_SHOW_JUMPING_FENCS_ERROR: {
      return {
        ...state,
        updateCrossCountryLodaing: false,
        error: action.error,
        viewEvent: [],
      };
    }
    case GET_COMPLETE_EVENT_REQ: {
      return {
        ...state,
        // isLoading: true,
        isGetCompleteEvent: true,
        error: null,
        completeEvent: [],
        leaderBoard: [],
      };
    }
    case GET_COMPLETE_EVENT_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isGetCompleteEvent: false,
        error: null,
        completeEvent: action.res,
        leaderBoard: [],
      };
    }
    case GET_COMPLETE_EVENT_ERROR: {
      return {
        ...state,
        isGetCompleteEvent: false,
        error: action.error,
        completeEvent: [],
      };
    }
    case GET_CLASS_BY_EVENT_REQ: {
      return {
        ...state,
        // isLoading: true,
        isClassByEvent: true,
        error: null,
        classByEvent: [],
        leaderBoard: [],
      };
    }
    case GET_CLASS_BY_EVENT_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isClassByEvent: false,
        error: null,
        classByEvent: action.res,
      };
    }
    case GET_CLASS_BY_EVENT_ERROR: {
      return {
        ...state,
        isClassByEvent: false,
        error: action.error,
        classByEvent: [],
      };
    }
    case GET_LEADERBOARD_REQ: {
      return {
        ...state,
        // isLoading: true,
        isLeaderBoard: true,
        error: null,
        leaderBoard: [],
      };
    }
    case GET_LEADERBOARD_SUCCESS: {
      //   const arr = action.res?.map((item) => {
      //     return { name: item.name, id: item.id };
      //   });
      // console.log
      return {
        ...state,
        isLeaderBoard: false,
        error: null,
        leaderBoard: action.res,
      };
    }
    case GET_LEADERBOARD_ERROR: {
      return {
        ...state,
        isLeaderBoard: false,
        error: action.error,
        leaderBoard: [],
      };
    }
    default:
      break;
  }

  return state;
}
