import React, { Component } from "react";
import SignUpContainer from "../../components/containers/SignUpContainer";
import SocialLoginContainer from "../../components/containers/SocialLoginContainer";
import DatePicker from "../../components/input/DatePicker";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { AuthAction } from "../../store/actions";
import { Formik, Form } from "formik";
import DefaultInput from "../../components/input/Input";
import { socialSignupValidations } from "../../config/schemas";
import { Radio } from "antd";
import { Metrix, Images, Fonts, Colors, Variables } from "../../config";
import { RiCalendar2Fill } from "react-icons/ri";
import ButtonForm from "../../components/button/Button";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import Info from "../../components/info";

class SocialLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: moment().format("DD-MM-YYYY"),
      companyName: "",
      termsAndConditions: false,
      dateError: false,
      gender: "",
    };
  }
  changeDate = (dateMoment, dateString) => {
    this.setState({
      dateError: false,
    });
    if (
      (
        (new Date() - new Date(dateMoment)) /
        1000 /
        60 /
        60 /
        24 /
        365
      ).toFixed() < 18
    ) {
      this.setState({
        dateError: true,
      });
      Info("error", "Age must be greater than 18");

      return;
    }
    this.setState({ dob: dateString });
  };
  render() {
    const { dob, companyName, termsAndConditions, dateError, gender } =
      this.state;
    const { socialExtraDetails } = this.props;
    if (!this.props?.history?.location?.state?.token) {
      return <Redirect to="signin" />;
    }
    return (
      <SocialLoginContainer>
        <Row justify="center" gutter={[10, 10]}>
          <Col align="middle" span={24}>
            <h1 style={{ color: Colors.White }}>Welcome</h1>
            <h3 style={{ color: Colors.White }}> Please Sign up To Continue</h3>
          </Col>
          <Col xs={16} md={8} lg={8} align="middle">
            <Formik
              initialValues={{
                dob,
                companyName,
                termsAndConditions,
                gender,
              }}
              validationSchema={socialSignupValidations}
              onSubmit={(values) => {
                let val = {
                  ...values,
                  dob: this.state.dob,
                };
                

                !dateError &&
                  socialExtraDetails(
                    {
                      ...val,
                      token: this.props?.history?.location?.state?.token,
                    },
                    () => {
                      this.props.history.push({
                        pathname: "/",
                      });
                    }
                  );
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                resetForm,
              }) => {
                
                return (
                  <Form>
                    <Row gutter={[10, 5]}>
                      <Col
                        span={24}
                        align="start"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <p style={{ color: Colors.White }}>Date of Birth*</p>
                        {/* <DefaultInput name="age" onChange={handleChange} /> */}
                        <DatePicker
                          allowClear={false}
                          name="dob"
                          format="DD/MM/YYYY"
                          onChange={this.changeDate}
                          suffixIcon={
                            <RiCalendar2Fill
                              size={25}
                              style={{
                                cursor: "pointer",
                                borderRadius: "5px",
                                color: "white",
                                backgroundColor: "#00A99D",
                              }}
                            />
                          }
                          style={{ borderColor: "#00A99D" }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {dateError && "Age must be greater then 18"}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>Company Name*</p>
                        <DefaultInput
                          name="companyName"
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.companyName &&
                            touched.companyName &&
                            errors.companyName}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>Gender*</p>
                      </Col>

                      <Col span={24} align="middle">
                        <Radio.Group
                          onChange={handleChange}
                          name="gender"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "2.2rem",
                          }}
                        >
                          <Radio style={{ color: "white" }} value={"male"}>
                            Male
                          </Radio>
                          <Radio value={"female"} style={{ color: "white" }}>
                            Female
                          </Radio>
                          <Radio value={"other"} style={{ color: "white" }}>
                            Other
                          </Radio>
                        </Radio.Group>
                        <label style={{ color: Colors.Red }}>
                          {errors.gender && touched.gender && errors.gender}
                        </label>
                      </Col>

                      <Col
                        md={24}
                        lg={24}
                        align="middle"
                        style={{ marginTop: "0.8rem" }}
                      >
                        <Row justify="space-between">
                          <Col md={3} lg={4} align="end">
                            <Radio
                              name="termsAndConditions"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col
                            lg={20}
                            align="start"
                            style={{ marginBottom: "1rem" }}
                          >
                            <a
                              href="https://eventpluz.com/terms-and-conditions"
                              style={{ color: Colors.White }}
                            >
                              Agree to Privacy Policy Statement
                            </a>
                          </Col>
                        </Row>
                        <label style={{ color: Colors.Red }}>
                          {errors.termsAndConditions &&
                            touched.termsAndConditions &&
                            errors.termsAndConditions}
                        </label>
                      </Col>
                      <Col span={24} align="middle" style={{ width: "100%" }}>
                        <ButtonForm
                          htmlType="submit"
                          loading={this.props.loader}
                        >
                          SignUp
                        </ButtonForm>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            />
          </Col>
        </Row>
      </SocialLoginContainer>
    );
  }
}

function mapStateToProps(storeState) {
  return {
    loader: storeState?.AuthReducer.loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    socialExtraDetails: (payload, cb) => {
      dispatch(AuthAction.SocialExtraDetailsReq(payload, cb));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
