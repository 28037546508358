import * as Yup from "yup";

export const profileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Full Name is Too Short! it must be between 3 to 10 characters")
    .max(10, "Full Name is Too Long! it must be between 3 to 10 characters")
    // .min(2, "Full Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Full Name is Too Long! it must be between 2 to 50 characters")
    .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    // .matches(/^[a-z0-9.\s]+$/i, "Only alphabets digits and periods are allowed")
    .required("First name required"),
  lastName: Yup.string()
    .min(3, "Full Name is Too Short! it must be between 3 to 10 characters")
    .max(10, "Full Name is Too Long! it must be between 3 to 10 characters")
    // .min(2, "Full Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Full Name is Too Long! it must be between 2 to 50 characters")
    .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    // .matches(/^[a-z0-9.\s]+$/i, "Only alphabets digits and periods are allowed")
    .required("Last name required"),

  email: Yup.string()
    .email("Email is not valid!")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    )
    .required("Email required"),
  dob: Yup.date(),
  // .test('age', "You must be 18 or older", function(dob) {
  //   const cutoff = new Date();
  //   cutoff.setFullYear(cutoff.getFullYear() - 18);
  //   return dob <= cutoff;
  // }
  companyName: Yup.string()
    .min(5, "Company Name is Too Short! it must be between 5 to 50 character")
    .max(50, "Company Name is Too Long! it must be between 5 to 50 character")
    // .min(2, "Full Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Full Name is Too Long! it must be between 2 to 50 characters")

    // .matches(/^[a-z0-9.\s]+$/i, "Only alphabets digits and periods are allowed")
    .required("Company name required"),

  password: Yup.string()
    .min(8, "Password length should be atleast 8")
    // .max(16, "Password is too long! it must be between 6 and 16 characters")
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=_()]).*$/,
      "Password must contain at least eight characters,one uppercase letter,one number and one symbol"
    )
    .required("Password required"),

  confirmPassword: Yup.mixed()
    .oneOf([Yup.ref("password")], "Passwords do not match!")
    .required("Confirm password required"),

  gender: Yup.string().required("Please Select the gender"),

  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "Must accept terms and conditions"
  ),
});
