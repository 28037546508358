// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import CustomModal from "../../components/modals/customModal";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import "./common.css";
import DefaultInput from "../../components/input/Input";
import { Row, Col } from "antd";
import ButtonForm from "../../components/button/Button";
import { useDispatch } from "react-redux";
import { UserAction } from "../../store/actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "lightgrey",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div>
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <DefaultInput
      // className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (storeState) => storeState.UserReducer.isLoading
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      card: elements.getElement(CardExpiryElement),
      card: elements.getElement(CardCvcElement),
      billing_details: billingDetails,
    });
    if (payload.error) {
      
      setError(payload?.error?.message);
      return;
    } else {
      setPaymentMethod(payload.paymentMethod);
    }
    dispatch(
      UserAction.createSubscriptionReq(
        {
          ...payload,
          plan: props.location.state.plan,
        },
        () => openModal()
      )
    );

    setProcessing(false);
  };
  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
    history.push("/create-event");
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
    });
  };
  return (
    <Row>
      <Col lg={24} sm={24} xs={24} xl={24} md={24}>
        <form onSubmit={handleSubmit}>
          <fieldset className="FormGroup">
            <Row gutter={[20, 10]}>
              <Col lg={12} xl={12} md={12} sm={22} xs={22} align="start">
                <Field
                  label="Name on card"
                  id="name"
                  type="text"
                  placeholder="Name on card"
                  required
                  autoComplete="name"
                  value={billingDetails.name}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      name: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col lg={12} xl={12} md={12} sm={22} xs={22} align="start">
                <Field
                  label="Email"
                  id="email"
                  type="email"
                  placeholder="Email"
                  required
                  autoComplete="email"
                  value={billingDetails.email}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      email: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col lg={12} xl={12} md={12} sm={22} xs={22} align="start">
                <Field
                  label="Phone"
                  id="phone"
                  type="tel"
                  placeholder="(000) 000-0000"
                  required
                  autoComplete="tel"
                  value={billingDetails.phone}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      phone: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col lg={12} xl={12} md={12} sm={22} xs={22} align="start">
                <span className="FormRowLabel">Card Number</span>
                <div
                  style={{
                    border: "solid lightgrey 1px",
                    borderRadius: "5px",
                    height: "2.5rem",
                    paddingLeft: "7px",
                  }}
                >
                  <CardNumberElement
                    onChange={(e) => {
                      
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              </Col>
              <Col lg={12} xl={12} md={12} sm={22} xs={22} align="start">
                <span className="FormRowLabel">Card Exipry</span>
                <div
                  style={{
                    border: "solid lightgrey 1px",
                    borderRadius: "5px",
                    height: "2.5rem",
                    paddingLeft: "7px",
                  }}
                >
                  <CardExpiryElement
                    onChange={(e) => {
                      
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              </Col>
              <Col lg={12} xl={12} md={12} sm={22} xs={22} align="start">
                <span className="FormRowLabel">CVC</span>
                <div
                  style={{
                    border: "solid lightgrey 1px",
                    borderRadius: "5px",
                    height: "2.5rem",
                    paddingLeft: "7px",
                  }}
                >
                  <CardCvcElement
                    options={CARD_OPTIONS}
                    onChange={(e) => {
                      
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </fieldset>
          {error && (
            <p>
              <span style={{ color: "lightblue",cursor:'pointer' }} onClick={()=>setError(null)}>X</span>{" "}
              <span style={{ color: "red" }}>{error}</span>{" "}
            </p>
          )}
          <Row justify="center">
            <Col lg={12} xl={12} md={12} sm={22} xs={22}>
              <ButtonForm
                htmlType="submit"
                loading={isLoading}
                // onClick={}
              >
                PAY {props.location.state.price}
              </ButtonForm>
            </Col>
          </Row>
        </form>
      </Col>
      <CustomModal visible={visible} onCancelModal={closeModal}>
        <Row justify="center" gutter={[0, 20]}>
          <Col span={24}></Col>
          <Col span={24}>
            <h1 className="text-center">Payment Confirmed</h1>
            <p className="text-center">Thank you for payment!</p>
            <p className="text-center">We've emailed you a confirmation</p>
          </Col>
          <Col
            span={8}
            style={{ marginBottom: "10px" }}
            onClick={() => history.push("/create-event")}
          >
            <ButtonForm>Thank You!</ButtonForm>
          </Col>
        </Row>
      </CustomModal>
    </Row>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51J8P0CFXq0bRsc1TjpP4eNPzYl057JtkDRUQKX8M3URETobEWxNpqRgfmTT9RCeZa8PEyfEVmrcxRvioR2VDhTEe005C2nMVCk"
);

const PaymentForm = (props) => {
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm {...props} />
      </Elements>
    </div>
  );
};

export default PaymentForm;
