import React from "react";
import { Row, Col } from "antd";
import Backbtn from "../button/BackButton.js";
import { BiArrowBack } from "react-icons/bi";

function EventHeading({ title,arrowBtn,goBack }) {
  return (
    <Row justify="start" align="middle">
      <Col xs={6} sm={6} md={2}></Col>
      <Col lg={7} sm={7} md={7} xs={7}  >
        <h1 className="event-headings">
          {arrowBtn ? (
            <Backbtn style={{marginRight:'1rem'}} onClick={()=>goBack()} width="10%" icon={<BiArrowBack color="black" size={30}/>} />
          ) : (
            ""
          )}
          {title}
        </h1>
      </Col>
    </Row>
  );
}
export default EventHeading;
