import React from "react";
import { Component } from "react";
import { Row, Col } from "antd";
import SearchBar from "../../components/input/Search";
import Notification from "../../assets/images/icons/notification.svg";
import Card from "../../components/eventCard";
import { connect } from "react-redux";
import EventAction from "../../store/actions/EventAction";
import { LoadingOutlined } from "@ant-design/icons";

class MyEvent extends Component {
  constructor() {
    super();
    this.state = {
      offset: 0,
      limit: 1000,
      like: "",
    };
  }
  componentDidMount() {
    const { getMyEvent } = this.props;
    getMyEvent({ ...this.state });
  }
  onHandleSearch = (searchTerm) => {
    const { getMyEvent } = this.props;

    getMyEvent({ ...this.state, like: searchTerm });
  };
  handleChanged = (e) => {
    this.setState({
      like: e.target.value,
    });
    this.props.getMyEvent({ ...this.state, like: e.target.value });
  };
  render() {
    const { myEvent, isMyEventLoading } = this.props;

    return (
      <Row gutter={[0, 30]} align="top" justify="start" className="card-row">
        <Col span={24}>
          <Row justify="space-between">
            <Col span={4}>
              <h1>My Event</h1>
            </Col>
          </Row>
        </Col>
        {!isMyEventLoading ? (
          <Col span={24}>
            {!myEvent.length && <h1>No Data Found!</h1>}
            <Row gutter={[20, 30]}>
              {myEvent.map((item) => {
                return (
                  <Col
                    lg={6}
                    md={8}
                    sm={12}
                    xs={24}
                    xl={6}
                    className="pointer-cursor"
                    onClick={() =>
                      this.props.history.push(`/my-event/${item.id}`)
                    }
                  >
                    <Card
                      item={item}
                      myEvent={true}
                      updateStatus={() =>
                        this.props.updateStatus(item.id, () =>
                          this.props.getMyEvent({ ...this.state })
                        )
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        ) : (
          <LoadingOutlined style={{ fontSize: "24", margin: "auto" }} spin />
        )}
      </Row>
    );
  }
}
function mapStateToProps(state) {
  return {
    myEvent: state.EventReducer?.myEvent?.rows || [],
    isMyEventLoading: state.EventReducer?.isMyEventLodaing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMyEvent: (parmas) => dispatch(EventAction.getMyEventReq(parmas)),
    updateStatus: (cb, CB) =>
      dispatch(EventAction.confirmEventReq(cb, CB, { status: 3 })),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MyEvent);
