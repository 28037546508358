import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import Colors from "../../config/colors";

const StyledButton = styled(Button)`
  background-color:${(props) =>
    props.color ? props.color : Colors.Primary};;
  color: ${Colors.White};
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  height: 2.5rem;
  border:none;
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) =>
      props.color ? props.color : Colors.Primary};
      border:none;
    color: ${Colors.White};
  }
`;

const DefaultButton = (props) => {
  return <StyledButton {...props}></StyledButton>;
};

export default DefaultButton;
