import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/images/app_logo.svg";
import { Col, Row } from "antd";

const ReactNotificationComponent = ({ title, body }) => {
  let hideNotif = title === "";

  if (!hideNotif) {
    toast.info(<Display />);
  }

  function Display() {
    return (
      <Row style={{height: "8%", padding: "0.5rem"}} justify="space-between" gutter={[30, 0]}>
        <Col span={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img src={Logo} style={{ height: 35 }} />
        </Col>
        <Col span={18}>
          <h3 style={{ fontWeight: "bold", textAlign: "left" }}>{title}</h3>
          <p style={{fontSize: "14px", textAlign: "left"}}>{body}</p>
        </Col>
      </Row>
    );
  }

  return (
    <ToastContainer
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
    />
  );
};

ReactNotificationComponent.defaultProps = {
  title: "This is title",
  body: "Some body",
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ReactNotificationComponent;
