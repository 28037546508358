import {
  UPDATE_PROFILE_REQ,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  GET_NOTIFICATION_REQ,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  DELETE_ACCOUNT_REQ,
  CREATE_SUBSCRIPTION_REQ,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_ERROR,
  CREATE_ACCOUNT_REQ,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  CREATE_LINK_REQ,
  CREATE_LINK_SUCCESS,
  CREATE_LINK_ERROR,
  CHECK_ACCOUNT_REQ,
  CHECK_ACCOUNT_SUCCESS,
  CHECK_ACCOUNT_ERROR,
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLANE_REQ,
  GET_SUBSCRIPTION_PLANE_SUCCESS,
  GET_SUBSCRIPTION_PLANE_ERROR,
} from "../constants";

export default class UserAction {
  static updateProfileReq(payload, cb) {
    return {
      type: UPDATE_PROFILE_REQ,
      payload,
      cb,
    };
  }
  static updateProfileSuccess(res) {
    return {
      type: UPDATE_PROFILE_SUCCESS,
      res,
    };
  }
  static updateProfileError(e) {
    return {
      type: UPDATE_PROFILE_ERROR,
      e,
    };
  }
  static getNotificationReq(parmas, cb) {
    return {
      type: GET_NOTIFICATION_REQ,
      parmas,
      cb,
    };
  }
  static getNotificationSuccess(res) {
    return {
      type: GET_NOTIFICATION_SUCCESS,
      res,
    };
  }
  static getNotificationError(e) {
    return {
      type: GET_NOTIFICATION_ERROR,
      e,
    };
  }
  static delteAccount() {
    return {
      type: DELETE_ACCOUNT_REQ,
    };
  }
  static createSubscriptionReq(data, cb, pushState) {
    return {
      type: CREATE_SUBSCRIPTION_REQ,
      data,
      cb,
      pushState,
    };
  }
  static createSubscriptionSuccess(res) {
    return {
      type: CREATE_SUBSCRIPTION_SUCCESS,
      res,
    };
  }
  static createSubscriptionError(e) {
    return {
      type: CREATE_SUBSCRIPTION_ERROR,
      e,
    };
  }
  static createAccountReq(data) {
    return {
      type: CREATE_ACCOUNT_REQ,
      data,
    };
  }
  static createAccountSuccess() {
    return {
      type: CREATE_ACCOUNT_SUCCESS,
    };
  }
  static createAccountError() {
    return {
      type: CREATE_ACCOUNT_ERROR,
    };
  }
  static createLinkReq(data) {
    return {
      type: CREATE_LINK_REQ,
      data,
    };
  }
  static createLinkSuccess() {
    return {
      type: CREATE_LINK_SUCCESS,
    };
  }
  static createLinkError() {
    return {
      type: CREATE_LINK_ERROR,
    };
  }
  static checkAccountReq(data) {
    return {
      type: CHECK_ACCOUNT_REQ,
      data,
    };
  }
  static checkAccountSuccess(res) {
    return {
      type: CHECK_ACCOUNT_SUCCESS,
      res,
    };
  }
  static checkAccountError() {
    return {
      type: CHECK_ACCOUNT_ERROR,
    };
  }
  static cancelSubscription() {
    return {
      type: CANCEL_SUBSCRIPTION,
    };
  }

  static getSubscriptionPlanReq(data) {
    return {
      type: GET_SUBSCRIPTION_PLANE_REQ,
    };
  }
  static getSubscriptionPlanSuccess(res) {
    return {
      type: GET_SUBSCRIPTION_PLANE_SUCCESS,
      res,
    };
  }
  static getSubscriptionPlanError() {
    return {
      type: GET_SUBSCRIPTION_PLANE_ERROR,
    };
  }
}
