import React from "react";
import "./style.css";
import DeletComponent from "../deleteComponent";
import { FaRegEdit } from "react-icons/fa";
import { Row, Col } from "antd";
function ShowJumpingListing(props) {
  const { item, deletShowJumping, showJumpingEdit, isUpdate } = props;
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="showjumping-grid">
        <tr>
          <th className="table-heading">Class Name</th>
          <th className="table-heading">Optimum Time</th>
          <th className="table-heading">Course Length</th>
          <th className="table-heading">no. Of Fences</th>
          <th className="table-heading">Name of judge</th>
          <th className="table-heading">Map Image</th>
          {/* <th className="table-heading">Description File</th> */}
          <th className="table-heading"></th>
        </tr>
        {(item || []).map((data, index) => {
          let fileName = data?.file_url;
          const liv = fileName?.split("/");
          let imgUrl = data?.image_url;
          const imgSplit = imgUrl?.split("/");

          return (
            <tr>
              <td className="table-data">
                {data?.ShowJumpingClass?.UserClass?.name}
              </td>
              <td className="table-data">{data?.optimum_time}</td>
              <td className="table-data">{data?.course_length}</td>
              <td className="table-data">{data?.ShowJumpingFences?.length}</td>
              <td className="table-data">{data?.Judge?.firstName}</td>
              <td className="table-data">
                <a href={imgUrl} target="_blank">
                  {imgUrl ? "View Image" : "-"}
                </a>{" "}
                {/* {liv?.[liv.length - 1]} */}
              </td>

              <td className="table-data">
                <Row align="middle">
                  <Col span={4}>
                    {" "}
                    {isUpdate && (
                      <FaRegEdit
                        onClick={() => showJumpingEdit(index)}
                        color="#00A99D"
                        size={25}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    <DeletComponent
                      onClick={() =>
                        deletShowJumping({ showJumpingId: data.id })
                      }
                    />
                  </Col>
                </Row>
              </td>
              {/* <td
                className="table-data"
                onClick={() => deletShowJumping({ showJumpingId: data.id })}
              >
                <DeletComponent />
              </td> */}
            </tr>
          );
        })}
      </table>
    </div>
  );
}
export default ShowJumpingListing;
