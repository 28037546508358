import React from "react";
import styled from "styled-components";
import Colors from "../../config/colors";
import "./Styles.css";
import background from "../../assets/images/background.png";

const SignInContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  // overflow: hidden;
  height: ${props=>props.height?props.height:'100vh'};
  display: flex;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none !important ;
  }

  @media screen and (device-aspect-ratio: 40/71) {
    padding: 2rem;
    height: 105vh;
  }


  @media screen and (device-aspect-ratio: 3/4) {
    height: 100vh;
    padding:6rem 0rem;
  }

  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    padding: 2rem 2rem !important;
  }

  /*iPhone6/7/8 Plus*/
  @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    padding: 2rem 2rem !important;
    height: 100vh;
  }

  /*iPhone X*/
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    padding: 2rem 2rem !important;
    height: 100vh;
  }


 
`;


const StyledContainer = (props) => {
  return (
    <SignInContainer {...props}>
      {props.children}
    </SignInContainer>
  );
};

export default StyledContainer;
