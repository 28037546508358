import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/loader";
import AuthRoutes from "./AuthRoutes";
// const Login = lazy(() => import("./views/auth/LoginCard"));
// const Lawyer = lazy(() => import("./views/landingpage/Lawyer"));
// const Signup = lazy(() => import("./views/auth/signup"));
// const LandingScreen = lazy(() => import("./views/landingpage/Main"));
// const ForgotPassword = lazy(() => import("./views/auth/ForgotPassword"));
// const VerificationCode = lazy(() => import("./views/auth/VerificationCode"));
// const ResetPassword = lazy(() => import("./views/auth/ResetPassword"));
// const Shell = lazy(() => import("./views/shell"));
// const Subscription = lazy(() => import("./views/Subscription"))
import SignIn from "../../containers/SignIn";
import SignUp from "../../containers/SignUp";
import PrivacyPolicy from "../../containers/SignUp/PrivatePolicy";
import ForgotPassword from "../../containers/ForgetPassword/FP1";
import VerifyCode from "../../containers/ForgetPassword/FP2";
import ResetPassword from "../../containers/ForgetPassword/FP3";
import VerficationCode from "../../containers/SignIn/VerficationCode";
import SocialLogin from '../../containers/socialSignin'
class Routes extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { isAuthenticated } = this.props;
    // let userRole = data.role;
    return (
      <Suspense fallback={<Loader />}>
        
          <Switch>
            {!this.props.isAuthenticated ? (
              <React.Fragment>
                <Switch>
                  <Route exact path="/signin" component={SignIn} />
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path='/social-login' component={SocialLogin}/>
                  {/* <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  /> */}
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route exact path="/verify-code" component={VerifyCode} />

                  <Route
                    exact
                    path="/verification-code"
                    component={VerficationCode}
                  />

                  <Route
                    exact
                    path="/reset-password"
                    component={ResetPassword}
                  />
                  {/* <Route path="/" component={AuthRoutes} /> */}
                  <Route path="*" render={() => <Redirect to="/signin" />} />
                </Switch>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Switch>
                  <Route path="/" component={AuthRoutes} />
                </Switch>
              </React.Fragment>
            )}
          </Switch>
      </Suspense>
    );
  }
}

// const ProtectedRoute = ({ enabled, ...props }) => (
//   enabled && <Route {...props} />
// )

export default connect((storeState) =>{

 return {
    isAuthenticated: storeState.AuthReducer?.isAuthenticated,
  }
} )(Routes);
