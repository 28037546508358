import React, { Component } from "react";
import logo from "../../assets/images/logo.png";
import FPContainer from "../../components/containers/FPContainer";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { Images, Fonts, Colors } from "../../config";
import { fp3Validations } from "../../config/schemas";
import { AuthAction } from "../../store/actions";
import BackButton from "../../components/button/BackButton";
import { Row, Col } from "antd";
import { BiArrowBack } from "react-icons/bi";
import Image from "../../components/image";
import DefaultInput from "../../components/input/Input";
import DefaultButton from "../../components/button/Button";
import { connect } from "react-redux";
import { AuthMiddleware } from "../../store/middlewares";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: "",
      confirmPassword: "",
    };
  }

  componentDidMount() {
    if (this.props.history.location.state != undefined) {
      if (this.props.history.location.state.email != undefined) {
        this.setState({
          email: this.props.history.location.state.email,
        });
      } else {
        this.props.history.push({
          pathname: "/signin",
        });
      }
    } else {
      this.props.history.push({
        pathname: "/signin",
      });
    }
  }

  render() {
    const { updatePassword } = this.props;
    // const { new_password, confirm_password, validPass } = this.state;
    return (
      <FPContainer>
        <Row justify="center" gutter={[0, 40]}>
          <Col xs={22} sm={22} md={24} lg={13} xl={10} align="middle">
            <Image src={logo} />
          </Col>
          <Col md={18} lg={15} xl={15} align="middle">
            <Row justify="center" gutter={[0, 40]}>
              <Col span={24}>
                <Row justify="center" align="middle">
                  <Col xs={6} md={5} lg={3}>
                    <Link to="/verify-code" style={{ color: "#707070" }}>
                      <BackButton
                        icon={<BiArrowBack color="white" size={20} />}
                      />
                    </Link>
                  </Col>
                  <Col xs={18} md={15} lg={12} align="start">
                    <h1 style={{ color: Colors.White }}>Enter New Password</h1>
                  </Col>
                </Row>

                <p style={{ color: Colors.White }}>
                  Please Enter your password
                </p>
              </Col>
              <Col xs={24} md={24} lg={22}>
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={fp3Validations}
                  onSubmit={(values) => {
                    let val = {
                      ...values,
                      email: this.props.history.location.state.email,
                    };
                    updatePassword(val, () => {
                      this.props.history.push({
                        pathname: "/signin",
                      });
                    });
                  }}
                  render={({ values, touched, handleChange, errors }) => {
                    return (
                      <Form>
                        <Row justify="center" gutter={[0, 40]}>
                          <Col
                            xs={14}
                            sm={16}
                            md={14}
                            lg={13}
                            xl={14}
                            align="start"
                          >
                            <p style={{ color: "white" }}>Password*</p>
                            <DefaultInput
                              name="password"
                              password={true}
                              onChange={handleChange}
                            />
                            <label style={{ color: Colors.Red }}>
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </label>
                          </Col>
                          <Col
                            xs={14}
                            sm={16}
                            md={14}
                            lg={13}
                            xl={14}
                            align="start"
                          >
                            <p style={{ color: "white" }}>Confirm Password*</p>
                            <DefaultInput
                              name="confirmPassword"
                              password={true}
                              onChange={handleChange}
                            />
                            <label style={{ color: Colors.Red }}>
                              {errors.confirmPassword &&
                                touched.confirmPassword &&
                                errors.confirmPassword}
                            </label>
                          </Col>
                          <Col
                            xs={14}
                            sm={16}
                            md={14}
                            lg={13}
                            xl={14}
                            align="middle"
                          >
                            <DefaultButton
                              htmlType="submit"
                              loading={this.props.loader}
                            >
                              Continue
                            </DefaultButton>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </FPContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.AuthReducer.user,
    loader: state?.AuthReducer.loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePassword: (payload, cb) => {
      dispatch(AuthAction.ResetPassword(payload, cb));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
