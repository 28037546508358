import React from "react";
import styled, { css } from "styled-components";
import { Input } from "antd";


const { TextArea } = Input;
const StyledTextArea = styled(TextArea).attrs({className: 'inputField'})`
  border-radius: 5px;
  height: 8rem;
`;

const DefaultTextArea = (props) => {
  return <StyledTextArea {...props}></StyledTextArea>;
};

export default DefaultTextArea;
