import React, { Component } from "react";
import HeadingComponet from "../../components/eventsHeading";
import { Row, Col, Radio } from "antd";
import DefaultInput from "../../components/input/Input";
import ButtonForm from "../../components/button/Button";
import { BiPlusMedical } from "react-icons/bi";
import { Formik, Form } from "formik";
import DefaultDatePicker from "../../components/input/DatePicker";
import { RiCalendar2Fill } from "react-icons/ri";
import Info from "../../components/info";
import moment from "moment";
import { connect } from "react-redux";
import { UserAction } from "../../store/actions";
import { editProfileEvents } from "../../config/schemas";
const user = JSON.parse(localStorage.getItem("eventAuth"));

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      coverImageUrl: "",
      dateError: false,
      requiredDate: "",
      dob: moment().format("YYYY-MM-DD"),
      coverImage: "",
      gender: "",
    };
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("eventAuth"));

    this.createFile();
    const event = {
      target: {
        name: "firstName",
        value: user.user.firstName,
      },
    };
    const e = {
      target: {
        name: "lastName",
        value: user.user.lastName,
      },
    };
    const el = {
      target: {
        name: "companyName",
        value: user.user.companyName,
      },
    };
    const em = {
      target: {
        name: "email",
        value: user.user.email,
      },
    };
    const datevent = {
      target: {
        name: "dob",
        value: user.user.dob,
      },
    };
    const genderEvent = {
      target: {
        name: "gender",
        value: user.user.gender,
      },
    };
    this.formikChange(e);
    this.formikChange(el);
    this.formikChange(event);
    this.formikChange(em);
    this.formikChange(datevent);
    this.formikChange(genderEvent);
    this.setState({
      coverImageUrl: user.user.thumbnailProfilePicture,
      dob: user.user.dob,
      gender: user.user.gender,
    });
  }
  changeDate = (dateMoment, dateString) => {
    this.setState({
      dateError: false,
      requiredDate: "",
    });
    if (
      (
        (new Date() - new Date(dateMoment)) /
        1000 /
        60 /
        60 /
        24 /
        365
      ).toFixed() < 18
    ) {
      this.setState({
        dateError: true,
      });
      Info("error", "Age must be greater than 18");

      return;
    }
    
    this.setState({ dob: dateString });
    const event = {
      target: {
        value: dateString,
        name: "dob",
      },
    };
    this.formikChange(event);
  };
  createFile = async () => {
    const user = JSON.parse(localStorage.getItem("eventAuth"));

    let response = await fetch(user.user.thumbnailProfilePicture);
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "test.jpg", metadata);
    this.setState({
      coverImage: file,
    });
    const e = {
      target: {
        value: file,
        name: "image",
      },
    };
    this.formikChange(e);
  };
  // DisplayingErrorMessagesSchema = Yup.object().shape({
  //   firstName: Yup.string()
  //     .min(2, "Too Short!")
  //     .max(50, "Too Long!")
  //     .required("Required"),
  //   email: Yup.string().email("Invalid email").required("Required"),
  // });
  render() {
    var inputFileRef;
    const { updateProfile } = this.props;
    return (
      <>
        <HeadingComponet title="Edit Profile" />
        <Row justify="center">
          <Col xs={20} sm={12} md={12} lg={12} xl={12} align="start">
            <Formik
              initialValues={{
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                dob: this.state.dob,
                companyName: this.state.companyName,
                image: this.state.coverImage,
                role: "event manager",
                gender: this.state.gender,
              }}
              validationSchema={editProfileEvents}
              onSubmit={(values) => {
                updateProfile(values, () =>
                  this.props.history.push("/settings")
                );
                const em = {
                  target: {
                    name: "email",
                    value: user.user.email,
                  },
                };
                this.formikChange(em);
              }}
              render={({ values, touched, handleChange, errors }) => {
                this.formikChange = handleChange;
                return (
                  <Form>
                    <Row
                      gutter={[10, 20]}
                      justify="center"
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: "1.5rem",
                      }}
                    >
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        style={{
                          height: "158px",
                          borderRadius: "50%",
                          backgroundImage: `url(${this.state.coverImageUrl})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                        }}
                      ></Col>

                      <Col
                        style={{
                          marginLeft: "-9%",
                          backgroundColor: "black",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          height: "30px",
                        }}
                      >
                        <input
                          type="file"
                          id="avatar"
                          name="avatar"
                          accept="image/png, image/jpeg"
                          className="d-none"
                          ref={(ref) => (inputFileRef = ref)}
                          onChange={(e) => {
                            let reader = new FileReader();
                            let file = e.target.files[0];

                            reader.onloadend = () => {
                              this.setState({
                                coverImageUrl: reader.result,
                              });
                            };

                            reader.readAsDataURL(file);
                            const event = {
                              target: {
                                name: "image",
                                value: e.target.files[0],
                              },
                            };
                            handleChange(event);
                          }}
                        />
                        <BiPlusMedical
                          size={20}
                          color={"white"}
                          className="pointer-cursor"
                          onClick={() => {
                            inputFileRef.click();
                          }}
                        />
                      </Col>
                      <label style={{ color: "red" }}>
                        {errors.image && touched.image && errors.image}
                      </label>
                    </Row>
                    <Row gutter={[10, 20]} justify="center">
                      <Col xs={20} sm={12} md={12} lg={12} xl={9}>
                        <p>
                          First Name<sup>*</sup>
                        </p>
                        <DefaultInput
                          name="firstName"
                          className="border-input-color"
                          onChange={handleChange}
                          value={values.firstName}
                        />
                        <label style={{ color: "red" }}>
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </label>
                      </Col>

                      <Col xs={20} sm={12} md={12} lg={12} xl={9}>
                        <p>
                          Last Name<sup>*</sup>
                        </p>
                        <DefaultInput
                          name="lastName"
                          className="border-input-color"
                          onChange={handleChange}
                          value={values.lastName}
                        />
                        <label style={{ color: "red" }}>
                          {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                        </label>
                      </Col>
                      <Col xs={20} sm={12} md={12} lg={12} xl={9}>
                        <p>
                          Email<sup>*</sup>
                        </p>
                        <DefaultInput
                          name="email"
                          disabled={true}
                          className="border-input-color"
                          onChange={handleChange}
                          value={values.email}
                        />
                        <label style={{ color: "red" }}>
                          {errors.email && touched.email && errors.email}
                        </label>
                      </Col>
                      <Col xs={20} sm={12} md={12} lg={12} xl={9}>
                        <p>
                          Age<sup>*</sup>
                        </p>
                        <label style={{ color: "red" }}>
                          {errors.dob && touched.dob && errors.dob}
                        </label>
                        <DefaultDatePicker
                          allowClear={false}
                          name="dob"
                          format="YYYY-MM-DD"
                          value={moment(new Date(this.state.dob))}
                          placeholder="Select Date"
                          onChange={this.changeDate}
                          suffixIcon={
                            <RiCalendar2Fill
                              size={25}
                              style={{
                                cursor: "pointer",
                                borderRadius: "5px",
                                color: "white",
                                backgroundColor: "#00A99D",
                              }}
                            />
                          }
                          style={{ borderColor: "#00A99D" }}
                        />
                      </Col>

                      <Col xs={20} sm={12} md={12} lg={12} xl={9}>
                        <p>
                          Company Name<sup>*</sup>
                        </p>
                        <DefaultInput
                          name="companyName"
                          className="border-input-color"
                          onChange={handleChange}
                          value={values.companyName}
                        />
                        <label style={{ color: "red" }}>
                          {errors.companyName &&
                            touched.companyName &&
                            errors.companyName}
                        </label>
                      </Col>
                      <Col xs={20} sm={12} md={12} lg={12} xl={9}></Col>

                      <Col span={18} align="left">
                        <p>
                          Gender<sup>*</sup>
                        </p>
                        <Radio.Group
                          onChange={(e) => {
                            const event = {
                              target: {
                                value: e.target.value,
                                name: "gender",
                              },
                            };
                            handleChange(event);
                            this.setState({
                              gender: e.target.value,
                            });
                          }}
                          name="gender"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "2.2rem",
                          }}
                          value={this.state.gender}
                        >
                          <Row justify="center">
                            <Col span={8}>
                              <Radio style={{ color: "black" }} value={"male"}>
                                Male
                              </Radio>
                            </Col>
                            <Col span={8}>
                              <Radio
                                value={"female"}
                                style={{ color: "black" }}
                              >
                                Female
                              </Radio>
                            </Col>
                            <Col span={8}>
                              <Radio value={"other"} style={{ color: "black" }}>
                                Other
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                        <label style={{ color: "red" }}>
                          {errors.gender && touched.gender && errors.gender}
                        </label>
                      </Col>
                      <Col xs={20} sm={12} md={12} lg={12} xl={18}>
                        <ButtonForm
                          htmlType="submit"
                          loading={this.props.isLoading}
                        >
                          Save
                        </ButtonForm>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.UserReducer.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (payload, cb) =>
      dispatch(UserAction.updateProfileReq(payload, cb)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
