import React from "react";
import styled from "styled-components";
import Colors from "../../config/colors";
import "./Styles.css";
import background from "../../assets/images/background.png";

const SocialLoginContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  // height:${props => props.height ? props.height : 'auto'};
  padding:10rem 14rem;
  justify-content: center;
  height:100vh;
  display: flex;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (device-aspect-ratio: 40/71) {
    padding: 3rem 0rem !important;
    height:105vh
  }

  @media screen and (device-aspect-ratio: 3/4) {
    padding: 20rem 0 !important;
    height:105vh
  } 


  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    padding: 4rem 0rem !important;
  }
  /*iPhone6/7/8 Plus*/
  @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
         .Property name{
             ...//Style
    }
  }
  /*iPhone X*/
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
         .Property name{
             ...//style
    }
  }
`;

const StyledSocialContainer = (props) => {
  return <SocialLoginContainer {...props}>{props.children}</SocialLoginContainer>;
};

export default StyledSocialContainer;
