import { EventAction } from "../actions";
// import { Alert } from "react-native"
import { Functions, ApiCaller, Variables } from "../../config";
import info from "../../components/info";
import { put, delay } from "redux-saga/effects";
import {
  EVENT,
  EVENT_CLASS,
  EVENT_QUESTION,
  SHOWJUMPING,
  SHOWJUMPING_FENCES,
  CROSS_COUNTRY,
  CROSS_COUNTRY_FENCES,
  USER_CLASS,
  USER_EVENTS,
  CSV_TEST,
  SEARCH_JUDGE,
  COVER,
  STATUS,
  DETAILS,
  DRESSAGE,
  GET_COMPLETE_EVENT,
  GET_LEADERBOARD,

} from "../../config/variables";
export default class EventMiddleware {
  static *createEvent(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    const { eventDate } = payload;

    const updatedEventDate = eventDate?.split(" ")[0]?.split("-").join("-");
    const updatedPayLoad = {
      ...payload,
      eventDate: `${updatedEventDate} ${eventDate?.split(" ")[1]}`,
    };
    let formData = new FormData();
    for (const key in updatedPayLoad) {
      formData.append(key, updatedPayLoad[key]);
    }
    try {
      let res = yield ApiCaller.Post(EVENT, formData, {
        Accept: "multipart/form-data",

        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });

      if (res.status == 200) {
        let eventId = res.data.data.event.id;
        localStorage.setItem("eventId", eventId);
        // info("success", res.data.data.message);
        yield put(EventAction.createEventSuccess(res));

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        yield put(EventAction.createEventError());
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.createEventError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *createEventQuestion(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    try {
      let res = yield ApiCaller.Post(
        EVENT_QUESTION,
        { ...payload },
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(
          EventAction.createEventQuestionSuccess(res.data.data.eventQuestion)
        );
        // yield put(
        //   EventAction.getEventQuestionReq({
        //     eventId: localStorage.getItem("eventId"),
        //   })
        // );

        cb && cb();
      } else {
        yield put(EventAction.createEventQuestionError());
        info(
          "error",
          res.data.error.message === "Can not more than five questions"
            ? "Can not add more than five questions"
            : res.data.error.message
        );
      }
    } catch (e) {
      yield put(EventAction.createEventQuestionError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *createUserClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb, callback } = action;
    try {
      let res = yield ApiCaller.Post(
        USER_CLASS,
        { ...payload },
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(EventAction.createUserClassSuccess(res.data.data.userClass));
        // yield put(EventAction.getClassRequest());
        cb && cb();
      } else {
        yield put(EventAction.createUserClassError('Error'));

        // info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.createUserClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *createDressage(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    try {
      let res = yield ApiCaller.Post(
        DRESSAGE,
        { ...payload },
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.createDressageSuccess(res.data.data.dressage));
        // yield put(
        //   EventAction.getDressageReq({
        //     eventId: localStorage.getItem("eventId"),
        //   })
        // );

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.createDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *createEventClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    let eventId = localStorage.getItem("eventId");
    const { payload, cb } = action;
    try {
      let res = yield ApiCaller.Post(
        EVENT_CLASS,
        { ...payload },
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        let eventClassId = res.data.data.eventClass.id;
        localStorage.setItem("eventClassId", eventClassId);
        info("success", res.data.data.message);
        yield put(
          EventAction.createEventClassSuccess([res.data.data.eventClass])
        );
        yield put(
          EventAction.getEventClassReq({
            eventId: localStorage.getItem("eventId"),
          })
        );

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.createEventClassError(res.data.error.message));
      }
    } catch (e) {
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *createShowJumping(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    const sortOptimumTime = payload.optimumTime?.split(" ");
    
    const load = { ...payload };
    if (sortOptimumTime.length > 1) {
      load.optimumTime = sortOptimumTime[1];
    }
    let formData = new FormData();
    for (const key in load) {
      formData.append(key, load[key]);
    }
    try {
      let res = yield ApiCaller.Post(SHOWJUMPING, formData, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        // yield put(EventAction.createShowJumpingSuccess(res));
        yield put(
          EventAction.getShowJumpingReq({
            eventId: localStorage.getItem("eventId"),
          })
        );
        localStorage.setItem("showJumpingId", res.data.data.showJumping.id);

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.createShowJumpingError(res.data.error.message));
      }
    } catch (e) {
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *createCrossCountry(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;

    //const id = JSON.parse(localStorage.getItem("eventId"));
    const sortOptimumTime = payload.optimumTime?.split(" ");
    const load = { ...payload };
    if (sortOptimumTime.length > 1) {
      load.optimumTime = sortOptimumTime[1];
    }
    let formData = new FormData();
    for (const key in load) {
      formData.append(key, load[key]);
    }
    try {
      let res = yield ApiCaller.Post(CROSS_COUNTRY, formData, {
        Accept: "multipart/form-data",

        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        yield put(
          EventAction.getCrossCountryRequest(localStorage.getItem("eventId"))
        );
        localStorage.setItem("countryId", res.data.data.crossCountry.id);
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.createEventError(e));

      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *createCrossCountryFence(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    try {
      let res = yield ApiCaller.Post(CROSS_COUNTRY_FENCES, payload, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });

      if (res.status == 200) {
        info("success", res.data.data.message);
        // info("success",  res.data.data.message);
        yield put(EventAction.createCrossCountryFenceSuccess(res));
        yield put(
          EventAction.getCrossCountryRequest(localStorage.getItem("eventId"))
        );
        window.location.reload();

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.createCrossCountryFenceError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${USER_CLASS}?offset=0&limit=10000`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getClassSuccess(res.data.data.classes));

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.getClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getCrossCountry(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const Id = JSON.parse(localStorage.getItem("eventId"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${CROSS_COUNTRY}/${payload}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(
          EventAction.getCrossCountrySuccess(res.data.data.crossCountry)
        );

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.getCrossCountryError(res.data.error));
      }
    } catch (e) {
      yield put(EventAction.getCrossCountryError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getTest(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${CSV_TEST}?offset=${0}&limit=${10}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getTestSuccess(res.data.data.tests));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.getTestError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *searchJudge(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(
        `${SEARCH_JUDGE}?offset=0&limit=10&like=${payload}&type=judge`,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.searchJudgeSuccess(res.data.data.users.rows));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getTestError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getEventClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${EVENT_CLASS}/${payload.eventId}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });

      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getEventClassSuccess(res.data.data.eventClass));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getEventClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *getEventQuestion(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(
        `${EVENT_QUESTION}/${payload.eventClassId}`,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );

      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(
          EventAction.getEventQuestionSuccess(res.data.data.eventQuestions)
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getEventQuestionError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *deleteDressage(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Delete(`${DRESSAGE}/${payload.dressageId}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(
          EventAction.getDressageReq({
            eventId: localStorage.getItem("eventId"),
          })
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.deleteDressageError(res.data.data.eventClass));
      }
    } catch (e) {
      yield put(EventAction.deleteDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *deleteEventClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Delete(`${EVENT_CLASS}/${payload}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(EventAction.deleteEventClassSuccess(res.data.data));
        yield put(
          EventAction.getEventClassReq({
            eventId: localStorage.getItem("eventId"),
          })
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.deleteEventClassError(res.data.data.eventClass));
      }
    } catch (e) {
      yield put(EventAction.deleteEventClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *deleteEventQuestion(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Delete(
        `${EVENT_QUESTION}/${payload.EventQuestionId}`,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(EventAction.deleteEventQuestionSuccess(res.data.data));
        yield put(
          EventAction.getEventQuestionReq({
            eventClassId: localStorage.getItem("eventId"),
          })
        );
        // yield put(EventAction.getEventQuestionReq(localStorage.getItem('eventId')))
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(
          EventAction.deleteEventQuestionError(res.data.data.eventQuestion)
        );
      }
    } catch (e) {
      yield put(EventAction.deleteEventQuestionError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getDressageList(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${DRESSAGE}/${payload.eventId}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });

      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getDressageSuccess(res.data.data.dressage));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getShowJumpingList(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${SHOWJUMPING}/${payload.eventId}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });

      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getShowJumpingSuccess(res.data.data.showJumping));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *deletShowJumping(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Delete(
        `${SHOWJUMPING}/${payload.showJumpingId}`,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );

      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(
          EventAction.getShowJumpingReq({
            eventId: localStorage.getItem("eventId"),
          })
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *deleteCrossCountry(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Delete(
        `${CROSS_COUNTRY}/${payload.crossCountryId}`,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );

      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(
          EventAction.getCrossCountryRequest(localStorage.getItem("eventId"))
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *confirmEvent(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb, status } = action;
    const lsid = JSON.parse(localStorage.getItem("eventId"));
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Put(
        `${EVENT}/${payload ? payload : lsid}/${STATUS}`,
        status,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );

      if (res.status == 200) {
        info("success", res.data.data.message);
        localStorage.removeItem("eventId");
        localStorage.removeItem("eventClassId");
        // yield put(
        //   EventAction.getCrossCountryRequest(localStorage.getItem("eventId"))
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *updateEvent(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb, callBack } = action;
    const lsid = JSON.parse(localStorage.getItem("eventId"));
    delete payload.coverImage;
    try {
      let res = yield ApiCaller.Put(`${EVENT}/${cb}`, payload, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });

      if (res.status == 200) {
        info("success", res.data.data.message);

        yield put(EventAction.updateEventSuccess());
        let eventId = res.data.data.event.id;
        localStorage.setItem("eventId", eventId);
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        callBack && callBack();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.updateEventError(res.data.error.message));
      }
    } catch (e) {
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *deleteEvent(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Delete(`${EVENT}/${payload}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });

      if (res.status == 200) {
        info("success", "Event Deleted");

        // yield put(
        //   EventAction.getCrossCountryRequest(localStorage.getItem("eventId"))
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *updateEventCover(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb } = action;
    let formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    try {
      let res = yield ApiCaller.Put(
        `${EVENT}/${payload.eventId}/${COVER}`,
        formData,
        {
          Accept: "multipart/form-data",

          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );

      if (res.status == 200) {
        // info("success", "Cover Updated Successfully");

        // yield put(
        //   EventAction.getCrossCountryRequest(localStorage.getItem("eventId"))
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      }
    } catch (e) {
      yield put(EventAction.getDressageError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getEvent(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { params, cb } = action;
    const qureyParams = new URLSearchParams(params).toString();
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${EVENT}?${qureyParams}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getEventSuccess(res.data.data.events));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.getEventError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *getEventClassDetails(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    
    const { params, cb } = action;
    const qureyParams = new URLSearchParams(params).toString();
    const id = params;
    
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${EVENT_CLASS}/${DETAILS}/${id}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getEventClassDetailsSuccess(res.data.data));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.getEventClassDetailsError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }

  static *getMyEvent(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { params, cb } = action;
    const qureyParams = new URLSearchParams(params).toString();
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(
        `${EVENT}/${USER_EVENTS}?${qureyParams}`,
        {}
      );
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getMyEventSuccess(res.data.data.event));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.getMyEventError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getEventById(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { params, cb } = action;
    // const qureyParams = new URLSearchParams(params).toString();
    // let formData = new FormData();
    // for (const key in payload) {
    //   
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Get(`${EVENT}/${params}`, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(EventAction.getEventByIdSuccess(res.data.data.event));
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.getEventByIdError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *updateEventClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, eventClassId, callback } = action;
    try {
      let res = yield ApiCaller.Put(
        `${EVENT_CLASS}/${eventClassId}`,
        { ...payload },
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(
          EventAction.getEventClassReq({
            eventId: payload.eventId,
          })
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        callback && callback();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.updateEventClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *updateEventQuestion(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb, callback } = action;
    const questionId = payload.questionId;
    delete payload.questionId;
    try {
      let res = yield ApiCaller.Put(
        `${EVENT_QUESTION}/${questionId}`,
        { ...payload },
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(
          EventAction.getEventQuestionReq({
            eventClassId: cb,
          })
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        callback && callback();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.updateEventClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *updateDressage(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb, callback } = action;
    const dressageId = payload.dressageId;
    delete payload.dressageId;
    try {
      let res = yield ApiCaller.Put(
        `${DRESSAGE}/${dressageId}`,
        { ...payload },
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        yield put(
          EventAction.getDressageReq({
            eventId: cb.eventId,
          })
        );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        callback && callback();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.updateEventClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *updateCrossCountry(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb, callback } = action;
    const crossCountryId = payload.crossCountryId;
    delete payload.crossCountryId;
    let formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    try {
      let res = yield ApiCaller.Put(
        `${CROSS_COUNTRY}/${crossCountryId}`,
        formData,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        // yield put(
        //   EventAction.getDressageReq({
        //     eventId: cb,
        //   })
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        // callback && callback();
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.updateEventClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *updateCrossCountryFencs(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, crossCountryFencsId, cb } = action;
    // const crossCountryId = payload.crossCountryId;
    // delete payload.crossCountryId;
    // let formData = new FormData();
    // for (const key in payload) {
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Put(
        `${CROSS_COUNTRY_FENCES}/${crossCountryFencsId}`,
        payload,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        info("success", "Update Cross Counntry Sucessfully");
        window.location.reload();
        // yield put(
        //   EventAction.getDressageReq({
        //     eventId: cb,
        //   })
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        // callback && callback();
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(EventAction.updateEventClassError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *updateShowjumping(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, showJumpingId, callBack, clearImage } = action;

    // const crossCountryId = payload.crossCountryId;
    // delete payload.crossCountryId;
    let formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    try {
      let res = yield ApiCaller.Put(
        `${SHOWJUMPING}/${showJumpingId.showJumpingId}`,
        formData,
        {
          Accept: "multipart/form-data",

          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        info("success", "SHOW JUMPING UPDATE SUCCESSFULLY");
        // yield put(
        //   EventAction.getDressageReq({
        //     eventId: cb,
        //   })
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        callBack && callBack();
        // cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.updateShowJumpingError(res.data.error.message));
      }
    } catch (e) {
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *createShowJumpingFencs(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, cb, callback } = action;

    // const crossCountryId = payload.crossCountryId;
    // delete payload.crossCountryId;
    // let formData = new FormData();
    // for (const key in payload) {
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Post(`${SHOWJUMPING_FENCES}`, payload, {
        "Content-Type": "application/json",
        "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success",  res.data.data.message);
        // yield put(
        //   EventAction.getDressageReq({
        //     eventId: cb,
        //   })
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        callback && callback();
        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(
          EventAction.createShowJumpingFencsError(res.data.error.message)
        );
      }
    } catch (e) {
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *updateShowJumpingFencs(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { payload, callback, clearImage, showJumpingId } = action;

    // const crossCountryId = payload.crossCountryId;
    // delete payload.crossCountryId;
    // let formData = new FormData();
    // for (const key in payload) {
    //   formData.append(key, payload[key]);
    // }
    try {
      let res = yield ApiCaller.Put(
        `${SHOWJUMPING_FENCES}/${showJumpingId}`,
        payload,
        {
          "Content-Type": "application/json",
          "x-auth-token": ls.x_auth_token,
        }
      );
      if (res.status == 200) {
        info("success", "Update successfully");
        // yield put(
        //   EventAction.getDressageReq({
        //     eventId: cb,
        //   })
        // );
        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        callback && callback();
        clearImage && clearImage();
        // cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(
          EventAction.updateShowJumpingFencsError(res.data.error.message)
        );
      }
    } catch (e) {
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getCompletedEvents(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { cb } = action;
    try {
      let res = yield ApiCaller.Get(`${EVENT}/${GET_COMPLETE_EVENT}`, {
        "Content-Type": "application/json",
        // "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success", "Update successfully");
        yield put(EventAction.getCompleteEventSuccess(res.data.data.events));

        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.getCompleteEventError(res.data.error.message));
      }
    } catch (e) {}
  }
  static *getClassByEvent(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { cb, payload } = action;
    try {
      let res = yield ApiCaller.Get(`${EVENT_CLASS}/${payload}`, {
        "Content-Type": "application/json",
        // "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success", "Update successfully");
        yield put(EventAction.getClassByEventSuccess(res.data.data.eventClass));

        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.getClassByEventError(res.data.error.message));
      }
    } catch (e) {}
  }
  static *getLeaderboard(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { cb, payload } = action;
    try {
      let res = yield ApiCaller.Get(`${GET_LEADERBOARD}/${payload}`, {
        "Content-Type": "application/json",
        // "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success", "Update successfully");
        yield put(EventAction.getLeaderboardSuccess(res.data.data.leaderboard));

        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.getClassByEventError(res.data.error.message));
      }
    } catch (e) {}
  }
  static *deleteUserClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { cb, payload } = action;
    try {
      let res = yield ApiCaller.Delete(`${USER_CLASS}/${payload}`, {
        "Content-Type": "application/json",
        // "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success", "Update successfully");
        yield put(EventAction.getClassRequest());

        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.deleteUserClassError(res.data.error.message));
      }
    } catch (e) {}
  }
  static *updateUserClass(action) {
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const { cb, payload,id } = action;
    try {
      let res = yield ApiCaller.Put(`${USER_CLASS}/${id}`, payload,{
        "Content-Type": "application/json",
        // "x-auth-token": ls.x_auth_token,
      });
      if (res.status == 200) {
        // info("success", "Update successfully");
        yield put(EventAction.getClassRequest());

        cb && cb();
      } else {
        info("error", res.data.error.message);
        yield put(EventAction.deleteUserClassError(res.data.error.message));
      }
    } catch (e) {}
  }
}
