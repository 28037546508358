import React, { Component } from "react";
import { Row, Col, TimePicker } from "antd";
import { BiPlusCircle } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";

import Button from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";
import ImageUpload from "../../components/uploadimage";
import { Formik, Form } from "formik";
import { Metrix, Images, Fonts, Colors } from "../../config";
import { crossCountryValidations } from "../../config/schemas";
import { connect } from "react-redux";
import EventAction from "../../store/actions/EventAction";
import Select, { components } from "react-select";
import EventHeading from "../../components/eventsHeading";
import HorizontalLine from "../../components/eventsHeading/horizontalLine";
import AddBtnWithTitle from "../../components/addWithTittle";
import CrossCountryListing from "../../components/crossCountryListing";
import Modals from "../../components/modals/Basic";
import moment from "moment";
class crossCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverImage: "",
      uploadFile: "",
      judge: "",
      setClassId: "",
      errorForUploadImage: false,
      errorForUploadFile: false,
      errorForJudge: false,
      isSubmit: false,
      confirmed: false,
      fenceNum: false,
      fenceNumm: false,
      fieldArr: [],
      visible: false,
      crossCountryIndex: "",
      userClassSelect: {},
      imageUrl: "",
      timeValue: moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"),
      fileObj: {},
      judgeNameArr: [],
      fileDoc: {},
    };
  }
  uploadImage = (e) => {
    this.setState({
      coverImage: e.originFileObj,
      errorForUploadImage: false,
    });
  };
  onChangeUploadFile = (e) => {
    this.setState({
      uploadFile: e,
      errorForUploadFile: false,
    });
  };
  getJudge = (value) => {
    const { searchJudge } = this.props;
    searchJudge(value);
    this.setState({
      errorForJudge: false,
    });
  };
  componentDidMount() {
    //const { getEventClass } = this.props;
    //getEventClass({ eventId: 16 });
    const { getCrossCountry, getEventClass, location } = this.props;
    getCrossCountry(localStorage.getItem("eventId"));
    getEventClass({
      eventId: localStorage.getItem("eventId"),
    });
  }
  componentDidUpdate(preprops, preState) {
    if (preState.fileObj !== this.state.fileObj) {
      // const event = {
      //   target: {
      //     name: "eventClassId",
      //     value:
      //       this.state.file,
      //   }
      const event = {
        target: {
          name: "image",
          value: this.state.fileObj,
        },
      };
      this.formikHandleRef(event);
    }
    if (preState.fileDoc !== this.state.fileDoc) {
      // const event = {
      //   target: {
      //     name: "eventClassId",
      //     value:
      //       this.state.file,
      //   }
      const event = {
        target: {
          name: "document",
          value: this.state.fileDoc,
        },
      };
      this.formikHandleRef(event);
    }

    if (preprops.eventClass !== this.props.eventClass) {
      this.setState({
        setClassId: this.props.eventClass?.[0]?.UserClass?.id,
      });
      return;
    }
    if (preState.fenceNo !== this.state.fenceNo) {
      let arr = [];
      for (let i = 1; i <= this.state.fenceNo; i++) {
        if (this.props?.location?.state?.isUpdate) {
          arr.push({
            crossCountryFenceId: this.props?.location?.state?.isUpdate
              ? this.props?.crossCountry?.[this.state.crossCountryIndex]
                  ?.CrossCountryFences[i - 1]?.id
              : "",
            userId: this.props?.location?.state?.isUpdate
              ? this.props?.crossCountry?.[this.state.crossCountryIndex]
                  ?.CrossCountryFences[i - 1]?.FenceJudge?.id
              : "",
            fenceNum: this.props?.location?.state?.isUpdate
              ? this.props?.crossCountry?.[this.state.crossCountryIndex]
                  ?.CrossCountryFences[i - 1]?.fenceNum
              : "",
          });
        } else {
          arr.push({
            // crossCountryFenceId: this.props?.location?.state?.isUpdate
            //   ? this.props?.crossCountry?.[this.state.crossCountryIndex]
            //       ?.CrossCountryFences[i - 1]?.id
            //   : "",
            userId: this.props?.location?.state?.isUpdate
              ? this.props?.crossCountry?.[this.state.crossCountryIndex]
                  ?.CrossCountryFences[i - 1]?.FenceJudge?.id
              : "",
            fenceNum: this.props?.location?.state?.isUpdate
              ? this.props?.crossCountry?.[this.state.crossCountryIndex]
                  ?.CrossCountryFences[i - 1]?.fenceNum
              : "",
          });
        }
      }
      let judgeName = [];
      for (let i = 1; i <= this.state.fenceNo; i++) {
        judgeName.push({
          label: this.props?.location?.state?.isUpdate
            ? this.props?.crossCountry?.[this.state.crossCountryIndex]
                ?.CrossCountryFences[i - 1]?.FenceJudge?.firstName
            : "",
          value: this.props?.location?.state?.isUpdate
            ? this.props?.crossCountry?.[this.state.crossCountryIndex]
                ?.CrossCountryFences[i - 1]?.FenceJudge?.id
            : "",
        });
      }
      this.setState({
        fieldArr: arr,
        judgeNameArr: judgeName,
      });
    }

    if (preState.crossCountryIndex !== this.state.crossCountryIndex) {
      this.createFile();
      this.createDoc();
      const event = {
        target: {
          name: "eventClassId",
          value:
            this.props.crossCountry?.[this.state.crossCountryIndex]
              ?.CrossCountryClass?.id,
        },
      };
      const e = {
        target: {
          name: "courseLength",
          value:
            this.props.crossCountry?.[this.state.crossCountryIndex]
              ?.course_length,
        },
      };

      const elem = {
        target: {
          name: "nofences",
          value:
            this.props.crossCountry?.[this.state.crossCountryIndex]
              ?.CrossCountryFences?.length,
        },
      };
      const timeEle = {
        target: {
          name: "optimumTime",
          value:
            this.props.crossCountry?.[this.state.crossCountryIndex]
              ?.optimum_time,
        },
      };
      let d = new Date(); // Creates a Date Object using the clients current time

      if (this.state.crossCountryIndex?.toString()) {
        let [hours, minutes, seconds] =
          this.props.crossCountry?.[
            this.state.crossCountryIndex
          ]?.optimum_time?.split(":"); // Using ES6 destructuring
        // "Old" ES5 version

        d.setHours(+hours); // Set the hours, using implicit type coercion
        d.setMinutes(minutes); // You can pass Number or String. It doesn't really matter
        d.setSeconds(seconds);
      }

      // If needed, adjust date and time zone

      // 
      this.setState({
        timeValue: moment(d).format("YYYY-MM-DD  HH:mm:ss"),

        userClassSelect: {
          label:
            this.props.crossCountry?.[this.state.crossCountryIndex]
              ?.CrossCountryClass?.UserClass?.name,
          value:
            this.props.crossCountry?.[this.state.crossCountryIndex]
              ?.CrossCountryClass?.id,
        },

        imageUrl:
          this.props.crossCountry?.[this.state.crossCountryIndex]?.image_url,
      });
      this.formikHandleRef(event);
      this.formikHandleRef(e);
      this.formikHandleRef(elem);
      this.formikHandleRef(timeEle);
    }
  }
  createFile = async () => {
    let response = await fetch(
      this.props?.crossCountry?.[this.state.crossCountryIndex]?.image_url
    );
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "test.jpg", metadata);
    // ... do something with the file or return it
    this.setState({
      fileObj: file,
    });
  };
  getFenceFields = (fenceNumber) => {
    //const marginTop = { marginTop: "1rem" };
    let arr = [];
    for (let i = 1; i <= fenceNumber; i++) {
      arr.push({
        crossCountryId: "",
        userId: "",
        fenceNum: "",
      });
    }
    return arr;
  };
  onHandleChanged = (e, index, name, judgArr) => {
    if (judgArr) {
      const filterJudge = judgArr.find((item) => {
        return item.value === e.target.value;
      });
      const newJudgeArr = [...this.state.judgeNameArr];
      newJudgeArr[index] = {
        ...filterJudge,
      };
      this.setState({ judgeNameArr: newJudgeArr });
    }
    const { fieldArr, judgeNameArr } = this.state;
    const newArr = [...this.state.fieldArr];
    if (this.state.crossCountryIndex?.toString()) {
      newArr[index] = {
        ...newArr[index],
        [name]: e.target.value,
      };
      this.setState({
        ...fieldArr,
        fieldArr: newArr,
      });
    } else {
      newArr[index] = {
        ...newArr[index],
        [name]: e.target.value,
        crossCountryId: +localStorage.getItem("countryId"),
      };
      this.setState({
        ...fieldArr,
        fieldArr: newArr,
      });
    }
  };
  isOpenModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  onSubmitModal = () => {
    this.props.confirmEvent(
      localStorage.getItem("eventId"),
      () => this.props.history.push("/my-event"),
      { status: 1 }
    );
  };
  crossCountryEdit = (index) => {
    this.setState({
      crossCountryIndex: index,
    });
  };
  createDoc = async () => {
    if (this.props?.crossCountry?.[this.state.crossCountryIndex]?.file_url) {
      let response = await fetch(
        this.props?.crossCountry?.[this.state.crossCountryIndex]?.file_url
      );
      let data = await response.blob();
      let metadata = {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      };
      let file = new File([data], "document.docx", metadata);
      // ... do something with the file or return it
      this.setState({
        fileDoc: file,
      });
    }
  };
  render() {
    const judgeOptions = (this.props.judge || []).map((item) => {
      return {
        value: item.id,
        label: `${item.firstName} 
      (${item.email})
    `,
      };
    });
    

    const customStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma('lightblue');
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "lightblue"
            : isFocused
            ? "#eaeaea"
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? "#eaeaea"
              ? "black"
              : "black"
            : "black",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? "lightblue" : "#eaeaea"),
          },
        };
      },
      input: (styles) => ({ ...styles }),
      placeholder: (styles) => ({ ...styles }),
      singleValue: (styles, { data }) => ({ ...styles }),
    };
    const marginTop = { marginTop: "1rem" };
    const { createCrossCountry, crossCountry, isLoading, updateCrossCountry } =
      this.props;
    
    const eventClass = this.props.eventClass;
    var uniqueResultOne = eventClass.filter(function (obj) {
      return !(crossCountry || []).some(function (obj2) {
        return obj.id == obj2.eventClassId;
      });
    });
    const classoptions = (uniqueResultOne || []).map((item) => {
      return { value: item.id, label: item?.UserClass?.name };
    });
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <AiOutlineSearch />
          </components.DropdownIndicator>
        )
      );
    };
    const isUpdate = this.props?.location?.state?.isUpdate;
    return (
      <>
        <Row>
          <Col span={24} sm={24} md={24} xs={24} lg={24}>
            {!this.state.isSubmit ? (
              <EventHeading
                arrowBtn={true}
                goBack={() =>
                  this.props.history.push({
                    pathname: isUpdate
                      ? "/update/showjumping"
                      : "/create-event/showjumping",
                    state: {
                      isUpdate,
                      eventDetail: this?.props?.location?.state?.eventDetail,
                    },
                  })
                }
                title={isUpdate ? "Update Cross Country" : "Cross Country"}
              />
            ) : (
              <EventHeading
                arrowBtn={true}
                goBack={() => {
                  this.setState({
                    isSubmit: false,
                  });
                }}
                title={isUpdate ? "Update Cross Country" : "Cross Country"}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <HorizontalLine width="85%" />
          </Col>
        </Row>
        <Modals
          title=""
          lable="Confirm Event"
          visible={this.state.visible}
          closable={true}
          maskClosable={true}
          onSubmitModal={this.onSubmitModal}
          onCancelModal={this.isOpenModal}
        />
        <Formik
          initialValues={{
            eventClassId: "",
            image: "",
            optimumTime: this.state.timeValue,
            courseLength: "",
            nofences: "",
            document: "",
          }}
          validationSchema={crossCountryValidations}
          onSubmit={(values, { resetForm }) => {
            let noFences = values.nofences;
            delete values.nofences;
            // let newValues = {
            //   ...values,
            //   eventDate: ,
            //   coverImage: coverImage,
            // };

            !this.state.crossCountryIndex.toString() &&
              !this.state.isSubmit &&
              createCrossCountry(values, () =>
                this.setState({
                  isSubmit: noFences > 0 ? true : false,
                  fenceNo: noFences,
                  confirmed: noFences > 0 ? false : true,
                })
              );
            this.state.crossCountryIndex.toString() &&
              updateCrossCountry(
                {
                  ...values,
                  crossCountryId:
                    this.props.crossCountry?.[this.state.crossCountryIndex]?.id,
                },
                () =>
                  this.setState({
                    isSubmit: noFences > 0 ? true : false,
                    fenceNo: noFences,
                    confirmed: noFences > 0 ? false : true,
                  })
              );

            // resetForm({ values: "" });
          }}
          render={({ values, touched, handleChange, errors }) => {
            this.formikHandleRef = handleChange;
            return (
              <Form style={{ width: "85%", margin: "auto" }}>
                <Row justify="space-between" gutter={[40, 20]}>
                  {this.state.isSubmit == false ? (
                    <>
                      <Col lg={5} sm={12} xs={24} md={12}>
                        <label className="label-alignlf">Class Name</label>
                        <Select
                          className="userSelect"
                          placeholder="Class Name"
                          name="eventClassId"
                          value={this.state.userClassSelect}
                          onChange={(e) => {
                            this.setState({
                              userClassSelect: e,
                            });
                            let event = {
                              target: {
                                name: "eventClassId",
                                value: e.value,
                              },
                            };
                            handleChange(event);
                          }}
                          options={classoptions}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.eventClassId &&
                            touched.eventClassId &&
                            errors.eventClassId}
                        </label>
                      </Col>
                      <Col lg={5} sm={12} xs={24} md={12}>
                        <p className="label-alignlf">Optimum Time</p>
                        <TimePicker
                          type="time"
                          placeholder="Optimum Time"
                          className="time-picker"
                          name="optimumTime"
                          allowClear={false}
                          value={moment(this.state.timeValue)}
                          showNow={false}
                          onChange={(e, timeString) => {
                            
                            this.setState({
                              timeValue: moment(e).format(
                                "YYYY-MM-DD  HH:mm:ss"
                              ),
                            });
                            let event = {
                              target: {
                                name: "optimumTime",
                                value: timeString,
                              },
                            };
                            handleChange(event);
                          }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.optimumTime &&
                            touched.optimumTime &&
                            errors.optimumTime}
                        </label>
                      </Col>{" "}
                      <Col lg={5} sm={12} xs={24} md={12}>
                        <label className="label-alignlf">Course Length</label>
                        <DefaultInput
                          placeholder="Course Length"
                          className="courseLength"
                          value={values.courseLength}
                          style={marginTop}
                          name="courseLength"
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.courseLength &&
                            touched.courseLength &&
                            errors.courseLength}
                        </label>
                      </Col>
                      <Col lg={5} sm={12} xs={24} md={12}>
                        <label className="label-alignlf">No. Of Fences</label>
                        <DefaultInput
                          placeholder="No. Of Fences"
                          className="fences"
                          style={marginTop}
                          value={values.nofences}
                          name="nofences"
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.nofences &&
                            touched.nofences &&
                            errors.nofences}
                        </label>
                      </Col>
                      <Col lg={4} sm={24} xs={24} md={12}>
                        <AddBtnWithTitle
                          style={{ marginTop: "37px" }}
                          loading={isLoading}
                          title="Add"
                          icon={() => <BiPlusCircle />}
                        />
                      </Col>
                    </>
                  ) : this.state.fenceNo > 0 ? (
                    <>
                      {this.state.fieldArr.map((x, index) => {
                        return (
                          <>
                            <Col lg={10} sm={12} xs={24} md={12}>
                              <label className="label-alignlf">
                                Fence No {index + 1}
                              </label>
                              <DefaultInput
                                className="userSelect"
                                value={this.state.fieldArr?.[index]?.fenceNum}
                                name="fenceNum"
                                onChange={(e) =>
                                  this.onHandleChanged(e, index, "fenceNum")
                                }
                              />
                              <label style={{ color: Colors.Red }}>
                                {errors.fenceNum &&
                                  touched.fenceNum &&
                                  errors.fenceNum}
                              </label>
                              {/* <Select className="userSelect" placeholder="Class Name" /> */}
                            </Col>
                            <Col lg={10} sm={12} xs={24} md={12}>
                              <label className="label-alignlf">
                                Judge Name
                              </label>
                              <Select
                                placeholder="Judge Name"
                                className="userSelect"
                                options={judgeOptions}
                                value={this.state.judgeNameArr[index]}
                                components={{ DropdownIndicator }}
                                onChange={(e) => {
                                  let event = {
                                    target: { name: "userId", value: e.value },
                                  };
                                  this.onHandleChanged(
                                    event,
                                    index,
                                    "userId",
                                    judgeOptions
                                  );
                                }}
                                onKeyDown={(e) => this.getJudge(e.target.value)}
                              />
                            </Col>{" "}
                            <label style={{ color: Colors.Red }}>
                              {errors.userId && touched.userId && errors.userId}
                            </label>
                          </>
                        );
                      })}{" "}
                    </>
                  ) : null}
                  {this.state.isSubmit && (
                    <Col lg={6} sm={24} xs={24} md={24}>
                      <AddBtnWithTitle
                        style={{ marginTop: "37px" }}
                        title="Save"
                        onClick={() =>
                          this.state.isSubmit &&
                          !this.state.crossCountryIndex?.toString()
                            ? this.props.createCrossCountryFence(
                                this.state.fieldArr,
                                () => this.setState({ isSubmit: false })
                              )
                            : this.props.updateCrossCountryFencs(
                                this.state.fieldArr,
                                this.props?.crossCountry?.[
                                  this.state.crossCountryIndex
                                ]?.id,
                                () =>
                                  this.setState({
                                    isSubmit: false,
                                    crossCountryIndex: "",
                                  })
                              )
                        }
                        icon={() => <BiPlusCircle />}
                        Loading={isLoading}
                        htmlType="submit"
                      />
                    </Col>
                  )}
                  <Col lg={11} md={20} xl={12}>
                    <Row justify="start" gutter={[10, 20]}>
                      {this.state.isSubmit == false ? (
                        <>
                          <Col lg={12} xl={10} md={10} xs={24}>
                            <ImageUpload
                              width="100%"
                              btnText="Upload Map Image"
                              name="image"
                              style={marginTop}
                              initialPreview={this.state.imageUrl}
                              onChange={(e) => {
                                let event = {
                                  target: {
                                    name: "image",
                                    value: e.originFileObj,
                                  },
                                };
                                handleChange(event);
                              }}
                              type="image"
                            />
                            <label style={{ color: Colors.Red }}>
                              (size 300 x 600) Max 10Mb
                            </label>
                            <p style={{ color: Colors.Red }}>
                              {errors.image && touched.image && errors.image}
                            </p>
                          </Col>
                          <Col lg={12} xl={10} md={10} xs={24}>
                            <ImageUpload
                              width="100%"
                              btnText="Upload Description File"
                              name="document"
                              type="document"
                              value={values.document}
                              style={marginTop}
                              onChange={(e) => {
                                
                                let event = {
                                  target: {
                                    name: "document",
                                    value: e,
                                  },
                                };
                                handleChange(event);
                              }}
                            />
                            <label style={{ color: Colors.Red }}>
                              Pdf / word Max 50Mb
                            </label>
                            <p>{values?.document?.name}</p>
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
              </Form>
            );
          }}
        />
        {!this.state.isSubmit && (
          <Row style={{ width: "85%", margin: "auto" }}>
            <Col span={24}>
              <CrossCountryListing
                deleteItem={this.props.deleteCrossCountry}
                item={crossCountry}
                isUpdate={isUpdate}
                crossCountryEdit={this.crossCountryEdit}
              />
            </Col>
          </Row>
        )}
        {this.state.isSubmit == false ? (
          <Row justify="center" style={{ marginBottom: "50px" }}>
            <Col span={4} sm={18} xs={18} md={4}>
              <Button
                disabled={
                  this.props.crossCountry?.length !==
                  this.props.eventClass?.length
                }
                onClick={this.isOpenModal}
              >
                Continue
              </Button>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (storeState) => {
  return {
    crossCountry: storeState?.EventReducer.crossCountry,
    crossCountryId: storeState?.EventReducer.crossCountryId,
    eventClass: storeState?.EventReducer.eventClass,
    judge: storeState?.EventReducer.judge,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createCrossCountry: (payload, cb) =>
      dispatch(EventAction.createCrossCountryReq(payload, cb)),
    createCrossCountryFence: (payload, cb) =>
      dispatch(EventAction.createCrossCountryFenceReq(payload, cb)),
    searchJudge: (payload) => {
      dispatch(EventAction.searchJudgeRequest(payload));
    },
    getCrossCountry: (eventId) => {
      dispatch(EventAction.getCrossCountryRequest(eventId));
    },
    getEventClass: (eventId) => {
      dispatch(EventAction.getEventClassReq(eventId));
    },
    searchJudge: (payload) => {
      dispatch(EventAction.searchJudgeRequest(payload));
    },
    deleteCrossCountry: (countryId) =>
      dispatch(EventAction.deletCrossCountryReq(countryId)),
    confirmEvent: (eventId, cb, status) =>
      dispatch(EventAction.confirmEventReq(eventId, cb, status)),
    updateCrossCountry: (payload, cb) =>
      dispatch(EventAction.updateCrossCountryReq(payload, cb)),
    updateCrossCountryFencs: (payload, crossCountryId, cb) =>
      dispatch(
        EventAction.updateCrossCountryFencsReq(payload, crossCountryId, cb)
      ),
  };
};
export default connect(
  (storeState) => ({
    tests: storeState?.EventReducer?.tests,
    eventClass: storeState?.EventReducer?.eventClass,
    judge: storeState?.EventReducer.judge,
    crossCountry: storeState?.EventReducer.crossCountry.res,
    isLoading: storeState?.EventReducer.isLoading,
  }),
  (mapStateToProps, mapDispatchToProps)
)(crossCountry);
