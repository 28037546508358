import { Modal, Button } from "antd";
import React, { Component } from "react";
import { Row, Col } from "antd";
import ButtonForm from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";

export default class CustomModal extends React.Component {
  state = {
    loading: false,
    visible: false,
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };
  handleCancel = () => {
    let { visible } = this.props;
    //visible = !visible;
    this.setState({ loading: false, visible: false });
  };
  render() {
    const { visible, title, lable, onChange, onSubmitModal, onCancelModal } =
      this.props;
    return (
      <>
        <Modal
          visible={visible}
          title={title}
          lable={lable}
          onOk={this.handleOk}
          onCancel={onCancelModal}
          closeable={true}
          maskClosable={true}
          footer={[]}
        >
            {this.props.children}
          {/* <Row justify="center" gutter={[0, 20]}>
            <Col span={24}>
              <h1 style={{ textAlign: "center" }}>{lable}</h1>
            </Col>
            <Col span={24} >
            <p style={{ textAlign: "center" }}>Are You sure You want to create this event?</p>
            </Col>
            <Col span={8} style={{ marginBottom: "10px" }}>
              <ButtonForm onClick={() => onSubmitModal()}>Yes</ButtonForm>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <ButtonForm color='#C5C3C3' onClick={() => onCancelModal()}>No</ButtonForm>
            </Col>
          </Row> */}
        </Modal>
      </>
    );
  }
}
