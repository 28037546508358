import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import DefaultInput from "../../components/input/Input";
import BackButton from "../../components/button/BackButton";
import { BiArrowBack } from "react-icons/bi";
import "./Styles.css";
import DefaultButton from "../../components/button/Button";
import { Button } from "antd";
import background from "../../assets/images/background.png";
import FPContainer from "../../components/containers/FPContainer";
import Image from "../../components/image";
import { Input } from "antd";
import { Row, Col } from "antd";
import { connect } from "react-redux";
//import { withRouter } from "react-router";
import { Colors } from "../../config";
import { AuthMiddleware } from "../../store/middlewares";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      validEmail: true,
    };
  }

  // send = () => {
  //     const { email, validEmail } = this.state;
  //     const { sendMail } = this.props;
  //     if (!email || !validEmail)
  //         Toast.show({ textStyle: { textAlign: "center", fontFamily: Fonts.Montserrat_Medium }, text: "Please enter valid email address.", type: "danger", duration: 3000 })
  //     else
  //         sendMail({ email })
  // }

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    let validEmail = re.test(String(email).toLowerCase());

    this.setState({ email, validEmail });
  };

  render() {
    const { email, validEmail } = this.state;
    return (
      <FPContainer>
        <Row justify="center" gutter={[0, 40]} style={{ width: "35%" }}>
          <Col span={12} align="middle">
            <Image src={logo} />
          </Col>
          <Col span={16} align="middle">
            <Row justify="center" gutter={[0, 40]} align="middle">
              <Col span={2}>
                <Link to="/signUp" style={{ color: "#707070" }}>
                  <BackButton icon={<BiArrowBack color="white" size={20} />} />
                </Link>
              </Col>
              <Col span={22}>
                <h1 style={{ color: Colors.White }}>Privacy Statement</h1>
              </Col>
            </Row>
          </Col>
          <Col
            style={{
              color: Colors.White,
              overflow: "scroll",
              overflowX: "hidden",
              height: "15rem",
              textAlign: "justify",
            }}
          >
            <p>
              Your privacy is important to us. It is Event Pluz’s policy to
              respect your privacy and comply with any applicable law and
              regulation regarding any personal information we may collect about
              you, including across our website, https://www.eventpluz.com, and
              other sites we own and operate. This policy is effective as of 14
              July 2021 and was last updated on 14 July 2021. Information We
              Collect Information we collect includes both information you
              knowingly and actively provide us when using or participating in
              any of our services and promotions, and any information
              automatically sent by your devices in the course of accessing our
              products and services. Log Data When you visit our website, our
              servers may automatically log the standard data provided by your
              web browser. It may include your device’s Internet Protocol (IP)
              address, your browser type and version, the pages you visit, the
              time and date of your visit, the time spent on each page, other
              details about your visit, and technical details that occur in
              conjunction with any errors you may encounter. Please be aware
              that while this information may not be personally identifying by
              itself, it may be possible to combine it with other data to
              personally identify individual persons. Personal Information We
              may ask for personal information which may include one or more of
              the following: Name Email Date of birth Phone/mobile number
              Legitimate Reasons for Processing Your Personal Information We
              only collect and use your personal information when we have a
              legitimate reason for doing so. In which instance, we only collect
              personal information that is reasonably necessary to provide our
              services to you. Collection and Use of Information We may collect
              personal information from you when you do any of the following on
              our website: Use a mobile device or web browser to access our
              content Contact us via email, social media, or on any similar
              technologies When you mention us on social media We may collect,
              hold, use, and disclose information for the following purposes,
              and personal information will not be further processed in a manner
              that is incompatible with these purposes: We may collect, hold,
              use, and disclose information for the following purposes, and
              personal information will not be further processed in a manner
              that is incompatible with these purposes: to contact and
              communicate with you for analytics, market research, and business
              development, including to operate and improve our website,
              associated applications, and associated social media platforms for
              advertising and marketing, including to send you promotional
              information about our products and services and information about
              third parties that we consider may be of interest to you to enable
              you to access and use our website, associated applications, and
              associated social media platforms for internal record keeping and
              administrative purposes to comply with our legal obligations and
              resolve any disputes that we may have for security and fraud
              prevention, and to ensure that our sites and apps are safe,
              secure, and used in line with our terms of use Please be aware
              that we may combine information we collect about you with general
              information or research data we receive from other trusted
              sources. Security of Your Personal Information When we collect and
              process personal information, and while we retain this
              information, we will protect it within commercially acceptable
              means to prevent loss and theft, as well as unauthorized access,
              disclosure, copying, use, or modification. Although we will do our
              best to protect the personal information you provide to us, we
              advise that no method of electronic transmission or storage is
              100% secure, and no one can guarantee absolute data security. We
              will comply with laws applicable to us in respect of any data
              breach. You are responsible for selecting any password and its
              overall security strength, ensuring the security of your own
              information within the bounds of our services. How Long We Keep
              Your Personal Information We keep your personal information only
              for as long as we need to. This time period may depend on what we
              are using your information for, in accordance with this privacy
              policy. If your personal information is no longer required, we
              will delete it or make it anonymous by removing all details that
              identify you. However, if necessary, we may retain your personal
              information for our compliance with a legal, accounting, or
              reporting obligation or for archiving purposes in the public
              interest, scientific, or historical research purposes or
              statistical purposes. Children’s Privacy We do not aim any of our
              products or services directly at children under the age of 13, and
              we do not knowingly collect personal information about children
              under 13. Disclosure of Personal Information to Third Parties We
              may disclose personal information to: a parent, subsidiary, or
              affiliate of our company third party service providers for the
              purpose of enabling them to provide their services, for example,
              IT service providers, data storage, hosting and server providers,
              advertisers, or analytics platforms our employees, contractors,
              and/or related entities our existing or potential agents or
              business partners sponsors or promoters of any competition,
              sweepstakes, or promotion we run courts, tribunals, regulatory
              authorities, and law enforcement officers, as required by law, in
              connection with any actual or prospective legal proceedings, or in
              order to establish, exercise, or defend our legal rights third
              parties, including agents or sub-contractors, who assist us in
              providing information, products, services, or direct marketing to
              you third parties to collect and process data International
              Transfers of Personal Information The personal information we
              collect is stored and/or processed where we or our partners,
              affiliates, and third-party providers maintain facilities. Please
              be aware that the locations to which we store, process, or
              transfer your personal information may not have the same data
              protection laws as the country in which you initially provided the
              information. If we transfer your personal information to third
              parties in other countries: (i) we will perform those transfers in
              accordance with the requirements of applicable law; and (ii) we
              will protect the transferred personal information in accordance
              with this privacy policy. Your Rights and Controlling Your
              Personal Information You always retain the right to withhold
              personal information from us, with the understanding that your
              experience of our website may be affected. We will not
              discriminate against you for exercising any of your rights over
              your personal information. If you do provide us with personal
              information you understand that we will collect, hold, use and
              disclose it in accordance with this privacy policy. You retain the
              right to request details of any personal information we hold about
              you. If we receive personal information about you from a third
              party, we will protect it as set out in this privacy policy. If
              you are a third party providing personal information about
              somebody else, you represent and warrant that you have such
              person’s consent to provide the personal information to us. If you
              have previously agreed to us using your personal information for
              direct marketing purposes, you may change your mind at any time.
              We will provide you with the ability to unsubscribe from our
              email-database or opt out of communications. Please be aware we
              may need to request specific information from you to help us
              confirm your identity. If you believe that any information we hold
              about you is inaccurate, out of date, incomplete, irrelevant, or
              misleading, please contact us using the details provided in this
              privacy policy. We will take reasonable steps to correct any
              information found to be inaccurate, incomplete, misleading, or out
              of date. If you believe that we have breached a relevant data
              protection law and wish to make a complaint, please contact us
              using the details below and provide us with full details of the
              alleged breach. We will promptly investigate your complaint and
              respond to you, in writing, setting out the outcome of our
              investigation and the steps we will take to deal with your
              complaint. You also have the right to contact a regulatory body or
              data protection authority in relation to your complaint. Limits of
              Our Policy Our website may link to external sites that are not
              operated by us. Please be aware that we have no control over the
              content and policies of those sites, and cannot accept
              responsibility or liability for their respective privacy
              practices. Changes to This Policy At our discretion, we may change
              our privacy policy to reflect updates to our business processes,
              current acceptable practices, or legislative or regulatory
              changes. If we decide to change this privacy policy, we will post
              the changes here at the same link by which you are accessing this
              privacy policy. If required by law, we will get your permission or
              give you the opportunity to opt in to or opt out of, as
              applicable, any new uses of your personal information. Contact Us
              For any questions or concerns regarding your privacy, you may
              contact us using the following details: Heather Jones
              heatherjones@eventpluz.com
            </p>
          </Col>
          <Col span={12}>
            <DefaultButton
              onClick={(e) => {
                //e.preventDefault();
                this.props.history.push("/verify-code");
              }}
            >
              Continue
            </DefaultButton>
          </Col>
        </Row>
      </FPContainer>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    sendMail: (payload) => {
      dispatch(AuthMiddleware.RequestPasswordReset(payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
