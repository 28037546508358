import {
  AuthMiddleware,
  EventMiddleware,
  userMiddleWarre,
} from "../middlewares";
import { takeLatest, all } from "redux-saga/effects";
import {
  SIGNIN,
  SIGNUP,
  SIGNOUT,
  VERIFICATION_CODE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CREATE_EVENT_REQ,
  CREATE_USER_CLASS_REQ,
  CREATE_EVENT_QUESTION_REQ,
  SOCIAL_EXTRA_DETAILS_REQ,
  SOCIAL_SIGN_IN_REQ,
  CREATE_EVENT_CLASS_REQ,
  CREATE_SHOW_JUMPING_REQ,
  CREATE_DRESSAGE_REQ,
  CREATE_CROSS_COUNTRY_REQ,
  CREATE_CROSS_COUNTRY_FENCE_REQ,
  GET_CLASS_REQ,
  GET_TEST_REQ,
  GET_CROSS_COUNTRY_REQ,
  GET_EVENT_QUESTION_REQ,
  SEARCH_JUDGE_REQ,
  GET_EVENT_CLASS_REQ,
  DELETE_DRESSAGE_REQ,
  DELETE_EVENT_CLASS_REQ,
  DELETE_EVENT_QUESTION_REQ,
  GET_DRESSAGE_REQUEST,
  GET_SHOW_JUMPING_REQ,
  DELETE_SHOW_JUMPING_REQ,
  DELETE_CROSS_COUNTRY_REQ,
  CONFIRM_EVENT_REQ,
  UPDATE_EVENT_REQ,
  DELETE_EVENT_REQ,
  UPDATE_EVENT_COVER_IMAGE_REQ,
  GET_EVENT_REQ,
  GET_MY_EVENT_REQ,
  GET_EVENT_BY_ID_REQ,
  GET_EVENT_CLASS_DETAILS_REQ,
  UPDATE_EVENT_CLASS_REQ,
  UPDATE_EVENT_QUESTION_REQ,
  UPDATE_DRESSAGE_REQ,
  UPDATE_CROSS_COUNTRY_REQ,
  UPDATE_CROSS_COUNTRY_FENCES_REQ,
  UPDATE_SHOW_JUMPING_REQ,
  CREATE_SHOW_JUMPING_FENCS_REQ,
  UPDATE_SHOW_JUMPING_FENCES_REQ,
  UPDATE_PROFILE_REQ,
  GET_COMPLETE_EVENT_REQ,
  GET_CLASS_BY_EVENT_REQ,
  GET_LEADERBOARD_REQ,
  GET_NOTIFICATION_REQ,
  DELETE_USER_CLASS_REQ,
  UPDATE_USER_CLASS_REQ,
  DELETE_ACCOUNT_REQ,
  CREATE_SUBSCRIPTION_REQ,
  CREATE_LINK_REQ,
  CREATE_ACCOUNT_REQ,
  CHECK_ACCOUNT_REQ,
  CHANGE_PASSWORD_REQ,
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLANE_REQ
} from "../constants";
import { AuthAction, EventAction, UserAction } from "../actions";

export function* Sagas() {
  yield all([
    yield takeLatest(SIGNIN, AuthMiddleware.SignIn),
    yield takeLatest(SIGNUP, AuthMiddleware.SignUp),
    yield takeLatest(SIGNOUT, AuthMiddleware.SignOut),
    yield takeLatest(VERIFICATION_CODE, AuthMiddleware.VerifyCode),
    yield takeLatest(FORGOT_PASSWORD, AuthMiddleware.ForgotPassword),
    yield takeLatest(RESET_PASSWORD, AuthMiddleware.ResetPassword),
    yield takeLatest(CREATE_EVENT_REQ, EventMiddleware.createEvent),
    yield takeLatest(CREATE_USER_CLASS_REQ, EventMiddleware.createUserClass),
    yield takeLatest(
      CREATE_EVENT_QUESTION_REQ,
      EventMiddleware.createEventQuestion
    ),
    yield takeLatest(CREATE_EVENT_CLASS_REQ, EventMiddleware.createEventClass),
    yield takeLatest(
      CREATE_SHOW_JUMPING_REQ,
      EventMiddleware.createShowJumping
    ),
    yield takeLatest(CREATE_DRESSAGE_REQ, EventMiddleware.createDressage),
    yield takeLatest(
      CREATE_CROSS_COUNTRY_REQ,
      EventMiddleware.createCrossCountry
    ),
    yield takeLatest(
      CREATE_CROSS_COUNTRY_FENCE_REQ,
      EventMiddleware.createCrossCountryFence
    ),
    yield takeLatest(GET_CLASS_REQ, EventMiddleware.getClass),
    yield takeLatest(GET_TEST_REQ, EventMiddleware.getTest),
    yield takeLatest(SEARCH_JUDGE_REQ, EventMiddleware.searchJudge),
    yield takeLatest(GET_EVENT_CLASS_REQ, EventMiddleware.getEventClass),
    yield takeLatest(GET_EVENT_QUESTION_REQ, EventMiddleware.getEventQuestion),
    yield takeLatest(DELETE_DRESSAGE_REQ, EventMiddleware.deleteDressage),
    yield takeLatest(DELETE_EVENT_CLASS_REQ, EventMiddleware.deleteEventClass),
    yield takeLatest(
      DELETE_EVENT_QUESTION_REQ,
      EventMiddleware.deleteEventQuestion
    ),
    yield takeLatest(GET_DRESSAGE_REQUEST, EventMiddleware.getDressageList),
    yield takeLatest(GET_SHOW_JUMPING_REQ, EventMiddleware.getShowJumpingList),
    yield takeLatest(DELETE_SHOW_JUMPING_REQ, EventMiddleware.deletShowJumping),
    yield takeLatest(GET_CROSS_COUNTRY_REQ, EventMiddleware.getCrossCountry),
    yield takeLatest(
      DELETE_CROSS_COUNTRY_REQ,
      EventMiddleware.deleteCrossCountry
    ),
    yield takeLatest(CONFIRM_EVENT_REQ, EventMiddleware.confirmEvent),
    yield takeLatest(SOCIAL_SIGN_IN_REQ, AuthMiddleware.SocialSignIn),
    yield takeLatest(
      SOCIAL_EXTRA_DETAILS_REQ,
      AuthMiddleware.SocialExtraDetails
    ),
    yield takeLatest(UPDATE_EVENT_REQ, EventMiddleware.updateEvent),
    yield takeLatest(DELETE_EVENT_REQ, EventMiddleware.deleteEvent),
    yield takeLatest(
      UPDATE_EVENT_COVER_IMAGE_REQ,
      EventMiddleware.updateEventCover
    ),
    yield takeLatest(GET_EVENT_REQ, EventMiddleware.getEvent),
    yield takeLatest(GET_MY_EVENT_REQ, EventMiddleware.getMyEvent),
    yield takeLatest(GET_EVENT_BY_ID_REQ, EventMiddleware.getEventById),
    yield takeLatest(
      GET_EVENT_CLASS_DETAILS_REQ,
      EventMiddleware.getEventClassDetails
    ),
    yield takeLatest(UPDATE_EVENT_CLASS_REQ, EventMiddleware.updateEventClass),
    yield takeLatest(
      UPDATE_EVENT_QUESTION_REQ,
      EventMiddleware.updateEventQuestion
    ),
    yield takeLatest(UPDATE_DRESSAGE_REQ, EventMiddleware.updateDressage),
    yield takeLatest(
      UPDATE_CROSS_COUNTRY_REQ,
      EventMiddleware.updateCrossCountry
    ),
    yield takeLatest(
      UPDATE_CROSS_COUNTRY_FENCES_REQ,
      EventMiddleware.updateCrossCountryFencs
    ),
    yield takeLatest(
      UPDATE_SHOW_JUMPING_REQ,
      EventMiddleware.updateShowjumping
    ),
    yield takeLatest(
      CREATE_SHOW_JUMPING_FENCS_REQ,
      EventMiddleware.createShowJumpingFencs
    ),
    yield takeLatest(
      UPDATE_SHOW_JUMPING_FENCES_REQ,
      EventMiddleware.updateShowJumpingFencs
    ),
    yield takeLatest(
      UPDATE_PROFILE_REQ,
      userMiddleWarre.updateProfileMiddleware
    ),
    yield takeLatest(
      GET_COMPLETE_EVENT_REQ,
      EventMiddleware.getCompletedEvents
    ),
    yield takeLatest(GET_CLASS_BY_EVENT_REQ, EventMiddleware.getClassByEvent),
    yield takeLatest(GET_LEADERBOARD_REQ, EventMiddleware.getLeaderboard),
    yield takeLatest(
      GET_NOTIFICATION_REQ,
      userMiddleWarre.getNotificationMiddleware
    ),
    yield takeLatest(DELETE_USER_CLASS_REQ, EventMiddleware.deleteUserClass),
    yield takeLatest(UPDATE_USER_CLASS_REQ, EventMiddleware.updateUserClass),
    yield takeLatest(DELETE_ACCOUNT_REQ, userMiddleWarre.deleteAccount),
    yield takeLatest(
      CREATE_SUBSCRIPTION_REQ,
      userMiddleWarre.createSubscriptionMiddleWare
    ),
    yield takeLatest(CREATE_LINK_REQ, userMiddleWarre.createLinkMiddleWare),
    yield takeLatest(
      CREATE_ACCOUNT_REQ,
      userMiddleWarre.createAccountMiddleWare
    ),
    yield takeLatest(CHECK_ACCOUNT_REQ, userMiddleWarre.checkAccountMiddleWare),
    yield takeLatest(CHANGE_PASSWORD_REQ, AuthMiddleware.updatePassword),
    yield takeLatest(CANCEL_SUBSCRIPTION, userMiddleWarre.cancelSubscription),
    yield takeLatest(GET_SUBSCRIPTION_PLANE_REQ, userMiddleWarre.getSubscriptionPlanMiddleWare),

  ]);
}
