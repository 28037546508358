import { UserAction } from "../actions";
// import { Alert } from "react-native"
import { Functions, ApiCaller, Variables } from "../../config";
import info from "../../components/info";
import { put, delay } from "redux-saga/effects";
import {
  CANCEL_SUBSCRIPTION,
  CHECK_ACCOUNT,
  CREATE_ACCOUNT,
  CREATE_LINK,
  DEACTIVE_USER,
  NOTTIFICATION,
  SUBSCRIPTION,
  UPDATE_PROFILE,
  USER,
} from "../../config/variables";
import AuthAction from "../actions/AuthAction";

export default class userMiddleWarre {
  static *updateProfileMiddleware(action) {
    const { payload, cb } = action;
    let formData = new FormData();
    delete payload.email;
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    const user = JSON.parse(localStorage.getItem("eventAuth"));

    try {
      let res = yield ApiCaller.Put(UPDATE_PROFILE, formData, {
        Accept: "multipart/form-data",

        "Content-Type": "application/json",
      });

      if (res.status == 200) {
        info("success", res.data.data.message);
        yield put(UserAction.updateProfileSuccess(res.data.data));
        localStorage.setItem(
          "eventAuth",
          JSON.stringify({
            ...user,
            user: res.data.data.user,
          })
        );

        // Functions.showToast(res["data"]["data"]["message"])
        // navigate("SignUpVerify", { email })
        cb && cb();
      } else {
        yield put(UserAction.updateProfileError());
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(UserAction.updateProfileError(e));
      // Functions.showToast(res["data"]["error"]["message"], "danger")
      // info("%c{res}", "color: red", " => ", res);
    }
  }
  static *getNotificationMiddleware(action) {
    const { cb, parmas } = action;
    const qureyParams = new URLSearchParams(parmas).toString();

    try {
      let res = yield ApiCaller.Get(`${USER}/${NOTTIFICATION}?${qureyParams}`);

      if (res.status == 200) {
        yield put(
          UserAction.getNotificationSuccess(res.data.data.notifications)
        );
        cb && cb();
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(UserAction.updateProfileError(e));
    }
  }
  static *deleteAccount() {
    try {
      let res = yield ApiCaller.Delete(`${DEACTIVE_USER}`);

      if (res.status == 200) {
        yield put(AuthAction.Signout());
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(UserAction.updateProfileError(e));
    }
  }
  static *createSubscriptionMiddleWare(action) {
    try {
      let res = yield ApiCaller.Post(`${SUBSCRIPTION}`, { ...action.data });

      if (res.status == 200) {
        // yield put(AuthAction.Signout());
        let arr = [];
        arr.push(res.data.data.subscription.subscription);

        const ls = JSON.parse(localStorage.getItem("eventAuth"));
        const newObj = {
          ...ls,
          user: { ...ls.user, Subscriptions: arr },
        };
        // 
        localStorage.setItem("eventAuth", JSON.stringify(newObj));
        action.cb() && action.cb();
        yield put(UserAction.createSubscriptionSuccess());
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(UserAction.createSubscriptionError(e));
    }
  }

  static *createAccountMiddleWare(action) {
    try {
      let res = yield ApiCaller.Post(`${SUBSCRIPTION}/${CREATE_ACCOUNT}`);

      if (res.status == 200) {
        // yield put(AuthAction.Signout());
        yield put(UserAction.createLinkReq());
      } else {
        yield put(UserAction.createLinkReq());

        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(UserAction.createAccountError(e));
    }
  }
  static *createLinkMiddleWare(action) {
    try {
      let res = yield ApiCaller.Post(`${SUBSCRIPTION}/${CREATE_LINK}`);

      if (res.status == 200) {
        if (!action.data) {
          window.open(res.data.data.accountLink.url, "_blank");
        }

        // yield put(AuthAction.Signout());
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(UserAction.createAccountError(e));
    }
  }
  static *checkAccountMiddleWare(action) {
    try {
      let res = yield ApiCaller.Get(`${SUBSCRIPTION}/${CHECK_ACCOUNT}`);

      if (res.status == 200) {
        // yield put(AuthAction.Signout());
        yield put(UserAction.checkAccountSuccess(res.data.data.account));
        if (action.data) {
          return;
        }
        if (res.data.data.account.status === "active") {
          if (!action.data) {
            info("success", "Account Already linked");
          }
        } else if (res.data.data.account.status === "pending") {
          yield put(UserAction.createLinkReq(action.data));
        } else {
          yield put(UserAction.createAccountReq());
        }
      } else {
        if (res?.data?.error?.message !== "Access denied no token provided") {
          info("error", res?.data?.error?.message);
        }
      }
    } catch (e) {
      yield put(UserAction.checkAccountError(e));
    }
  }
  static *cancelSubscription(action) {
    try {
      let res = yield ApiCaller.Post(`${CANCEL_SUBSCRIPTION}`);

      if (res.status == 200) {
        info("success", res.data.data.message);

        // yield put(AuthAction.Signout());
      } else {
        info("error", res.data.error.message);
      }
    } catch (e) {
      // yield put(UserAction.createAccountError(e));
    }
  }

  static *getSubscriptionPlanMiddleWare(action) {
    try {
      let res = yield ApiCaller.Get(`${SUBSCRIPTION}/plans`);

      if (res.status == 200) {
        // yield put(AuthAction.Signout());
        yield put(UserAction.getSubscriptionPlanSuccess(res.data.data));
      } else {
        yield put(UserAction.getSubscriptionPlanError());

        info("error", res.data.error.message);
      }
    } catch (e) {
      yield put(UserAction.getSubscriptionPlanError(e));
    }
  }

}
