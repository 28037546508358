import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import variables from "../../config/variables";
import moment from "moment";
import "./Styles.css";
import { Formik, Form } from "formik";
import { DatePicker, Space } from "antd";
import DefaultDatePicker from "../../components/input/DatePicker";
import ButtonForm from "../../components/button/Button";
import { RiCalendar2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { profileValidations } from "../../config/schemas";
import background from "../../assets/images/background.png";
import DefaultInput from "../../components/input/Input";
// import RadioButton from "../../components/radio/Radio";
import { Radio } from "antd";
import Button from "../../components/button/Button";
import SignUpContainer from "../../components/containers/SignUpContainer";
import Info from "../../components/info";

import { Metrix, Images, Fonts, Colors, Variables } from "../../config";

import { AuthMiddleware } from "../../store/middlewares";
import { AuthAction } from "../../store/actions";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      companyName: "",
      password: "",
      gender: "",
      confirmPassword: "",
      termsAndConditions: false,
      requiredDate: "",
    };
  }



  resetForm = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      companyName: "",
      password: "",
      confirmPassword: "",
      gender: "",
      termsAndConditions: false,
      dateError: false,
    });
  };
  signUp = () => {};
  changeDate = (dateMoment, dateString) => {
    this.setState({
      dateError: false,
      requiredDate: "",
    });
    if (
      (
        (new Date() - new Date(dateMoment)) /
        1000 /
        60 /
        60 /
        24 /
        365
      ).toFixed() < 18
    ) {
      this.setState({
        dateError: true,
      });
      Info("error", "Age must be greater than 18");

      return;
    }
    this.setState({ dob: dateString });
  };

  render() {
    const { signup, loader } = this.props;
    const {
      firstName,
      lastName,
      email,
      dob,
      companyName,
      password,
      confirmPassword,
      gender,
      termsAndConditions,
      dateError,
      requiredDate,
    } = this.state;

    return (
      <SignUpContainer>
        <Row
          justify="center"
          gutter={[10, 10]}
          style={{ height: "100%", overflowY: "auto" }}
        >
          <Col align="middle" md={24} lg={20} xl={20}>
            <h1 style={{ color: Colors.White }}>Welcome</h1>
            <h3 style={{ color: Colors.White }}> Please Sign Up To Continue</h3>
          </Col>
          <Col xs={18} sm={18} md={12} lg={8} xl={6} align="middle">
            <Formik
              initialValues={{
                firstName,
                lastName,
                email,
                dob,
                companyName,
                password,
                confirmPassword,
                gender,
                termsAndConditions,
              }}
              validationSchema={profileValidations}
              onSubmit={(values) => {
                let val = {
                  ...values,
                  dob: this.state.dob,
                };
                if (!dob) {
                  this.setState({
                    requiredDate: "Date is required",
                  });
                }
                !dateError &&
                  dob &&
                  signup(val, () => {
                    this.props.history.push({
                      pathname: "/verification-code",
                      state: {
                        email: values.email,
                      },
                    });
                  });
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                resetForm,
              }) => {
                return (
                  <Form>
                    <Row gutter={[10, 10]}>
                      <Col span={12} align="start">
                        <p style={{ color: Colors.White }}>First Name*</p>
                        <DefaultInput
                          name="firstName"
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </label>
                      </Col>
                      <Col span={12} align="start">
                        <p style={{ color: Colors.White }}>Last Name*</p>
                        <DefaultInput name="lastName" onChange={handleChange} />
                        <label style={{ color: Colors.Red }}>
                          {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>Email*</p>
                        <DefaultInput name="email" onChange={handleChange} />
                        <label style={{ color: Colors.Red }}>
                          {errors.email && touched.email && errors.email}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>Date of Birth*</p>
                        <DefaultDatePicker
                          allowClear={false}
                          format={""}
                          name="age"
                          format="DD/MM/YYYY"
                          maxD
                          placeholder="Select Date"
                          onChange={this.changeDate}
                          suffixIcon={
                            <RiCalendar2Fill
                              size={25}
                              style={{
                                cursor: "pointer",
                                borderRadius: "5px",
                                color: "white",
                                backgroundColor: "#00A99D",
                              }}
                            />
                          }
                          style={{ borderColor: "#00A99D" }}
                        />
                        <label style={{ color: Colors.Red }}>
                          <p>{requiredDate && requiredDate}</p>
                          {dateError && "Age must be greater then 18"}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>Company Name*</p>
                        <DefaultInput
                          name="companyName"
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.companyName &&
                            touched.companyName &&
                            errors.companyName}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>Password*</p>
                        <DefaultInput
                          password={true}
                          name="password"
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>
                          Re-enter Password*
                        </p>
                        <DefaultInput
                          password={true}
                          name="confirmPassword"
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                        </label>
                      </Col>
                      <Col span={24} align="start">
                        <p style={{ color: Colors.White }}>Gender*</p>
                      </Col>

                      <Col span={24} align="middle">
                        <Radio.Group
                          onChange={handleChange}
                          name="gender"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "2.2rem",
                          }}
                        >
                          <Radio style={{ color: "white" }} value={"male"}>
                            Male
                          </Radio>
                          <Radio value={"female"} style={{ color: "white" }}>
                            Female
                          </Radio>
                          <Radio value={"other"} style={{ color: "white" }}>
                            Other
                          </Radio>
                        </Radio.Group>
                        <label style={{ color: Colors.Red }}>
                          {errors.gender && touched.gender && errors.gender}
                        </label>
                      </Col>
                      <Col span={24} align="middle">
                        <Row justify="start">
                          <Col span={2} align="start">
                            <Radio
                              style={{ cursor: "pointer" }}
                              name="termsAndConditions"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col
                            span={19}
                            align="start"
                            style={{ marginBottom: "1rem" }}
                          >
                            <a
                              href="https://eventpluz.com/terms-and-conditions"
                              style={{ color: Colors.White }}
                              target='_blank'
                            >
                              Agree to Privacy Policy Statement
                            </a>
                          </Col>
                        </Row>
                        <label style={{ color: Colors.Red }}>
                          {errors.termsAndConditions &&
                            touched.termsAndConditions &&
                            errors.termsAndConditions}
                        </label>
                        <Col
                          xs={24}
                          lg={24}
                          align="middle"
                          style={{ marginTop: "1.5rem" }}
                        >
                          <ButtonForm htmlType="submit" loading={loader}>
                            Sign Up
                          </ButtonForm>
                        </Col>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            />
          </Col>
          <Col span={24}>
            <p style={{ color: Colors.White, marginTop: "10px" }}>
              Already have an account?{" "}
              <Link to="/signIn" style={{ color: "#707070" }}>
                Sign in
              </Link>{" "}
            </p>
          </Col>
        </Row>
      </SignUpContainer>
    );
  }
}

function mapStateToProps(storeState) {
  return {
    loader: storeState?.AuthReducer.loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signup: (payload, cb) => dispatch(AuthAction.SignUp(payload, cb)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
