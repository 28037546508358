import React, { Component } from "react";
import ButtonForm from "../../components/button/Button";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import VerficationCode from '../../store/actions/AuthAction'
import { AuthAction } from "../../store/actions";
import { verificationValdations } from "../../config/schemas";
import ReactCodeInput from "react-code-input";
import { Formik, Form } from "formik";
import FPContainer from "../../components/containers/FPContainer";
import BackButton from "../../components/button/BackButton";
import { BiArrowBack } from "react-icons/bi";
import Image from "../../components/image";
import DefaultButton from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";
import { Row, Col } from "antd";
import { Images, Fonts, Colors } from "../../config";
import { AuthMiddleware } from "../../store/middlewares";

class VerificationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      email: null,
      // code1: "",
      // code2: "",
      // code3: "",
      // code4: "",
      // code5: "",
      // code6: "",
      // resendCount: 0,
    };
    // this.code = {};
  }

  componentDidMount() {
    if (this.props.history.location.state != undefined) {
      if (this.props.history.location.state.email != undefined) {
        this.setState({
          email: this.props.history.location.state.email,
        });
      } else {
        this.props.history.push({
          pathname: "/login",
        });
      }
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  }

  handleErrors = () => {
    let error = "";
    if (this.state.code.length == 0) {
      error = "Please enter the code ";
    } else if (this.state.code.length < 4 && this.state.code.length > 0) {
      error = "Incomplete Code ";
    } else {
      error = "";
    }
    this.setState({ error: error });
    return error;
  };
  handleChanged = (val) => {
    this.setState({
      code: val,
      email: this.props.location.state.email,
    });
  };

  redirectNext = () => {
    this.props.history.push({
      pathname: "/signin",
      state: {
        email: this.state.email,
      },
    });
  };
  apiCall = () => {
    let isValid = this.handleErrors();
    if (isValid == "") {
      this.props.sendCode(
        
          {
            email: this.state.email,
            code: this.state.code,
          },
          this.redirectNext()
        
      );
    } else {
      console.error("Error: ", isValid);
    }
  };

  // resendInterval;

  // onChange = (name, text) => {
  //   let { code1, code2, code3, code4, code5, code6 } = this.state;
  //   const textLength = text.length;

  //   switch (name) {
  //     case "code1":
  //       code1 = text;
  //       if (textLength) this.code["code2"].focus();
  //       else this.code["code1"].focus();
  //       break;
  //     case "code2":
  //       code2 = text;
  //       if (textLength) this.code["code3"].focus();
  //       else {
  //         this.code["code1"].focus();
  //       }
  //       break;
  //     case "code3":
  //       code3 = text;
  //       if (textLength) this.code["code4"].focus();
  //       else {
  //         this.code["code2"].focus();
  //       }
  //       break;
  //     case "code4":
  //       code4 = text;
  //       if (textLength) this.code["code5"].focus();
  //       else {
  //         this.code["code3"].focus();
  //       }
  //       break;
  //     case "code5":
  //       code5 = text;
  //       if (textLength) this.code["code6"].focus();
  //       else {
  //         this.code["code4"].focus();
  //       }
  //       break;
  //     case "code6":
  //       code6 = text;
  //       if (!textLength) {
  //         this.code["code5"].focus();
  //       }
  //       break;
  //   }

  //   const code = code1 + code2 + code3 + code4 + code5 + code6;
  //   this.state.code = code;

  //   this.setState({
  //     [name]: text,
  //   });

  //   // if (code.length == 6) {
  //   //     Keyboard.dismiss()
  //   //     // Submission
  //   //     this.continue()
  //   // }
  // };

  // continue = () => {
  //     let { code } = this.state;
  //     const { sendCode, user } = this.props;
  //     const { email } = user;
  //     if (code.length < 6)
  //         Toast.show({ textStyle: {textAlign: "center", fontFamily: Fonts.Montserrat_Medium}, text: "Please enter the verification code.", type: "danger", duration: 3000 })
  //     else {
  //         sendCode({ email, code })
  //         this.allowResend()
  //     }

  // }

  // allowResend = () => {
  //   this.setState({ resendCount: 59 });
  //   this.resendInterval = setInterval(() => {
  //     let { resendCount } = this.state;
  //     if (resendCount < 1) {
  //       clearInterval(this.resendInterval);
  //       this.setState({ resendCount: 0 });
  //     } else this.setState({ resendCount: resendCount - 1 });
  //   }, 1000);
  // };

  // resendCode = () => {
  //   const { sendMail, user } = this.props;
  //   const { email } = user;
  //   this.allowResend();
  //   sendMail({ email });
  // };

  render() {
    return (
      <FPContainer>
        <Row justify="center" gutter={[0, 40]}>
          <Col xs={24} lg={10} align="middle">
            <Image src={logo} />
          </Col>
          <Col xs={24} lg={16}>
            <Row justify="center" align="middle">
              <Col span={2}>
                <Link to="/signIn" style={{ color: "#707070" }}>
                  <BackButton icon={<BiArrowBack color="white" size={20} />} />
                </Link>
              </Col>
              <Col span={6}>
                <h1 style={{ color: Colors.White }}>Enter Code</h1>
              </Col>
              <Col span={24}>
                <p style={{ color: Colors.White }}>
                  Please enter the OTP Code received on your email address
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={24}>
            <ReactCodeInput onChange={this.handleChanged} />
          </Col>
          <Col span={20}>
            <span style={{ color: "#f44336" }}>{this.state.error}</span>
          </Col>
          <Col xs={24} lg={6} align="middle">
            <ButtonForm
              onClick={() => {
                this.apiCall();
              }}
            >
              Continue
            </ButtonForm>
          </Col>
        </Row>
      </FPContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // sendMail: (payload) => {
    //   dispatch(AuthMiddleware.ResetPassword(payload));
    // },
    sendCode: (payload, cb) => {
      dispatch(AuthAction.VerficationCode(payload, cb));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);
