import React from "react";
import styled from "styled-components";
import { BsTrash } from "react-icons/bs";
const StyledButton = styled.button`
  background-color: transparent;
  color: #00a99d;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  padding: 9px 10px 10px 10px;
  border: none;
  height: 2.5rem;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #00a99d;
  }
`;

function DeleteComponent(props) {
  const {loader}=props
  return (
    <StyledButton {...props} loading={loader}>
      <div style={{ display: "flex", alignItems: "center",width:'max-content' }}>
        <BsTrash color="#00A99D" size='25' />
        <span style={{ fontWeight: "600", color: "#00A99D" ,paddingLeft:'10px'}}>Delete</span>
      </div>
    </StyledButton>
  );
}
export default DeleteComponent;
