import React from "react";
import { Row, Col } from "antd";
import { BsTrash } from "react-icons/bs";
import { EventAction } from "../../store/actions";
import { useDispatch } from "react-redux";
import { FaRegEdit } from "react-icons/fa";

const QuestionDescription = (props) => {
  const dispatch = useDispatch();
  const { item, questionIndex, onEditQuestion, id } = props;
  const deleteQuestion = () => {
    dispatch(EventAction.deleteEventQuestion({ EventQuestionId: item.id }));
  };
  return (
    <Row
      justify="space-between"
      style={{
        // borderBottom: "#B9B9B9 1px solid",
        height: "2rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        height: "auto",
        padding: "10px 0 5px 0",
        width: "100%",
      }}
    >
      <Col span={10}>
        <Row justify="start">
          <Col span={2} align="start" style={{ wordWrap: "break-word" }}>
            Q:{questionIndex + 1}
          </Col>
          <Col
            span={18}
            style={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              // wordBreak: "break-word",
              textAlign: "left",
              paddingLeft: "12px",
            }}
          >
            {item.question}
          </Col>
        </Row>
      </Col>
      <Col span={14}>
        <Row justify="space-between">
          <Col span={10}></Col>
          <Col xs={7} sm={7} md={4}>
            <Row style={{ cursor: "pointer" }}>
              <Col
                style={{ visibility: !props.isUpdate ? "hidden" : "visible" }}
                span={6}
                onClick={() => onEditQuestion(questionIndex, id)}
              >
                <FaRegEdit color="#00A99D" />
              </Col>
              <Col
                onClick={deleteQuestion}
                span={2}
                style={{ color: "#00A99D" }}
              >
                <BsTrash color="#00A99D" />
                <span style={{ margin: "7px" }}>Delete</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default QuestionDescription;
