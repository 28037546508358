import React from "react";
import { Row, Col } from "antd";
import { BsTrash } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";

function ManageClassCard({ userClasses, deleteUserClass, updateHandle }) {
  return (
    <>
      <Row
        justify={"space-around"}
        style={{ padding: "3rem" }}
        gutter={[0, 30]}
      >
        {userClasses.map((item) => {
          return (
            <Col
              style={{ boxShadow: "3px 3px 10px lightgrey", height: "7rem" }}
              lg={5}
              md={7}
              sm={11}
              xs={24}
            >
              <Row>
                <Col span={24} style={{ height: "5rem" }}>
                  <h1 className="text-center three-dot" title={item.name}>
                    {item.name}
                  </h1>
                </Col>
                <Col span={24} style={{ borderTop: "solid #5FC9C1 1px" }}>
                  <Row justify="center" align="middle">
                    <Col
                      span={12}
                      style={{
                        borderRight: "1px solid #5FC9C1",
                        verticalAlign: "center",
                      }}
                    >
                      <Row
                        justify="center"
                        align="middle"
                        // style={{ border: "2px solid black" }}
                        onClick={() => updateHandle(item.name, item.id)}
                      >
                        <Col
                          span={4}
                          align="middle"
                          style={{ marginTop: "3px" }}
                        >
                          <FaRegEdit
                            title={item.name}
                            className="theme-font-color"
                            size={17}
                          />
                        </Col>
                        <Col span={4}>
                          {" "}
                          <span className="text-center theme-font-color m-auto">
                            Edit
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row
                        justify="center"
                        //   align="middle"
                        //   style={{ border: "solid black 1px" }}
                      >
                        <Col span={4} style={{ marginTop: "3px" }}>
                          <BsTrash className="theme-font-color" size={16} />
                        </Col>
                        <Col
                          span={4}
                          onClick={() => deleteUserClass(item.id)}
                          className="pointer-cursor"
                        >
                          {" "}
                          <span className="text-center theme-font-color m-auto">
                            Delete
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
export default ManageClassCard;
