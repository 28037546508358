import React from "react";
import styled from "styled-components";
import { Upload, Modal, message } from "antd";
import Icon from "../Icon";
import { FaImage } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import Info from "../info";
const UploadWrapper = styled(Upload)`
  & .ant-upload.ant-upload-select-picture-card {
    width: 100%;

    border-radius: 5px;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat;
  }
  & .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .styleIconText {
    color: black;
  }

  .styleIconText svg {
  }

  .deleteButton {
    color: white;
    width: 25px;
    height: 25px;
    background-color: red;
    border-radius: 5px;
    padding: 5px;
  }

  & .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    height: 9rem;
    margin: 0;
  }
  /* 
& .ant-upload.ant-upload-select-picture-card:hover {
    color:"black"
  } */
  .ant-upload.ant-upload-select {
    width: ${(props) => (props.width ? `100%` : "inherit")};
  }
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  let a = reader.readAsDataURL(img);
}

function beforeUpload(file, props) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  // if (!isJpgOrPng) {
  //   message.error("You can only upload JPG/PNG file!");
  // }
  const isLt2M =
    props.type === "image"
      ? file.size / 1024 / 1024 < 10
      : file.size / 1024 / 1024 < 50;
  if (!isLt2M) {
    Info("error", "Image must smaller than 10Mb!");
    return;
  }
  return isJpgOrPng && isLt2M;
}

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: props.initialPreview,

      previewVisible: false,
      previewImage: "",

      loading: false,
      errorMsg: false,
    };
  }
  clearThumnail = () => {
    this.setState({
      imageUrl: "",
    });
  };
  componentDidMount() {
    // this.props.clearUpload(this.clearThumnail);
    if (this.props?.clearUpload) {
      this.props.clearUpload(this.clearThumnail);
    }
  }
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (url) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }

    this.setState({
      previewImage: url,
      previewVisible: true,
    });
  };

  handleChange = (info) => {
    const isLt2M = info.file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      Info("error", "Image must smaller than 10Mb!", 1);
      
      return;
    }
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.props.onChange(info.file);
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };
  componentDidUpdate(preProps, preState) {
    if (preProps.initialPreview !== this.props.initialPreview) {
      this.setState({
        imageUrl: this.props.initialPreview,
      });
    }
  }
  render() {
    const uploadButton = (
      <div
        className="styleIconText"
        style={{ backgroundColor: "#E9E9E9", padding: "10px" }}
      >
        {this.state.loading ? (
          <Icon type="loading" style={{ fontSize: "1.5rem" }} />
        ) : (
          <FaImage />
        )}
        <span className="ant-upload-text" style={{ marginLeft: 10 }}>
          {this.props.btnText}
        </span>
      </div>
    );
    const { previewVisible, previewImage, imageUrl } = this.state;
    return (
      <div>
        <UploadWrapper
          showUploadList={false}
          action="https://ej2.syncfusion.com/services/api/uploadbox/Save"x
          // beforeUpload={(e) => beforeUpload(e, this.props)}
          accept={
            this.props.type === "image" ? ".png, .jpg, .jpeg," : ".pdf,.docx"
          }
          //onPreview={this.handlePreview}
          {...this.props}
          onChange={(e) => {
            if (this.props.type !== "image") {
              const isLt2M = e.file.size / 1024 / 1024 < 50;
              if (!isLt2M) {
                Info("File must smaller than 50Mb!");
                return;
              }
              this.props.onChange(e.file.originFileObj);
              return;
            }

            this.handleChange(e);
          }}
        >
          {imageUrl ? (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={(e) => {
                e.stopPropagation(e);
                this.handlePreview(imageUrl);
              }}
            >
              <div
                style={{
                  position: "absolute",
                  //top: 0,
                  bottom: 0,
                  right: 0,
                  //left: 0,
                  transform: "translate(-12.5px, -12.5px)",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!this.props.disabled && (
                  <AiOutlineDelete
                    className="deleteButton"
                    style={{ position: "absolute" }}
                    onClick={(e) => {
                      e.stopPropagation(e);
                      this.setState({ imageUrl: "" });
                      if (this.props.onChange) {
                        this.props.onChange("");
                      }
                    }}
                  />
                )}
              </div>
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: this.props.width || 100,
                  height: this.props.height,
                  borderRadius: this.props.borderRadius,
                }}
              />
            </div>
          ) : (
            uploadButton
          )}
        </UploadWrapper>
        <Modal
          className="imagePreviewModal"
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        {/* {this.state.errorMsg && Info("error", "Error")} */}
      </div>
    );
  }
}

export default ImageUpload;
