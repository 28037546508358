import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  LOADER_FALSE,
  LOADER_TRUE,
  VERIFICATION_CODE,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_CODE_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SIGNOUT_FAILURE,
  SOCIAL_SIGN_IN_REQ,
  SOCIAL_SIGN_IN_SUCCESS,
  SOCIAL_SIGN_IN_ERROR,
  SOCIAL_EXTRA_DETAILS_REQ,
  SOCIAL_EXTRA_DETAILS_SUCCESS,
  SOCIAL_EXTRA_DETAILS_ERROR,
  CHANGE_PASSWORD_REQ,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "../constants";

export default class AuthAction {
  static SignUp(payload, cb) {
    return {
      type: SIGNUP,
      payload,
      cb,
    };
  }
  static SignUpSuccess(payload) {
    return {
      type: SIGNUP_SUCCESS,
      payload,
    };
  }
  static SignUpFailure() {
    return {
      type: SIGNUP_FAILURE,
    };
  }

  static SignIn(payload, cb, callback) {
    return {
      type: SIGNIN,
      payload,
      cb,
      callback,
    };
  }
  static SignInSuccess(payload) {
    return {
      type: SIGNIN_SUCCESS,
      payload,
    };
  }
  static SignInFailure() {
    return {
      type: SIGNIN_FAILURE,
    };
  }

  static VerficationCode(payload, cb) {
    return {
      type: VERIFICATION_CODE,
      payload,
      cb,
    };
  }
  static VerficationCodeSuccess(res) {
    return {
      type: VERIFICATION_CODE_SUCCESS,
      res,
    };
  }
  static VerficationCodeFailure(error) {
    return {
      type: VERIFICATION_CODE_FAILURE,
      error,
    };
  }

  static ForgotPassword(payload, cb) {
    return {
      type: FORGOT_PASSWORD,
      payload,
      cb,
    };
  }
  static ForgotPasswordSuccess(res) {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      res,
    };
  }
  static ForgotPasswordFailure(error) {
    return {
      type: FORGOT_PASSWORD_FAILURE,
      error,
    };
  }

  static ResetPassword(payload, cb) {
    return {
      type: RESET_PASSWORD,
      payload,
      cb,
    };
  }
  static ResetPasswordSuccess(res) {
    return {
      type: RESET_PASSWORD_SUCCESS,
      res,
    };
  }
  static ResetPasswordFailure(error) {
    return {
      type: RESET_PASSWORD_FAILURE,
      error,
    };
  }

  static SocialSignInReq(payload, cb) {
    return {
      type: SOCIAL_SIGN_IN_REQ,
      payload,
      cb,
    };
  }
  static SocialSignInSuccess(res) {
    return {
      type: SOCIAL_SIGN_IN_SUCCESS,
      res,
    };
  }
  static SocialSignInError(error) {
    return {
      type: SOCIAL_SIGN_IN_ERROR,
      error,
    };
  }

  static SocialExtraDetailsReq(payload, cb) {
    return {
      type: SOCIAL_EXTRA_DETAILS_REQ,
      payload,
      cb,
    };
  }
  static SocialExtraDetailsSuccess(res) {
    return {
      type: SOCIAL_EXTRA_DETAILS_SUCCESS,
      res,
    };
  }
  static SocialExtraDetailsError(error) {
    return {
      type: SOCIAL_EXTRA_DETAILS_ERROR,
      error,
    };
  }

  static Signout() {
    return {
      type: SIGNOUT,
    };
  }
  static SignoutSuccess(res) {
    return {
      type: SIGNOUT_SUCCESS,
      res,
    };
  }
  static SignoutFailure(error) {
    return {
      type: SIGNOUT_FAILURE,
      error,
    };
  }

  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }

  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
  static changePasswordReq(payload, cb,push) {
    return {
      type: CHANGE_PASSWORD_REQ,
      payload,
      cb,
      push
    };
  }
}
