import React from "react";
import { Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: #e9e9e9;
  color: black;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: ${(props) => (props.width ? props.width : "80%")};
  padding: 9px 23px 20px 10px;
  height: 2.5rem;
  &:hover,
  &:active,
  &:focus {
    background-color: #e9e9e9;
    color: black;
  }
`;
function AddBtnWithTitle(props) {
  const { loading } = props;
  return (
    <StyledButton {...props}>
      {/* <div
        style={{ display: "flex", alignItems: "center" }}
      >
        <span style={{paddingRight: "10px",backgroundColor:"white",borderRadius:"15px" }}>
          {props.icon ? props.icon() : ""}
        </span>
        <span style={{ fontWeight: "600" }}>{props.title}</span>
      </div> */}
      {/* <Row>
        <Col span={3} style={{backgroundColor:"white",borderRadius:"25px" }}>{props.icon ? props.icon() : ""}</Col>
        <Col span={16}>{props.title}</Col>
      </Row> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          ...(loading ? { justifyContent: "center" } : {}),
        }}
      >
        {loading ? (
          <LoadingOutlined />
        ) : (
          <>
            <span style={{ paddingRight: "8px" }}>
              {props.icon ? props.icon() : ""}
            </span>
            <span style={{ fontWeight: "600",fontSize:'10px' }}>{props.title}</span>
          </>
        )}
      </div>
    </StyledButton>
  );
}
export default React.memo(AddBtnWithTitle);
