import React, { Component } from "react";
import { Col, Row } from "antd";
import Horse from "../../assets/images/rider.png";
import HorizontalLine from "../../components/eventsHeading/horizontalLine";
import VerticalLine from "../../components/verticalLine";
import { connect } from "react-redux";
import EventAction from "../../store/actions/EventAction";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import CustomModal from "../../components/modals/customModal";
import ButtonForm from "../../components/button/Button";
import info from "../../components/info";

class ViewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventClassIndex: 0,
      visible: false,
      eventClassId: "",
    };
  }
  componentDidMount() {
    const {
      getEventById,
      match: {
        params: { id },
      },
    } = this.props;
    getEventById(id);
  }
  closeModal = () => {
    this.setState({
      visible: false,
    });
  };
  componentDidUpdate(preProps) {
    if (preProps.eventDetail !== this.props.eventDetail) {
      this.setState({
        eventClassId:
          this.props.eventDetail?.EventClass?.[this.state.eventClassIndex]?.id,
      });
    }
  }
  render() {
    
    const { eventDetail, loading } = this.props;
    if (loading) {
      return <LoadingOutlined style={{ fontSize: 24, margin: "auto" }} spin />;
    }
    const fromHome = this.props.location?.state?.home;
    return (
      <Row className="container-padding">
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row justify="center" align="middle">
            <Col span={12}>
              <h2 className="text-left font-bold text-capitilize">
                {eventDetail?.name}
              </h2>
              <p
                className="text-left event-description"
                title={eventDetail?.description}
              >
                {`${eventDetail?.description}`}
              </p>
            </Col>
            {!fromHome && (
              <Col span={2}>
                <FaRegEdit
                  color="black"
                  size={20}
                  className="pointer-cursor mr"
                  onClick={() => {
                    eventDetail.status === "completed"
                      ? info("error", "Completed Event can not be Updated", 1)
                      : this.props.history.push({
                          pathname: "/update-event",
                          state: { update: true, eventDetail },
                        });
                  }}
                />
                <BsTrash
                  color="#00A99D"
                  size={20}
                  className="pointer-cursor"
                  onClick={() =>
                    this.setState({
                      visible: true,
                    })
                  }
                />
              </Col>
            )}

            <Col span={2}>
              <VerticalLine />
            </Col>
            <Col span={8}>
              <Row justify="start">
                <Col span={24} style={{ marginLeft: "9px" }}>
                  {" "}
                  <h3 className="text-left d-inline">Date:</h3>
                  <span style={{ marginLeft: "5px" }}>
                    {moment(eventDetail?.event_date).format("L")}
                  </span>
                </Col>
                <Col span={24}>
                  {" "}
                  <h3 className="text-left d-inline">Time:</h3>
                  <span style={{ marginLeft: "5px" }}>
                    {moment(eventDetail.event_date).utc().format("LT")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          {!fromHome && (
            <Row>
              <Col span={24}>
                <h2 className="text-left font-bold">Registered Rider</h2>
              </Col>
              <Col span={24}>
                <p className="text-left">
                  {
                    eventDetail?.EventClass?.[this.state.eventClassIndex]
                      ?.ClassMembers?.length
                  }
                </p>
              </Col>
              {eventDetail?.EventClass?.length && (
                <Col span={24}>
                  <p
                    className="font-theme-color text-left pointer-cursor text-capitilize"
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/view-event/riderDetail",
                        state: {
                          classId: this.state.eventClassId,
                          eventId: this.props.match?.params?.id,
                        },
                      })
                    }
                  >
                    View Details
                  </p>
                </Col>
              )}
            </Row>
          )}

          <Row>
            <Col span={22}>
              <HorizontalLine className="m-zero" />
            </Col>
          </Row>
          <Row justify="start">
            {(eventDetail?.EventClass || []).map((item, index) => {
              return (
                <Col
                  lg={8} md={12} sm={24} xl={8} xs={24}
                  onClick={() =>
                    this.setState({
                      eventClassIndex: index,
                      eventClassId: item?.id,
                    })
                  }
                  className="pointer-cursor"
                >
                  <h2
                    style={{
                      color:
                        index === this.state.eventClassIndex
                          ? "#00A99D"
                          : "black",
                    }}
                    className="text-left text-capitilize three-dot"
                  >
                    {item?.UserClass?.name}
                  </h2>
                </Col>
              );
            })}
          </Row>
          <CustomModal
            visible={this.state.visible}
            onCancelModal={() => this.closeModal()}
          >
            <Row justify="center" gutter={[0, 20]}>
              <Col span={24}></Col>
              <Col span={24}>
                <h2 className="text-center">Confirm Delete</h2>
                <p className="text-center">
                  {" "}
                  Are you sure you want to delete this event?
                </p>
              </Col>
              <Col span={8} style={{ marginBottom: "10px" }}>
                <ButtonForm
                  onClick={() =>
                    this.props.deleteEvent(this.props.match.params.id, () =>
                      this.props.history.push("/my-event")
                    )
                  }
                >
                  Yes
                </ButtonForm>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={8}>
                <ButtonForm color="#C5C3C3" onClick={() => this.closeModal()}>
                  No
                </ButtonForm>
              </Col>
            </Row>{" "}
          </CustomModal>
          <Row>
            <Col span={22}>
              <HorizontalLine className="m-zero" />
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              className="d-flex justify-content-start align-item-middle"
            >
              <h2 className="text-left d-inline font-bold">Hosted by:</h2>
              <span
                className="text-left three-dot"
                style={{ fontSize: "24", marginLeft: "5px" }}
              >{`${eventDetail?.EventManager?.firstName} ${eventDetail?.EventManager?.lastName}`}</span>{" "}
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <HorizontalLine className="m-zero" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h2 className="text-left font-bold">Event Class Description</h2>
            </Col>
            <Col span={24} style={{ fontSize: '24', margin: "auto" }}>
              <p className="text-left">
                {
                  eventDetail?.EventClass?.[this.state.eventClassIndex]
                    ?.description
                }
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <img
            src={eventDetail?.cover_image}
            alt="cover_image"
            height={550}
            width="100%"
          />
        </Col>
      </Row>
    );
  }
}
function mapStateToProps(state) {
  
  return {
    eventDetail: state.EventReducer.viewEvent || {},
    loading: state?.EventReducer?.viewEventLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getEventById: (id) => dispatch(EventAction.getEventByIdReq(id)),
    deleteEvent: (id, cb) => dispatch(EventAction.deletEventRequest(id, cb)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEvent);
