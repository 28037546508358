import * as Yup from "yup";

export const Showjumping = Yup.object().shape({
    optimumTime: Yup.string().required("Optimum time required"),
    courseLength:Yup.string().required("Course length Required"),
    fences:Yup.string().required("Fence is Required"),
    // document:Yup.mixed().required('Document is Required'),
    // coverImage:Yup.string().required('Image is Required'),
    userId:Yup.string().required('Judge is Required'),
    eventClassId:Yup.string().required('Class Name is required')

});
  