import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import EventReducer from "./EventReducer";
import UserReducer from "./UserReducer";
const RootReducer = combineReducers({
  AuthReducer,
  EventReducer,
  UserReducer,
});

export default RootReducer;
