import { Col, Row } from "antd";
import React, { useEffect } from "react";
import SignInContainer from "../../components/containers/SignInContainer";
import SubscriptionCard from "../../components/subcriptionCards";
import { AiFillCloseSquare } from "react-icons/ai";
import useMedia from "use-media";
import { useDispatch, useSelector } from "react-redux";
import { UserAction } from "../../store/actions";

function Subscription(props) {
  const isWide = useMedia({ minWidth: 768 });
  const disptach = useDispatch();
  const subscriptionData = useSelector(
    (store) => store.UserReducer.subscriptionPlan
  );
  useEffect(() => {
    disptach(UserAction.getSubscriptionPlanReq());
  }, []);
  console.log("SUBSCROPTION==>", subscriptionData?.subscriptionPlans);
  return (
    <SignInContainer height={isWide ? "100vh" : "auto"}>
      <Row justify="center" style={{ width: "100%" }}>
        <Col span={23} style={{ marginTop: "2rem" }}>
          <h1
            className="text-center"
            style={{ color: "white", fontSize: "2rem" }}
          >
            Subscription Plan{" "}
          </h1>
        </Col>
        <Col span={1} style={{ marginTop: "2rem" }} className="pointer-cursor">
          <AiFillCloseSquare
            color="white"
            size="30"
            onClick={() => props.history.push("/")}
          />
        </Col>
        <Col span={24}>
          <Row>
            {(subscriptionData?.subscriptionPlans || []).map((item) => {
              const description=item.description.split('\r\n')
              console.log('splitted==>',description)
              return (
                <Col
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginBottom: "3rem" }}
                >
                  <SubscriptionCard
                    {...props}
                    price={`£${item.price}`}
                    description1={`${description[0]}`}
                    description2={`${description[1]}`}

                    plan={`${item.name} Plan`}
                    color="#00A99D"
                    push={() =>
                      props.history.push({
                        pathname: "/payment",
                        state: {
                          plan: item.name.toLowerCase(),
                          price: `£${item.price}`,
                        },
                      })
                    }
                  />
                </Col>
              );
            })}

            {/* <Col lg={12} md={12} sm={24} xs={24}>
              <SubscriptionCard
                {...props}
                price="£999"
                description1="Create Unlimited Events"
                description2="Yearly And Get Started"
                plan="Annual Plan"
                color="#5900A9"
                push={() =>
                  props.history.push({
                    pathname: "/payment",
                    state: { plan: "yearly", price: "£999" },
                  })
                }
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </SignInContainer>
  );
}
export default Subscription;
