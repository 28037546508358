import React, { Component } from "react";
import { Row, Col } from "antd";
import ButtonForm from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";
import ImageUpload from "../../components/uploadimage";
import { connect } from "react-redux";
import { EventAction } from "../../store/actions";
import DefaultTextArea from "../../components/input/TextArea";
import DatePicker from "../../components/input/DatePicker";
import { RiCalendar2Fill } from "react-icons/ri";
import { Colors } from "../../config";
import { Formik, Form } from "formik";
import { createEvent1Validations } from "../../config/schemas";
import moment from "moment";
import Info from "../../components/info";

class CreateEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventDate: moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss"),
      name: "",
      description: "",
      coverImage: "",
      className: "",
      eventId: null,
      question: "",
      eventClass: "",
      eventQuestion: "",
      userClassId: null,
      disabled: false,
      visible: false,
      isCreated: false,
      filtered: [],
      arr: [],
      dateError: false,
      fileObj: {},
    };
  }

  changeDate = (dateMoment, dateString) => {
    // let todayInMili = new Date();
    // todayInMili.setHours(0, 0, 0, 0);
    if ((new Date() - new Date(dateMoment)).toFixed() < 0) {
      this.setState({ eventDate: dateString, dateError: false });
      return;
    }
    this.setState({
      dateError: true,
    });
    Info("Selected must be greater than today");
  };
  componentDidMount() {
    if (this.props.history?.location?.state?.update) {
      this.createFile();

      this.setState({
        eventDate:
          this.props?.history?.location?.state?.eventDetail?.event_date,
        coverImage: this.state.fileObj,
      });
    }
  }
  createFile = async () => {
    let response = await fetch(
      this.props?.history?.location?.state?.eventDetail?.cover_image
    );
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "test.jpg", metadata);
    // ... do something with the file or return it
    this.setState({
      fileObj: file,
    });
  };
  componentDidUpdate(preProps, prevState) {
    if (prevState.fileObj !== this.state.fileObj) {
      let event = {
        target: {
          name: "coverImage",
          value: this.state.fileObj,
        },
      };
      this.formicHandleChangeRef(event);
    }
  }

  isChecked = [];
  changeImage = (e) => {
    this.setState({ coverImage: e.originFileObj });
  };
  onHandleChange = (e) => {
    this.setState({
      className: e.target.value,
    });
  };

  handleChange = (e) => {
    const { userClass } = this.props;

    this.state.arr.push(e.target.value);
    this.setState({
      ...this.state,
      arr: this.state.arr,
    });
    const filtered = userClass.filter((item) => {
      return this.state.arr.includes(item.id);
    });
    this.setState({
      ...this.state,
      filtered,
    });
  };
  disabledDate(current) {
    let customDate = moment().add(1, "days").format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  }
  render() {
    const { createEvent, updateCoverImage, loader, history, updateEvent } =
      this.props;
    const eventId = localStorage.getItem("eventId");
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // borderBottom: '1px dotted pink',
        borderColor: "#00A99D solid 2px",
        color: "black",
        padding: 10,
        backgroundColor: "white",
      }),
    };
    const { name, eventDate, coverImage, description } = this.state;

    // const classOptions = (this.props.eventClass || []).map((item) => {
    //   return { value: item.id, label: item?.UserClass?.name };
    // });
    const filterOption = (this.state.filtered || []).map((item) => {
      return { label: item.name, value: item.id };
    });
    const eventClassOption = (this.props.eventClass || []).map((item) => {
      return { label: item?.UserClass?.name, value: item.id };
    });
    const { dateError } = this.state;
    const isUpdate = history?.location?.state?.update;
    const eventDetail = history?.location?.state?.eventDetail;
    

    return (
      <>
        <Row justify="start" align="middle">
          <Col xs={24} sm={10} md={8} lg={6} xl={8} align="middle">
            <h1 style={{ fontWeight: "700", fontSize: "35px" }}>
              {isUpdate ? "Edit Event" : "Create Event"}
            </h1>
          </Col>
        </Row>

        <Row justify="center" gutter={[0, 20]}>
          <Col xs={22} sm={20} md={18} lg={14} xl={12}>
            <Formik
              initialValues={{
                name: isUpdate ? eventDetail.name : this.state.name,
                eventDate: this.state.eventDate,
                coverImage: coverImage,
                description: isUpdate ? eventDetail.description : description,
                location: isUpdate ? eventDetail.location : description,
              }}
              validationSchema={createEvent1Validations}
              onSubmit={(values) => {
                let newValues = {
                  ...values,
                  eventDate: this.state.eventDate,
                };
                
                !dateError &&
                  !isUpdate &&
                  createEvent(newValues, () => {
                    this.props.history.push({
                      pathname: "/create-event-continue",
                    });
                  });
                !dateError &&
                  isUpdate &&
                  updateEvent(
                    newValues,
                    this.props?.history?.location?.state.eventDetail?.id,
                    () => {
                      this.props.history.push({
                        pathname: "/update-event-continute",
                        state: {
                          ...this.props?.history?.location?.state.eventDetail,
                          isUpdate: true,
                        },
                      });
                    }
                  );
                !dateError &&
                  isUpdate &&
                  updateCoverImage({
                    coverImage: values.coverImage,
                    eventId:
                      this.props?.history?.location?.state.eventDetail?.id,
                  });
              }}
              render={({ values, touched, handleChange, errors }) => {
                
                this.formicHandleChangeRef = handleChange;
                return (
                  <Form>
                    <Row gutter={[30, 20]} justify="center" align="midlle">
                      <Col
                        xs={20}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        align="start"
                      >
                        <p>Event Name</p>
                        <DefaultInput
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          style={{ borderColor: "#00A99D" }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.name && touched.name && errors.name}
                        </label>
                      </Col>
                      <Col
                        xs={20}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        align="start"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <p>Event Date</p>
                        <DatePicker
                          showTime
                          disabledDate={this.disabledDate}
                          use12Hours={true}
                          allowClear={false}
                          showNow={false}
                          value={moment(this.state.eventDate)}
                          name="eventDate"
                          onChange={this.changeDate}
                          format="YYYY-MM-DD HH:mm:ss"
                          suffixIcon={
                            <RiCalendar2Fill
                              size={25}
                              style={{
                                cursor: "pointer",
                                borderRadius: "5px",
                                color: "white",
                                backgroundColor: "#00A99D",
                              }}
                            />
                          }
                          style={{ borderColor: "#00A99D" }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {dateError &&
                            "Selected Date must be greater than today"}
                        </label>
                      </Col>
                      <Col
                        xs={20}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        align="start"
                        style={{ cursor: "pointer" }}
                      >
                        <p>Event image Header</p>
                        <ImageUpload
                          defaultFileList={
                            isUpdate ? eventDetail.cover_image : ""
                          }
                          onChange={(e) => {
                            let event = {
                              target: {
                                name: "coverImage",
                                value: e.originFileObj,
                              },
                            };
                            handleChange(event);
                          }}
                          btnText="Upload Image"
                          name="coverImage"
                          type="image"
                          initialPreview={
                            isUpdate ? eventDetail.cover_image : ""
                          }
                        />
                        <label style={{ color: Colors.Red }}>
                          (size 300 x 600) Max 10Mb)
                        </label>
                        <p style={{ color: Colors.Red }}>
                          {errors.coverImage &&
                            touched.coverImage &&
                            errors.coverImage}
                        </p>
                      </Col>
                      <Col
                        xs={20}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        align="start"
                      >
                        <p>Event Description</p>

                        <DefaultTextArea
                          name="description"
                          onChange={handleChange}
                          value={values.description}
                          style={{ borderColor: "#00A99D", height: "6.6rem" }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </label>
                      </Col>
                        <Col
                          xs={20}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          align="start"
                        >
                          <p>Location</p>
                          <DefaultInput
                            name="location"
                            onChange={handleChange}
                            value={values.location}
                            style={{ borderColor: "#00A99D" }}
                          />
                          <label style={{ color: Colors.Red }}>
                            {errors.location &&
                              touched.location &&
                              errors.location}
                          </label>
                        </Col>
                        <Col span={12}></Col>
                      <Col xs={20} sm={14} md={12} lg={10} xl={10}>
                        <ButtonForm
                          htmlType="submit"
                          loading={
                            isUpdate ? this.props.updateEventLoader : loader
                          }
                          // onClick={}
                        >
                          Continue
                        </ButtonForm>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(storeState) {
  return {
    userClass: storeState?.EventReducer?.userClass,
    eventClass: storeState?.EventReducer.eventClass,
    eventData: storeState.EventReducer.eventData,
    loader: storeState?.EventReducer.isCreating,
    eventQuestion: storeState?.EventReducer.eventQuestion,
    updateEventLoader: storeState?.EventReducer.updateEventLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // signup: (payload, cb) => dispatch(AuthAction.SignUp(payload, cb)),
    createEvent: (payload, cb) =>
      dispatch(EventAction.createEventRequest(payload, cb)),
    updateEvent: (payload, cb, callBack) =>
      dispatch(EventAction.updateEventRequest(payload, cb, callBack)),
    updateCoverImage: (payload, cb) =>
      dispatch(EventAction.updateEventCoverRequest(payload, cb)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
