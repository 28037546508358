import React from "react";
import styled from "styled-components";
import Colors from "../../config/colors";
import "./Styles.css";
import background from "../../assets/images/background.png";

// height:${(props) => (props.height ? props.height : "auto")};
const SignUpContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  height:100vh;
  width:100%;
  overflow:hidden;
  display: flex;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (device-aspect-ratio: 40/71) {
    padding: 0 !important;
    
  }

  @media screen and (device-aspect-ratio: 3/4) {
    padding: 6rem 2rem;
    height:110vh;
  } 


  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    padding: 0 !important;
  }
  /*iPhone6/7/8 Plus*/
  @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
         .Property name{
             ...//Style
    }
  }
  /*iPhone X*/
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
         .Property name{
             ...//style
    }
  }
`;

const StyledUpContainer = (props) => {
  return (
    <SignUpContainer {...props}>
      {props.children}
    </SignUpContainer>
  );
};

export default StyledUpContainer;
