import React, { Component } from "react";
import { Row, Col } from "antd";
import SelectInput from "../../components/input/select";
import DressageListing from "../../components/dressageListing";
import { Input, Space } from "antd";
import { BiPlusCircle } from "react-icons/bi";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "../../components/button/Button";
import EventAction from "../../store/actions/EventAction";
import Select, { components } from "react-select";
import EventHeading from "../../components/eventsHeading";
import HorizontalLine from "../../components/eventsHeading/horizontalLine";
import AddBtnWithTitle from "../../components/addWithTittle";
import { Formik, Form } from "formik";
import { DressageSchemas } from "../../config/schemas";
import { Metrix, Images, Fonts, Colors } from "../../config";
import { AiOutlineSearch } from "react-icons/ai";

const { Search } = Input;
class Dressage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventClassId: "",
      testId: "",
      judgeName: "",
      eventName: "",
      testName: "",
      judge: "",
      setClassId: "",
      className: "",
      isCreated: false,
      eventId: "",
      eventName: "",
      dressageIndex: "",
    };
  }
  componentDidMount() {
    // if (localStorage.getItem("eventId")) {
    //   this.setState({ eventId: localStorage.getItem("eventId") });
    // } else {
    //   this.props.history.push({
    //     pathname: "/create-event",
    //   });
    // }
    const eventId = localStorage.getItem("eventId");
    const { getEventClass, getTests, getDressage, location } = this.props;
    getTests();
    getEventClass({
      eventId: location.state?.isUpdate
        ? location.state.eventDetail?.id
        : eventId,
    });
    getDressage({
      eventId: location.state?.isUpdate
        ? location.state.eventDetail?.id
        : eventId,
    });
  }
  getJudge = (value) => {
    const { searchJudge } = this.props;
    searchJudge(value);
  };
  createDressage = () => {
    const { judge, setClassId, testId } = this.state;
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    this.props.createDressage(
      {
        eventClassId: setClassId,
        testId,
        userId: this.state.judge,
      },
      () => this.setState({ isCreated: true })
    );
  };
  componentDidUpdate(preprops, preState) {
    if (preprops.eventClass !== this.props.eventClass) {
      this.setState({
        setClassId: this.props.eventClass?.[0]?.id,
        testId: this?.props?.tests?.[0]?.id,
      });
    }
    if (preState.dressageIndex !== this.state.dressageIndex) {
      const event = {
        target: {
          name: "testId",
          value:
            this.props.dressage?.[this.state.dressageIndex]?.DressageTest?.id,
        },
      };
      const e = {
        target: {
          name: "eventClassId",
          value:
            this.props.dressage?.[this.state.dressageIndex]?.DressageClass?.id,
        },
      };
      const even = {
        target: {
          name: "userId",
          value: this.props.dressage?.[this.state.dressageIndex]?.Judge?.id,
        },
      };
      this.setState({
        testId: {
          label:
            this.props.dressage?.[this.state.dressageIndex]?.DressageTest?.name,
          value:
            this.props.dressage?.[this.state.dressageIndex]?.DressageTest?.id,
        },
        eventName: {
          label:
            this.props.dressage?.[this.state.dressageIndex]?.DressageClass
              ?.UserClass?.name,
          value:
            this.props.dressage?.[this.state.dressageIndex]?.DressageClass?.id,
        },
        judgeName: {
          label:
            this.props.dressage?.[this.state.dressageIndex]?.Judge?.firstName,
          value: this.props.dressage?.[this.state.dressageIndex]?.Judge?.id,
        },
      });
      this.formiRef(event);
      this.formiRef(e);
      this.formiRef(even);
    }
  }
  deleteDressageById = () => {
    const { deleteDressage, dressage } = this.props;
    deleteDressage({ dressageId: dressage.id }, () =>
      this.setState({ isCreated: false })
    );
  };
  editDressage = (index) => {
    this.setState({
      dressageIndex: index,
    });
  };

  render() {
    const tests = this?.props?.tests;
    const classes = this?.props?.classes;
    const { dressage, isLoading } = this.props;
    const updateDressageOpt = [this.state.judgeName];

    const options = (tests || []).map((item) => {
      return { value: item.id, label: item.name };
    });
  
    const judgeOptions = (this.props.judge || []).map((item) => {
      return {
        value: item.id,
        label: `${item.firstName} 
      (${item.email})
    `,
      };
    });
    const testOption = (tests || []).map((item) => {
      return { value: item.id, label: item.name };
    });
    // const customStyles = {
    //   option: (provided, state) => ({
    //     ...provided,
    //     // borderBottom: '1px dotted pink',
    //     color: "black",
    //     padding: 10,
    //     backgroundColor: "white",
    //   }),
    // };
    
    const customStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma('lightblue');
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "lightblue"
            : isFocused
            ? "#eaeaea"
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? "#eaeaea"
              ? "black"
              : "black"
            : "black",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? "lightblue" : "#eaeaea"),
          },
        };
      },
      input: (styles) => ({ ...styles }),
      placeholder: (styles) => ({ ...styles }),
      singleValue: (styles, { data }) => ({ ...styles }),
    };
    const data = [
      {
        judgeName: `${dressage?.Judge?.firstName} ${dressage?.Judge?.lastName}`,
        dreassageName: `${dressage?.DressageTest?.name}`,
        className: this.state.className,
      },
    ];
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <AiOutlineSearch />
          </components.DropdownIndicator>
        )
      );
    };
    let res = [];
    const eventClass=this.props.eventClass
    var uniqueResultOne = eventClass.filter(function (obj) {
      return !dressage.some(function (obj2) {
        return obj.id == obj2.eventClassId;
      });
    });
    const classoptions = (uniqueResultOne || []).map((item) => {
      return { value: item.id, label: item?.UserClass?.name };
    });

    
    const isUpdate = this.props?.location?.state?.isUpdate;
    
    return (
      <div>
        <Row>
          <Col xs={24} sm={10} md={8} lg={6} xl={8} align="middle">
            {/* <EventHeading title={"Dressage"} /> */}
            <h1 style={{ fontWeight: "700", fontSize: "35px" }}>
              {isUpdate ? "Update Dressage" : "Dressage"}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <HorizontalLine width="85%" />
          </Col>
        </Row>
        <Formik
          initialValues={{
            testId: "",
            eventClassId: "",
            userId: "",
          }}
          validationSchema={DressageSchemas}
          onSubmit={(values, { resetForm }) => {
            // let newValues = {
            //   ...values,
            //   eventDate: ,
            //   coverImage: coverImage,
            // };
            !this.state?.dressageIndex?.toString() &&
              this.props.createDressage(values, () =>
                this.setState({
                  show1: true,
                  testId: "",
                  eventName: "",
                  judgeName: "",
                })
              );
            this.state?.dressageIndex?.toString() &&
              this.props.updateDressage(
                {
                  ...values,
                  dressageId:
                    this.props.dressage?.[this.state.dressageIndex]?.id,
                },
                {
                  eventId:
                    this.props.dressage?.[this.state.dressageIndex]
                      ?.DressageClass?.eventId,
                },
                () => {
                  this.setState({
                    dressageIndex: "",
                    testId: "",
                    eventName: "",
                    judgeName: "",
                  });
                }
              );
            resetForm({ values: "" });
          }}
          render={({ values, touched, handleChange, errors }) => {
            this.formiRef = handleChange;
            return (
              <Form style={{ width: "85%", margin: "auto" }}>
                <Row gutter={[40, 10]}>
                  <Col lg={6} sm={12} xs={24} md={12}>
                    <label className="label-alignlf">Test</label>
                    <Select
                      options={options}
                      className="userSelect"
                      placeholder="Test Name"
                      name="testId"
                      value={this.state.testId}
                      onChange={(e) => {
                        this.setState({
                          testId: e,
                        });
                        let event = {
                          target: {
                            name: "testId",
                            value: e.value,
                          },
                        };
                        handleChange(event);
                      }}
                    />
                    <label style={{ color: Colors.Red }}>
                      {errors.testId && touched.testId && errors.testId}
                    </label>
                  </Col>
                  <Col lg={6} sm={12} xs={24} md={12}>
                    <label className="label-alignlf">Class Name</label>
                    <Select
                      className="userSelect"
                      name="eventClassId"
                      value={this.state.eventName}
                      onChange={(e) => {
                        this.setState({
                          eventName: e,
                        });
                        let event = {
                          target: {
                            name: "eventClassId",
                            value: e.value,
                          },
                        };
                        handleChange(event);
                      }}
                      options={classoptions}
                      styles={customStyles}
                    />
                    <label style={{ color: Colors.Red }}>
                      {errors.eventClassId &&
                        touched.eventClassId &&
                        errors.eventClassId}
                    </label>
                  </Col>{" "}
                  <Col lg={6} sm={12} xs={24} md={12}>
                    <label className="label-alignlf">Judge Name</label>
                    <Select
                      placeholder="Judge Name"
                      className="userSelect"
                      options={judgeOptions}
                      value={this.state.judgeName}
                      components={{ DropdownIndicator }}
                      onChange={(e) => {
                        this.setState({
                          judgeName: e,
                        });

                        let event = {
                          target: { name: "userId", value: e.value },
                        };
                        handleChange(event);
                      }}
                      onKeyDown={(e) => this.getJudge(e.target.value)}
                    />
                    <label style={{ color: Colors.Red }}>
                      {errors.userId && touched.userId && errors.userId}
                    </label>
                  </Col>
                  <Col lg={6} sm={12} xs={24} md={12}>
                    <label style={{ visibility: "hidden" }}>sasassaa</label>
                    <label style={{ visibility: "hidden" }}>sasassaa</label>

                    <AddBtnWithTitle
                      style={{ marginTop: "1rem" }}
                      htmlType="submit"
                      title="Add"
                      loading={isLoading}
                      icon={() => <BiPlusCircle />}
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        />

        <Row>
          <Col span={24}>
            <HorizontalLine width="85%" />
          </Col>
        </Row>
        <Row style={{ width: "85%", margin: "auto" }} align="middle">
          <Col span={24}>
            {(dressage || [])?.map((item, index) => (
              <DressageListing
                data={item}
                deleteItem={this.props.deleteDressage}
                isUpdate={isUpdate}
                editedIndex={index}
                editDressage={this.editDressage}
              />
            ))}
          </Col>
        </Row>
        <Row justify="center" align="bottom" style={{ marginBottom: "50px" }}>
          <Col xs={6} sm={6} md={4}>
            <Button
              disabled={dressage.length !== this.props.eventClass?.length||!dressage.length}
              onClick={() =>
                !isUpdate
                  ? this.props.history.push("/create-event/showjumping")
                  : this.props.history.push({
                      pathname: "/update/showjumping",
                      state: {
                        isUpdate,
                        eventDetail: this.props.location?.state?.eventDetail,
                      },
                    })
              }
            >
              Continue
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getEventClass: (eventId) => {
      dispatch(EventAction.getEventClassReq(eventId));
    },
    getTests: () => {
      dispatch(EventAction.getTestRequest());
    },
    searchJudge: (payload) => {
      dispatch(EventAction.searchJudgeRequest(payload));
    },
    createDressage: (payload, cb) => {
      dispatch(EventAction.createDressageRequest(payload, cb));
    },
    deleteDressage: (payload, cb) => {
      dispatch(EventAction.deleteDressage(payload, cb));
    },
    getDressage: (payload) => {
      dispatch(EventAction.getDressageReq(payload));
    },
    updateDressage: (payload, cb, callback) => {
      dispatch(EventAction.updateDressageReq(payload, cb, callback));
    },
  };
}
export default withRouter(
  connect(
    (storeState) => ({
      tests: storeState?.EventReducer?.tests,
      eventClass: storeState?.EventReducer?.eventClass,
      judge: storeState?.EventReducer.judge,
      dressage: storeState?.EventReducer.dressage,
      isLoading: storeState?.EventReducer.isLoading,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(Dressage)
);
