import React, { Component } from "react";
import { Row, Col } from "antd";
import ButtonForm from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";
import AddUserClass from "../../components/addUserClass/AddUserClass";
import Modal from "../../components/modal/modal";
import Loader from "../../components/loader";
import EventClassDescription from "../../components/eventClassDescription/EventClassDescription";
import QuestionDescription from "../../components/questionDescription/QuestionDescription";
import { connect } from "react-redux";
import { EventAction } from "../../store/actions";
import "./styles.css";
import AddBtnWithTitle from "../../components/addWithTittle";
import info from "../../components/info";
import { BiPlus } from "react-icons/bi";
import { Metrix, Images, Fonts, Colors } from "../../config";
import Select from "react-select";
import { Formik, Form } from "formik";
import {
  createEvent1Validations,
  createEvent2Validations,
  createEvent3Validations,
  createEvent4Validations,
} from "../../config/schemas";
import moment from "moment";
import DefaultTextArea from "../../components/input/TextArea";
class CreateEventContinue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      eventDate: moment().format("YYYY-MM-DD"),
      name: "",
      description: "",
      coverImage: "",
      className: "",
      eventId: null,
      question: "",
      eventClass: "",
      eventQuestion: "",
      userClassId: null,
      eventClassId: null,
      disabled: false,
      visible: false,
      isCreated: false,
      checkedIDs: [],
      filtered: [],
      arr: [],
      isChecked: false,
      selectedClass: "",
      selectedQuestion: "",
      changeinArr: [],
      editIndex: "",
      questionIndex: "",
      questionObjId: "",
      questionStateObj: {},
    };
  }
  changeDate = (dateMoment, dateString) => {
    this.setState({ eventDate: dateString });
  };

  changeImage = (e) => {
    this.setState({ coverImage: e.originFileObj });
  };
  componentDidMount() {
    const updateMode = this.props?.location?.state?.isUpdate;
    // if (!localStorage.getItem("eventId") && !updateMode) {
    //   this.setState({ eventId: localStorage.getItem("eventId") });
    // } else {
    //   this.props.history.push({
    //     pathname: "/create-event",
    //   });
    // }
    this.props.getUserClass();
    this.props.getEventClass({
      eventId: localStorage.getItem("eventId"),
    });
    this.props.getEventQuestion({
      eventClassId: localStorage.getItem("eventId"),
    });
  }
  onHandleChange = (e) => {
    this.setState({
      className: e.target.value,
    });
  };
  onSubmitModal = () => {
    let userId = JSON.parse(localStorage.getItem("eventAuth"));
    const filterClass = (this.props.userClass || []).filter((item) => {
      return item?.name?.toLowerCase() === this.state.className.toLowerCase();
    });
    if (filterClass?.length) {
      return info("error", "class name already exists");
    }
    //api
    this.props.createUserClass(
      { name: this.state.className, userId: userId.user.id },
      () => this.setState({ visible: false })
    );
  };
  onCancelModal = () => {
    this.setState({ visible: false });
  };
  // getEventClass = (value) => {
  //   const { searchEventClass } = this.props;
  //   searchEventClass(value);
  // };
  // deleteEventClassById = () => {
  //   const { deleteEventClass, eventClass } = this.props;
  //   deleteEventClass({ eventClassId: eventClass.id }, () =>
  //     this.setState({ isCreated: false })
  //   );
  // };
  handleChange = (e, isChecked) => {
    const { userClass } = this.props;
    this.state.selectedClass = "";
    this.state.eventClass = "";
    if (isChecked) {
      let itemIndex = this.state.checkedIDs.findIndex(
        (item) => item == e.target.value
      );
      if (itemIndex >= 0) this.state.checkedIDs.splice(itemIndex, 1);
      this.setState({ checkedIDs: this.state.checkedIDs });
    } else {
      this.state.checkedIDs.push(e.target.value);
      this.setState({ checkedIDs: this.state.checkedIDs });
    }
    const filtered = userClass.filter((item) => {
      return this.state.checkedIDs.includes(item.id);
    });
    this.setState({
      isChecked: Boolean(this.state.checkedIDs?.length),
      filtered,
    });
    if (this.props?.location?.state?.isUpdate) {
      const abc = userClass.find((item) => {
        return item.id === e.target.value;
      });
      this.setState({
        selectedClass: { label: abc.name, value: abc.id },
      });
    }
  };
  onHandleEdit = (index) => {
    this.setState({
      editIndex: index,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("PROPS:", nextProps.eventQuestion);
    let questionObj = {};
    let eventQuestions = nextProps.eventQuestion;
    for (let i in eventQuestions) {
      if (questionObj[eventQuestions[i]["eventClassId"]])
        questionObj[eventQuestions[i]["eventClassId"]].push(eventQuestions[i]);
      else questionObj[eventQuestions[i]["eventClassId"]] = [eventQuestions[i]];
    }
    this.setState({
      questionStateObj: questionObj,
    });
  }

  componentDidUpdate(preProps, preState) {
    if (this.props.eventQuestion !== preProps.eventQuestion) {
    }

    if (preProps.eventClass !== this.props.eventClass) {
      this.setState({
        changeinArr: [],
      });
    }
    if (preState.editIndex !== this.state.editIndex) {
      let event = {
        target: {
          name: "description",
          value: this.props?.eventClass?.[this.state.editIndex]?.description,
        },
      };
      let e = {
        target: {
          name: "price",
          value: this.props?.eventClass?.[this.state.editIndex]?.price,
        },
      };
      let ev = {
        target: {
          name: "slots",
          value: this.props?.eventClass?.[this.state.editIndex]?.slots,
        },
      };

      let userClas = {
        target: {
          name: "userClassId",
          value: this.props?.eventClass?.[this.state.editIndex]?.userClassId,
        },
      };

      this.formicHandleChangeRef(event);
      this.formicHandleChangeRef(e);
      this.formicHandleChangeRef(ev);
      this.formicHandleChangeRef(userClas);
      this.setState({
        selectedClass: {
          label:
            this.props?.eventClass?.[this.state.editIndex]?.UserClass?.name,
          value: this.props?.eventClass?.[this.state.editIndex]?.userClassId,
        },
      });
    }
    if (preState.questionIndex !== this.state.questionIndex) {
      this.setState({
        selectedQuestion: {
          label:
            this.state.questionStateObj?.[this.state.questionObjId]?.[
              this.state.questionIndex
            ]?.EventClass?.UserClass.name,
          value:
            this.state.questionStateObj?.[this.state.questionObjId]?.[
              this.state.questionIndex
            ]?.EventClass?.id,
        },
      });
      let event = {
        target: {
          name: "question",
          value:
            this.state.questionStateObj?.[this.state.questionObjId]?.[
              this.state.questionIndex
            ].question,
        },
      };
      let e = {
        target: {
          name: "eventClassId",
          value:
            this.state.questionStateObj?.[this.state.questionObjId]?.[
              this.state.questionIndex
            ]?.EventClass?.id,
        },
      };
      this.questionFormicRef(event);
      this.questionFormicRef(e);
    }
  }
  onEditQuestion = (index, id) => {
    this.setState({
      questionIndex: index,
      questionObjId: id,
    });
  };
  render() {
    const {
      createEventClass,
      createEventQuestion,
      getUserClass,
      updateEventClass,
      userClass,
      eventClass,
      eventQuestion,
      userClassLoading,
      loader,
      updateEventQuestion,
    } = this.props;
    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma('lightblue');
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "lightblue"
            : isFocused
            ? "#eaeaea"
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? "#eaeaea"
              ? "black"
              : "black"
            : "black",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? "lightblue" : "#eaeaea"),
          },
        };
      },
      input: (styles) => ({ ...styles }),
      placeholder: (styles) => ({ ...styles }),
      singleValue: (styles, { data }) => ({ ...styles }),
    };

    let res = [];
    res = (this.state.filtered || []).filter((el) => {
      return !(this.props.eventClass || []).find((element) => {
        return element.userClassId === el.id;
      });
    });
    const filterOption = (res || []).map((item) => {
      return { label: item.name, value: item.id };
    });
    const editOption = [this.state.selectedClass];
    const questionEditOpt = [this.state.selectedQuestion];
    const eventClassOption = (this.props.eventClass || []).map((item) => {
      return { label: item?.UserClass?.name, value: item.id };
    });
    const eventId = localStorage.getItem("eventId");
    const isUpdate = this.props?.history?.location?.state?.isUpdate;
    console.log(eventClass, "eventClass  ");

    return (
      <div>
        <Row justify="start" align="middle">
          <Col xs={24} sm={10} md={8} lg={6} xl={8} align="middle">
            <h1 style={{ fontWeight: "700", fontSize: "35px" }}>
              {isUpdate ? "Update Event" : "Create Event"}
            </h1>
          </Col>
        </Row>

        <Row justify="center" gutter={[0, 20]}>
          <Col xs={22} sm={20} md={19} lg={16} xl={15}>
            <Formik
              initialValues={{
                name: "",
              }}
              validationSchema={createEvent2Validations}
              onSubmit={(values) => {
                // createUserClass(values, () =>{});
                this.setState({ show2: true, className: "" });
              }}
              render={({ values, touched, handleChange, errors, set }) => (
                <Form>
                  <Row gutter={[30, 40]} style={{ marginTop: "2rem" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} align="start">
                      <h4>Event Classes</h4>
                      <Row gutter={[20, 20]}>
                        {loader ? (
                          <Loader />
                        ) : (
                          (userClass || []).map((item) => {
                            return (
                              <Col xs={12} sm={8} md={6} lg={6} xl={5}>
                                <AddUserClass
                                  onChange={(e) =>
                                    this.handleChange(
                                      e,
                                      this.state.checkedIDs.includes(item.id)
                                    )
                                  }
                                  isChecked={this.state.checkedIDs.includes(
                                    item.id
                                  )}
                                  item={item}
                                />
                              </Col>
                            );
                          })
                        )}
                        <Col xs={24} sm={9} md={7} lg={8} xl={6}>
                          <AddBtnWithTitle
                            icon={() => <BiPlus />}
                            title="Add User Class"
                            width="100%"
                            onClick={() => this.setState({ visible: true })}
                          />
                          <label style={{ color: Colors.Red }}>
                            {errors.name && touched.name && errors.name}
                          </label>
                          <Modal
                            onChange={this.onHandleChange}
                            title="Add User Class"
                            lable="Class Name"
                            confirmloading={userClassLoading}
                            visible={this.state.visible}
                            closable={true}
                            maskClosable={true}
                            className={this.state.className}
                            onSubmitModal={this.onSubmitModal}
                            onCancelModal={this.onCancelModal}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
            />
          </Col>
        </Row>

        {(this.state.isChecked || eventClass?.length !== 0) && (
          <Row justify="center" gutter={[0, 20]} style={{ marginTop: "2rem" }}>
            <Col xs={22} sm={20} md={19} lg={16} xl={15}>
              <Row gutter={[0, 20]}>
                <h4>Event Class Description</h4>

                <Col span={24}>
                  <Formik
                    initialValues={{
                      description: "",
                      price: "",
                      userClassId: "",
                      eventId,
                      slots: "",
                    }}
                    validationSchema={createEvent3Validations}
                    onSubmit={(values, { resetForm }) => {
                      const val = {
                        ...values,
                        eventId,
                      };
                      console.log(val);
                      const findSame = eventClass.find((item) => {
                        return item.userClassId == values.userClassId;
                      });
                      if (findSame && !this.state.editIndex?.toString()) {
                        info("error", "Event Class Already exsist!");
                        return;
                      }
                      console.log(findSame, "findSame");
                      !this.state.editIndex?.toString() &&
                        createEventClass(val, () =>{
                          this.setState({ show3: true, selectedClass: "" })

                          resetForm({ values: "" });
                        }
                        );
                      this.state.editIndex?.toString() &&
                        updateEventClass(
                          val,
                          this.props?.eventClass?.[this.state.editIndex]?.id,
                          () => {
                            this.setState({ selectedClass: "", editIndex: "" });
                            resetForm({ values: "" });
                          }
                        );
                    }}
                    render={({
                      values,
                      touched,
                      handleChange,
                      errors,
                      resetForm,
                    }) => {
                      this.formicHandleChangeRef = handleChange;
                      return (
                        <Form>
                          <Row gutter={[30, 40]}>
                            <Col md={24} lg={24} xl={24} align="start">
                              <Row gutter={[10, 0]}>
                                <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                                  <p>Class Prices</p>
                                  <DefaultInput
                                    value={values.price}
                                    type="number"
                                    name="price"
                                    onChange={handleChange}
                                    style={{ borderColor: "#00A99D" }}
                                  />
                                  <label style={{ color: Colors.Red }}>
                                    {errors.price &&
                                      touched.price &&
                                      errors.price}
                                  </label>
                                </Col>

                                <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                                  <p>Class</p>

                                  <Select
                                    placeholder="Class Name"
                                    name="userClassId"
                                    value={this.state.selectedClass}
                                    onChange={(e) => {
                                      this.setState({
                                        eventClass: e.value,
                                        selectedClass: e,
                                      });
                                      let event = {
                                        target: {
                                          name: "userClassId",
                                          value: e.value,
                                        },
                                      };
                                      handleChange(event);
                                    }}
                                    options={
                                      filterOption?.length
                                        ? filterOption
                                        : editOption
                                    }
                                    styles={colourStyles}
                                    // onKeyDown={(e) => this.getEventDescription(e.target.value)}
                                  />
                                  <label style={{ color: Colors.Red }}>
                                    {errors.userClassId &&
                                      touched.userClassId &&
                                      errors.userClassId}
                                  </label>
                                </Col>
                                <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                                  <p>Slots</p>
                                  <DefaultInput
                                    value={values.slots}
                                    type="number"
                                    name="slots"
                                    onChange={handleChange}
                                    style={{ borderColor: "#00A99D" }}
                                  />
                                  <label style={{ color: Colors.Red }}>
                                    {errors.price &&
                                      touched.price &&
                                      errors.price}
                                  </label>
                                </Col>
                                <Col xs={24} sm={12} md={5} lg={5} xl={2}></Col>
                                <Col
                                  xs={24}
                                  sm={12}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  style={{ marginTop: "2.3rem" }}
                                >
                                  <AddBtnWithTitle
                                    style={{ width: "100%" }}
                                    icon={() => <BiPlus />}
                                    title={
                                      !isUpdate ? "Add Class" : "Update Class"
                                    }
                                    htmlType="submit"
                                    loading={this.props.eventClassLoading}
                                  />
                                </Col>
                                <Col xs={24} sm={12} md={24} lg={24} xl={21}>
                                  <p>Event Class Description</p>
                                  <DefaultTextArea
                                    value={values.description}
                                    name="description"
                                    onChange={handleChange}
                                    style={{ borderColor: "#00A99D" }}
                                  />
                                  <label style={{ color: Colors.Red }}>
                                    {errors.description &&
                                      touched.description &&
                                      errors.description}
                                  </label>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {(this.state.isChecked || eventClass?.length !== 0) && (
          <Row justify="center" gutter={[0, 10]} style={{ marginTop: "5px" }}>
            <Col xs={22} sm={20} md={19} lg={16} xl={15}>
              <Row gutter={[0, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Formik
                    initialValues={{
                      question: "",
                      eventClassId: null,
                    }}
                    validationSchema={createEvent4Validations}
                    onSubmit={(values, { resetForm }) => {
                      let eventClassId = localStorage.getItem("eventClassId");
                      // const val = {
                      //   ...values,
                      //   eventClassId:eventClassId
                      // };
                      !this.state.questionIndex?.toString() &&
                        createEventQuestion(
                          { ...values, eventClassId: this.state.eventQuestion },
                          () =>
                            this.setState({ show4: true, selectedQuestion: "" })
                        );

                      this.state.questionIndex?.toString() &&
                        updateEventQuestion(
                          {
                            ...values,
                            questionId:
                              this.state.questionStateObj?.[
                                this.state.questionObjId
                              ]?.[this.state.questionIndex]?.id,
                          },
                          this.props?.location?.state?.id,
                          () =>
                            this.setState({
                              questionIndex: "",
                              questionObjId: "",
                            })
                        );
                      resetForm({ values: "" });
                    }}
                    render={({ values, touched, handleChange, errors }) => {
                      this.questionFormicRef = handleChange;
                      return (
                        <Form>
                          <Row gutter={[30, 40]}>
                            <Col sm={24} md={24} lg={24} xl={24} align="start">
                              <Row gutter={[10, 30]}>
                                <Col xs={24} sm={7} md={10} lg={9} xl={10}>
                                  <p>Add Questions</p>
                                  <DefaultInput
                                    name="question"
                                    onChange={handleChange}
                                    value={values.question}
                                    style={{ borderColor: "#00A99D" }}
                                    maxLength={250}
                                  />
                                  <label style={{ color: Colors.Red }}>
                                    {errors.question &&
                                      touched.question &&
                                      errors.question}
                                  </label>
                                </Col>
                                <Col xs={24} sm={7} md={6} lg={6} xl={6}>
                                  <p>Class</p>
                                  <Select
                                    placeholder="Class Name"
                                    // value={filterOption}
                                    onChange={(e) => {
                                      this.setState({
                                        eventQuestion: e.value,
                                        selectedQuestion: e,
                                      });
                                      let event = {
                                        target: {
                                          value: e.value,
                                          name: "eventClassId",
                                        },
                                      };
                                      handleChange(event);
                                    }}
                                    value={this.state.selectedQuestion}
                                    options={eventClassOption}
                                    styles={colourStyles}
                                    // onKeyDown={(e) => this.getEventQuestion(e.target.value)}
                                  />
                                  <label style={{ color: Colors.Red }}>
                                    {errors.eventName &&
                                      touched.eventName &&
                                      errors.eventName}
                                  </label>
                                </Col>

                                {
                                  eventClass?.length ? (
                                    <Col
                                      xs={24}
                                      sm={10}
                                      md={8}
                                      lg={7}
                                      xl={5}
                                      style={{ marginTop: "2.3rem" }}
                                    >
                                      <AddBtnWithTitle
                                        style={{ width: "100%" }}
                                        icon={() => <BiPlus />}
                                        title={
                                          isUpdate
                                            ? "Update Question"
                                            : "Addional Question"
                                        }
                                        htmlType="submit"
                                        loading={this.props.questionLoading}
                                      />
                                    </Col>
                                  ) : null
                                  // info("error", "Question can`t be more than")
                                }
                                <Col span={24}>
                                  <h2>Event Class Description</h2>

                                  {loader ? (
                                    <Loader />
                                  ) : (
                                    (eventClass || []).map((item, index) => {
                                      return (
                                        <div>
                                          <EventClassDescription
                                            isUpdate={isUpdate}
                                            item={item}
                                            loading={loader}
                                            editedIndex={index}
                                            onEdit={this.onHandleEdit}
                                          />
                                          <>
                                            {this.state.questionStateObj[
                                              item.id
                                            ]?.map((v, i) => {
                                              return (
                                                <QuestionDescription
                                                  item={v}
                                                  questionIndex={i}
                                                  onEditQuestion={
                                                    this.onEditQuestion
                                                  }
                                                  isUpdate={isUpdate}
                                                  id={item.id}
                                                />
                                              );
                                            })}
                                          </>
                                          <hr />
                                        </div>
                                      );
                                    })
                                  )}
                                </Col>
                                {eventClass?.length ? (
                                  <Col
                                    span={24}
                                    style={{ marginBottom: "50px" }}
                                  >
                                    <Row justify="center">
                                      <Col
                                        xs={14}
                                        sm={12}
                                        md={10}
                                        lg={8}
                                        xl={8}
                                      >
                                        <ButtonForm
                                          // htmlType="submit"

                                          onClick={() => {
                                            if (isUpdate) {
                                              eventClass?.length &&
                                                this.props.history.push({
                                                  pathname: "/update/dressage",
                                                  state: {
                                                    isUpdate,
                                                    eventDetail:
                                                      this.props?.history
                                                        ?.location?.state,
                                                  },
                                                });
                                              return;
                                            }
                                            eventClass?.length &&
                                              this.props.history.push(
                                                "/create-event/dressage"
                                              );
                                          }}
                                        >
                                          Save
                                        </ButtonForm>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

function mapStateToProps(storeState) {
  return {
    userClass: storeState?.EventReducer?.userClass,
    eventClass: storeState?.EventReducer.eventClass,
    eventQuestion: storeState?.EventReducer.eventQuestion,
    loader: storeState?.EventReducer.isLoading,
    questionLoading: storeState?.EventReducer.createQuestionLoading,
    eventClassLoading: storeState?.EventReducer.createEventClassLoading,
    userClassLoading: storeState?.EventReducer.createUserClassLoading,
    loadingQuestion: storeState?.EventReducer?.loadingQuestion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // signup: (payload, cb) => dispatch(AuthAction.SignUp(payload, cb)),
    createEvent: (payload, cb) =>
      dispatch(EventAction.createEventRequest(payload, cb)),
    createUserClass: (payload, cb) =>
      dispatch(EventAction.createUserClassReq(payload, cb)),
    getUserClass: () => dispatch(EventAction.getClassRequest()),
    createEventClass: (payload, cb) =>
      dispatch(EventAction.createEventClassReq(payload, cb)),

    getEventClass: (payload, cb) =>
      dispatch(EventAction.getEventClassReq(payload)),

    deleteEventClass: (payload, cb) =>
      dispatch(EventAction.deleteEventClass(payload, cb)),

    getEventQuestion: (payload) =>
      dispatch(EventAction.getEventQuestionReq(payload)),
    deleteEventQuestion: (payload, cb) =>
      dispatch(EventAction.deleteEventQuestion(payload, cb)),

    createEventQuestion: (payload, cb) =>
      dispatch(EventAction.createEventQuestionReq(payload, cb)),
    updateEventClass: (payload, cb, callback) =>
      dispatch(EventAction.updateEventClassReq(payload, cb, callback)),
    updateEventQuestion: (payload, cb, callBack) =>
      dispatch(EventAction.updateEventQuestionReq(payload, cb, callBack)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventContinue);
