import React from "react";
import { Row, Col } from "antd";
import { BsTrash } from "react-icons/bs";
import { EventAction } from "../../store/actions";
import { useDispatch } from "react-redux";
import { FaRegEdit } from "react-icons/fa";

const EventClassDescription = (props) => {
  const dispatch = useDispatch();
  const { item, isUpdate, onEdit, editedIndex } = props;
  const deleteFunction = () => {
    dispatch(EventAction.deleteEventClass(item.id));
  };
  return (
    <Row
      justify="space-between"
      style={{
        borderBottom: "#F1F1F1 2px solid",
        height: "2.5rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        height: "auto",
        padding: "10px 0 5px 0",
      }}
    >
      <Col span={10}>
        <Row justify="start" style={{ wordWrap: "break-word" }}>
          <Col span={12}>
            <h3 className="text-capitilize">{item.UserClass.name}</h3>{" "}
          </Col>

          <Col
            span={22}
            align="start"
            style={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            {item.description}
          </Col>
          <Col span={2}>£{item.price}</Col>
        </Row>
      </Col>
      <Col span={14}>
        <Row justify="space-between">
          <Col span={10}></Col>
          <Col
            xs={7}
            sm={7}
            md={4}
            style={{
              marginTop: "6px",
            }}
          >
            <Row
              // onClick={!isUpdate ? deleteFunction :()=> alert("update")}
              style={{ cursor: "pointer" }}
            >
              <Col onClick={() => onEdit(editedIndex)} span={6}>
                {isUpdate && <FaRegEdit color="#00A99D" />}
              </Col>
              <Col span={6} onClick={deleteFunction}>
                <BsTrash color="#00A99D" />
              </Col>
              <Col
                onClick={deleteFunction}
                span={2}
                style={{ color: "#00A99D" }}
              >
                Delete
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EventClassDescription;
