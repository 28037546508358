import React, { Component } from "react";
import { Row, Col, Spin } from "antd";
import { connect } from "react-redux";
import Card from "../../components/eventCard";
import "./dashboard.css";
import EventAction from "../../store/actions/EventAction";
import { LoadingOutlined } from "@ant-design/icons";
import Notification from "../../assets/images/icons/notification.svg";
import SearchBar from "../../components/input/Search";
import { UserAction } from "../../store/actions";
import ButtonForm from "../../components/button/Button";

import CustomModal from "../../components/modals/customModal";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      offset: 0,
      limit: 1000,
      like: "",
      isVisible: false,
    };
  }
  componentDidMount() {
    const { getAllEvents, checkAccount, location } = this.props;
    const { offset, limit, like } = this.state;
    getAllEvents({ offset, limit, like });
    localStorage.removeItem("eventId");
    localStorage.removeItem("eventClassId");
    localStorage.removeItem("countryId");
    localStorage.removeItem("showJumpingId");
    checkAccount({ data: "true" });
  }
  onHandleSearch = (searchTerm) => {
    const { getAllEvents } = this.props;

    getAllEvents({ ...this.state, like: searchTerm });
  };
  handleChanged = (e) => {
    this.setState({
      like: e.target.value,
    });
    this.props.getAllEvents({ ...this.state, like: e.target.value });
  };

  closeModal = () => {
    this.setState({
      isVisible: false,
    });
  };
  closeVisible = () => {
    this.setState({
      isVisible: true,
    });
  };
  componentDidUpdate(preProps) {
    if (preProps.accountData !== this.props.accountData) {
      if (
        this.props?.location?.state &&
        (this.props?.accountData?.status === "pending" ||
          this.props?.accountData?.status === "inactive")
      ) {
        this.setState({
          isVisible: true,
        });
      }
    }
  }
  render() {
    const { events, isEventLoading } = this.props;
    

    return (
      <Row gutter={[0, 30]} align="top" justify="start" className="card-row">
        <Col span={24}>
          <Row justify="space-between">
            <Col lg={8} xl={8} sm={24} md={12} xs={24}>
              <h1>Home</h1>
            </Col>
            <Col lg={8} xl={8} sm={24} md={12} xs={24}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ marginRight: "17px" }}>
                  <SearchBar
                    handleChanged={this.handleChanged}
                    value={this.state.like}
                    onSearch={this.onHandleSearch}
                  />
                </div>
                <div
                  onClick={() => this.props.history.push("/notification")}
                  className="pointer-cursor"
                >
                  {" "}
                  <img src={Notification} height={"40px"} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        {isEventLoading ? (
          <LoadingOutlined style={{ fontSize: 24, margin: "auto" }} spin />
        ) : (
          <Col span={24}>
            <Row gutter={[20, 30]}>
              {!events.length && <h1 className="m-auto">No Event Found!</h1>}
              {events.map((item) => {
                return (
                  <Col
                    lg={6}
                    md={8}
                    sm={12}
                    xs={24}
                    xl={6}
                    className="pointer-cursor"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `/my-event/${item.id}`,
                        state: { home: true },
                      })
                    }
                  >
                    <Card item={item} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
        <CustomModal
          visible={this.state.isVisible}
          onCancelModal={this.closeModal}
        >
          <Row justify="center" gutter={[0, 20]}>
            <Col span={24}></Col>
            <Col span={24}>
              <h1 className="text-center">Connect account</h1>
              <p className="text-center">
                {" "}
                Link your account to stripe to create event{" "}
              </p>
            </Col>
            <Col span={8} style={{ marginBottom: "10px" }}>
              <ButtonForm onClick={()=>this.props.checkLink()}>Yes</ButtonForm>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={8}>
              <ButtonForm color="#C5C3C3" onClick={() => this.closeModal()}>
                No
              </ButtonForm>
            </Col>
          </Row>{" "}
        </CustomModal>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.EventReducer?.event?.rows || [],
    isEventLoading: state.EventReducer?.isEventLoading,
    accountData: state.UserReducer.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllEvents: (parmas) => dispatch(EventAction.getEventReq(parmas)),
    checkAccount: (data) => dispatch(UserAction.checkAccountReq(data)),
    checkLink:()=>dispatch(UserAction.createLinkReq())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
