import React, { Component } from "react";
import EventHeading from "../../components/eventsHeading";
import { connect } from "react-redux";
import { UserAction } from "../../store/actions";
import { Row, Col } from "antd";
class Notification extends Component {
  constructor() {
    super();
    this.state = {
      offset: 0,
      limit: 1000,
      like: "",
    };
  }
  componentDidMount() {
    this.props.getNotification({ ...this.state });
  }
  render() {
    return (
      <Row style={{ height: "auto" }} gutter={[0, 30]}>
        <Col span={24}>
          <EventHeading title="Notification" />
        </Col>
        <Col
          span={20}
          className="m-auto"
          style={{
            boxShadow: "0px 1px 26px lightgrey",
            borderRadius: "8px",
            padding: "20px 10px 10px 10px",
          }}
        >
          {(this.props.notification || []).map((item) => {
            return (
              <div onClick={()=>{item.title !== 'Event Deleted' && item.eventId !==null ? this.props.history.push(`my-event/${item.eventId}`) : console.log("NULL")}}>
                <Row justify="start" className="setting-padding" style={{cursor: item.title !== 'Event Deleted' && item.eventId !==null ? "pointer" : "auto"}}>
                  <Col lg={24} xs={24} sm={24} md={24}>
                    <p className="text-left setting-font">{item.body}</p>
                  </Col>
                </Row>
                <hr className="setting-hr" style={{width:'100%'}} />
              </div>
            );
          })}
        </Col>
      </Row>
    );
  }
}
function mapStateToProps(store) {
  return {
    notification: store.UserReducer.notification,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getNotification: (parmas) =>
      dispatch(UserAction.getNotificationReq(parmas)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
