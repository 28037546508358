import React from 'react';
import styled, { css } from "styled-components";
import { Input } from 'antd';

const StyledInput = styled(Input).attrs({className: 'inputField'})`
    border-radius:5px;
    height:2.5rem;
`;

const DefaultInput = (props) => {
  return (
    props.password ? (
      <StyledInput as={Input.Password} {...props}></StyledInput>
    ) : (
        <StyledInput {...props}></StyledInput>
      )
  );
}

export default DefaultInput;