import React, { Component } from "react";
import { Row, Col } from "antd";
import DefaultInput from "../../components/input/Input";
import ButtonForm from "../../components/button/Button";
import EventHeading from "../../components/eventsHeading";
import { connect } from "react-redux";
import { AuthAction } from "../../store/actions";
import {PASSWORD_REGX} from '../../config/variables'
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      password: "",
      isLoading: false,
      oldPasswordError: "",
      passwordError: "",
    };
  }
  onHandleChange = (e) => {
    // console.log(e.target.name, "eee==>");
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: "",
    });
  };
  onHandleSubmit = () => {
    const { oldPassword, password } = this.state;
  
    if (!oldPassword || !PASSWORD_REGX.test(password)) {
      if (!oldPassword) {
        this.setState({
          oldPasswordError: "Field can not be empty",
        });
      }
      if (!PASSWORD_REGX.test(password)) {
        this.setState({
          passwordError: "Password must contain at least eight characters,one uppercase letter,one number and one symbol",
        });
      }
      return;
    }
    this.setState({ isLoading: true });
    this.props.updatePassword(
      {
        oldPassword: oldPassword,
        password: password,
      },
      () => {
        this.setState({
          isLoading: false,
        });
      },
      () => {
        this.props.history.push("/settings");
      }
    );
  };
  render() {

    return (
      <Row justify="center">
        <Col span={24} sm={24} md={24} xs={24} lg={24}>
          <EventHeading
            goBack={() => this.props.history.push("/settings")}
            arrowBtn={true}
            title={"Change Password"}
          />
        </Col>
        <Col span={12}>
          <Row justify="center" gutter={[10, 20]}>
            <Col xs={20} sm={12} md={12} lg={12} xl={13} align="start">
              <p>Current Password</p>
              <DefaultInput
                name="oldPassword"
                password={true}
                onChange={this.onHandleChange}
                // value={values.name}
                style={{ borderColor: "#00A99D" }}
              />{" "}
              <label style={{ color: "red" }}>
                {this.state.oldPasswordError && this.state.oldPasswordError}
              </label>
            </Col>

            <Col xs={20} sm={12} md={12} lg={12} xl={13} align="start">
              <p>New Password</p>
              <DefaultInput
                name="password"
                password={true}
                onChange={this.onHandleChange}
                // value={values.name}
                style={{ borderColor: "#00A99D" }}
              />{" "}
              <label style={{ color: "red" }}>
                {this.state.passwordError && this.state.passwordError}
              </label>
            </Col>

            <Col xs={20} sm={14} md={12} lg={10} xl={13}>
              <ButtonForm
                htmlType="submit"
                onClick={() => this.onHandleSubmit()}
                loading={this.state.isLoading}
                // onClick={}
              >
                Continue
              </ButtonForm>
            </Col>
            {/* <Col xs={20} sm={12} md={12} lg={12} xl={12} align="start">
              <p>Event Name</p>
              <DefaultInput
                name="name"
                // onChange={handleChange}
                // value={values.name}
                style={{ borderColor: "#00A99D" }}
              />{" "}
            </Col> */}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default connect(null, (dispatch) => {
  return {
    updatePassword: (payload, cb, push) =>
      dispatch(AuthAction.changePasswordReq(payload, cb, push)),
  };
})(ChangePassword);
