import * as Yup from "yup";

export const socialSignupSchema = Yup.object().shape({
  age: Yup.date(),
  companyName: Yup.string()
    // .min(2, "Full Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Full Name is Too Long! it must be between 2 to 50 characters")

    // .matches(/^[a-z0-9.\s]+$/i, "Only alphabets digits and periods are allowed")

    .required("Company name required"),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "Must accept terms and conditions"
  ),
});
