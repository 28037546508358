import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyC0YOSBLSF6tVnYDLx_2jzSz4yV-tAP2pc",
    authDomain: "event-pluz.firebaseapp.com",
    projectId: "event-pluz",
    storageBucket: "event-pluz.appspot.com",
    messagingSenderId: "255409926246",
    appId: "1:255409926246:web:769b5981ebffbad40cfda7",
    measurementId: "G-EB6P1N1SZ2",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;