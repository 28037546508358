import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import { openModal } from "../../actions/Modal";
//import { setHistory, redirect } from "../../actions/App";
//import { ThemeContext } from "../../configurations/Theme";
import Dashboard from "../../containers/Dashboard";
import CreateEvent from "../../containers/createEvents";
import CreateEventContinue from "../../containers/createEvents/CreateEventContinue";
import Navbar from "../../components/navbar";
import Dressage from "../../containers/dressage";
import ShowJumping from "../../containers/showjumping";
import CrossCountry from "../../containers/crossCountry";
import BG_IMAGE from "../../assets/images/createEventBackground.jpg";
import MyEvent from "../../containers/MyEvent";
import ViewEvent from "../../containers/viewEvent";
import UpdateEvent from "../../containers/createEvents";
import RiderDetail from "../../containers/RiderDetail";
import Settings from "../../components/settings";
import EditProfile from "../../containers/editProfile";
import ManageClass from "../../containers/ManageClass";
import Leaderboard from "../../containers/leaderboard";
import Notification from "../../containers/Notification";
import Subscription from "../../containers/Subscription";
import Payment_form from "../../containers/payment";
import Upgrade_payment from "../../containers/upgrade_payment";
import { UserAction } from "../../store/actions";
import ChangePassword from "../../containers/changePassword";
class AuthRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedRight: true,
      collapsedLeft: false,
      ScreenWidth: window.innerWidth,
    };

    this.handleWidthChange = this.handleWidthChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      //this.props.redirect(this.props.location.pathname);
    }
    window.addEventListener("resize", this.handleWidthChange);
  }

  componentDidMount() {
    //this.props.setHistory(this.props.history);
    //this.props.redirect(this.props.location.pathname);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthChange);
  }
  handleWidthChange = () => {
    this.setState({
      ScreenWidth: window.innerWidth,
    });
  };
  toggleCollapsed = (type) => {
    this.setState({
      [type]: !this.state[type],
    });
  };

  render() {
    if (this.props.location.pathname === "/reauth") {
      // console.log(this.props, "pathname==>");
      this.props.createLink();
    }
    if (this.props.location.pathname === "/checkaccount") {
      console.log(this.props, "pathname==>");
      this.props.checkAccount({ flag: this.props.location.search });
    }
    return (
      <div
        className="App"
        style={{
          background: `url(${BG_IMAGE}) `,
          backgroundSize: " 100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <Navbar />

        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/create-event" component={CreateEvent} />
          <Route exact path="/subscription" component={Subscription} />

          <Route
            exact
            path="/create-event-continue"
            component={CreateEventContinue}
          />
          <Route exact path="/create-event/dressage" component={Dressage} />
          <Route
            exact
            path="/create-event/crosscountry"
            component={CrossCountry}
          />
          {/* <Route exact path="/settings" component={Settings} /> */}
          <Route
            exact
            path="/create-event/showjumping"
            component={ShowJumping}
          />
          <Route exact path="/my-event" component={MyEvent} />
          <Route exact path="/my-event/:id" component={ViewEvent} />
          <Route exact path="/update-event" component={UpdateEvent} />
          <Route
            path="/update-event-continute"
            component={CreateEventContinue}
          />
          <Route exact path="/update/dressage" component={Dressage} />
          <Route exact path="/update/showjumping" component={ShowJumping} />
          <Route exact path="/update/crosscountry" component={CrossCountry} />
          <Route exact path="/view-event/riderDetail" component={RiderDetail} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/edit-profile" component={EditProfile} />
          <Route exact path="/manage-class" component={ManageClass} />
          <Route exact path="/leaderboard" component={Leaderboard} />
          <Route exact path="/notification" component={Notification} />
          <Route exact path="/payment" component={Payment_form} />
          <Route path="/upgrade_payment" component={Upgrade_payment} />
          <Route path="/change-password" component={ChangePassword} />

          <Route exact path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

//Shell.contextType = ThemeContext;

export default connect(
  (state) => ({ storeState: state }),
  (dispatch) => ({
    createLink: () => dispatch(UserAction.createLinkReq()),
    checkAccount: (data) => dispatch(UserAction.checkAccountReq(data)),
  })
)(AuthRoutes);
