export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_FAILURE = "SIGNOUT_FAILURE";

export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

export const VERIFICATION_CODE = "VERIFICATION_CODE";
export const VERIFICATION_CODE_SUCCESS = " VERIFICATION_CODE_SUCCESS";
export const VERIFICATION_CODE_FAILURE = " VERIFICATION_CODE_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = " RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = " RESET_PASSWORD_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = " FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = " FORGOT_PASSWORD_FAILURE";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_ALL_MODAL = "CLOSE_ALL_MODAL";

export const CREATE_EVENT_REQ = "CREATE_EVENT_REQ";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";

export const CREATE_USER_CLASS_REQ = "CREATE_USER_CLASS_REQ";
export const CREATE_USER_CLASS_SUCCESS = "CREATE_USER_CLASS_SUCCESS";
export const CREATE_USER_CLASS_ERROR = "CREATE_USER_CLASS_ERROR";

export const CREATE_EVENT_QUESTION_REQ = "CREATE_EVENT_QUESTION_REQ";
export const CREATE_EVENT_QUESTION_SUCCESS = "CREATE_EVENT_QUESTION_SUCCESS";
export const CREATE_EVENT_QUESTION_ERROR = "CREATE_EVENT_QUESTION_ERROR";

export const CREATE_DRESSAGE_REQ = "CREATE_DRESSAGE_REQ";
export const CREATE_DRESSAGE_SUCCESS = "CREATE_DRESSAGE_SUCCESS";
export const CREATE_DRESSAGE_ERROR = "CREATE_DRESSAGE_ERROR";

export const CREATE_EVENT_CLASS_REQ = "CREATE_EVENT_CLASS_REQ";
export const CREATE_EVENT_CLASS_SUCCESS = "CREATE_EVENT_CLASS_SUCCESS";
export const CREATE_EVENT_CLASS_ERROR = "CREATE_EVENT_CLASS_ERROR";

export const CREATE_SHOW_JUMPING_REQ = "CREATE_SHOW_JUMPING_REQ";
export const CREATE_SHOW_JUMPING_SUCCESS = "CREATE_SHOW_JUMPING_SUCCESS";
export const CREATE_SHOW_JUMPING_ERROR = "CREATE_SHOW_JUMPING_ERROR";

export const CREATE_CROSS_COUNTRY_REQ = "CREATE_CROSS_COUNTRY_REQ";
export const CREATE_CROSS_COUNTRY_SUCCESS = "CREATE_CROSS_COUNTRY_SUCCESS";
export const CREATE_CROSS_COUNTRY_ERROR = "CREATE_CROSS_COUNTRY_ERROR";

export const CREATE_CROSS_COUNTRY_FENCE_REQ = "CREATE_CROSS_COUNTRY_FENCE_REQ";
export const CREATE_CROSS_COUNTRY_FENCE_SUCCESS =
  "CREATE_CROSS_COUNTRY_FENCE_SUCCESS";
export const CREATE_CROSS_COUNTRY_FENCE_ERROR =
  "CREATE_CROSS_COUNTRY_FENCE_ERROR";

export const GET_CLASS_REQ = "GET_CLASS_REQ";
export const GET_CLASS_SUCCESS = "GET_CLASS_SUCCESS";
export const GET_CLASS_ERROR = "GET_CLASS_ERROR";

export const GET_TEST_REQ = "GET_TEST_REQ";
export const GET_TEST_SUCCESS = "GET_TEST_SUCCESS";
export const GET_TEST_ERROR = "GET_TEST_ERROR";

export const SEARCH_JUDGE_REQ = "SEARCH_JUDGE_REQ";
export const SEARCH_JUDGE_SUCCESS = "SEARCH_JUDGE_SUCCESS";
export const SEARCH_JUDGE_ERROR = "SEARCH_JUDGE_ERROR";

export const GET_EVENT_CLASS_REQ = "GET_EVENT_CLASS_REQ";
export const GET_EVENT_CLASS_SUCCESS = "GET_EVENT_CLASS_SUCCESS";
export const GET_EVENT_CLASS_ERROR = "GET_EVENT_CLASS_ERROR";

export const GET_EVENT_QUESTION_REQ = "GET_EVENT_QUESTION_REQ";
export const GET_EVENT_QUESTION_SUCCESS = "GET_EVENT_QUESTION_SUCCESS";
export const GET_EVENT_QUESTION_ERROR = "GET_EVENT_QUESTION_ERROR";

export const GET_EVENT_CLASS_DETAILS_REQ = "GET_EVENT_CLASS_DETAILS_REQ";
export const GET_EVENT_CLASS_DETAILS_SUCCESS =
  "GET_EVENT_CLASS_DETAILS_SUCCESS";
export const GET_EVENT_CLASS_DETAILS_ERROR = "GET_EVENT_CLASS_DETAILS_ERROR";

export const GET_CROSS_COUNTRY_REQ = "GET_CROSS_COUNTRY_REQ";
export const GET_CROSS_COUNTRY_SUCCESS = "GET_CROSS_COUNTRY_SUCCESS";
export const GET_CROSS_COUNTRY_ERROR = "GET_CROSS_COUNTRY_ERROR";

export const DELETE_DRESSAGE_REQ = "DELETE_DRESSAGE_REQ";
export const DELETE_DRESSAGE_SUCCESS = "DELETE_DRESSAGE_SUCCES";
export const DELETE_DRESSAGE_ERROR = "DELETE_DRESSAGE_ERROR";

export const DELETE_EVENT_CLASS_REQ = "DELETE_EVENT_CLASS_REQ";
export const DELETE_EVENT_CLASS_SUCCESS = "DELETE_EVENT_CLASS_SUCCES";
export const DELETE_EVENT_CLASS_ERROR = "DELETE_EVENT_CLASS_ERROR";

export const DELETE_EVENT_QUESTION_REQ = "DELETE_EVENT_QUESTION_REQ";
export const DELETE_EVENT_QUESTION_SUCCESS = "DELETE_EVENT_QUESTION_SUCCES";
export const DELETE_EVENT_QUESTION_ERROR = "DELETE_EVENT_QUESTION_ERROR";

export const GET_DRESSAGE_REQUEST = "GET_DRESSAGE_REQUEST";
export const GET_DRESSAGE_SUCCESS = "GET_DRESSAGE_SUCCESS";
export const GET_DRESSAGE_ERROR = "GET_DRESSAGE_ERROR";

export const GET_SHOW_JUMPING_REQ = "GET_SHOW_JUMPING_REQ";
export const GET_SHOW_JUMPING_SUCCESS = "GET_SHOW_JUMPING_SUCCESS";
export const GET_SHOW_JUMPING_ERROR = "GET_SHOW_JUMPING_ERROR";

export const DELETE_SHOW_JUMPING_REQ = "DELETE_SHOW_JUMPING_REQ";
export const DELETE_SHOW_JUMPING_SUCCESS = "DELETE_SHOW_JUMPING_SUCCESS";
export const DELETE_SHOW_JUMPING_ERROR = "DELETE_SHOW_JUMPING_ERROR";

export const DELETE_CROSS_COUNTRY_REQ = "DELETE_CROSS_COUNTRY_REQ";
export const DELETE_CROSS_COUNTRY_SUCCESS = "DELETE_CROSS_COUNTRY_SUCCESS";
export const DELETE_CROSS_COUNTRY_ERROR = "DELETE_CROSS_COUNTRY_ERROR";

export const CONFIRM_EVENT_REQ = "CONFIRM_EVENT_REQ";
export const CONFIRM_EVENT_SUCCESS = "CONFIRM_EVENT_SUCCESS";
export const CONFIRM_EVENT_ERROR = "CONFIRM_EVENT_ERROR";

export const SOCIAL_SIGN_IN_REQ = "SOCIAL_SIGN_IN_REQ";
export const SOCIAL_SIGN_IN_SUCCESS = "SOCIAL_SIGN_IN_SUCCESS";
export const SOCIAL_SIGN_IN_ERROR = "SOCIAL_SIGN_IN_ERROR";

export const SOCIAL_EXTRA_DETAILS_REQ = "SOCIAL_EXTRA_DETAILS_REQ";
export const SOCIAL_EXTRA_DETAILS_SUCCESS = "SOCIAL_EXTRA_DETAILS_SUCCESS";
export const SOCIAL_EXTRA_DETAILS_ERROR = "SOCIAL_EXTRA_DETAILS_ERROR";

export const UPDATE_EVENT_REQ = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";

export const UPDATE_EVENT_COVER_IMAGE_REQ = "UPDATE_EVENT_COVER_IMAGE_REQ";
export const UPDATE_EVENT_COVER_IMAGE_SUCCESS =
  "UPDATE_EVENT_COVER_IMAGE_SUCCESS";
export const UPDATE_EVENT_COVER_IMAGE_ERROR = "UPDATE_EVENT_COVER_IMAGE_ERROR";

export const DELETE_EVENT_REQ = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export const GET_EVENT_REQ = "GET_EVENT_REQ";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const GET_MY_EVENT_REQ = "GET_MY_EVENT_REQ";
export const GET_MY_EVENT_SUCCESS = "GET_MY_EVENT_SUCCESS";
export const GET_MY_EVENT_ERROR = "GET_MY_EVENT_ERROR";

export const GET_EVENT_BY_ID_REQ = "GET_EVENT_BY_ID_REQ";
export const GET_EVENT_BY_ID_SUCCESS = "GET_EVENT_BY_ID_SUCCESS";
export const GET_EVENT_BY_ID_ERROR = "GET_EVENT_BY_ID_ERROR";

export const UPDATE_EVENT_CLASS_REQ = "UPDATE_EVENT_CLASS_REQ";
export const UPDATE_EVENT_CLASS_SUCCESS = "UPDATE_EVENT_CLASS_SUCCESS";
export const UPDATE_EVENT_CLASS_ERROR = "UPDATE_EVENT_CLASS_ERROR";

export const UPDATE_EVENT_QUESTION_REQ = "UPDATE_EVENT_QUESTION_REQ";
export const UPDATE_EVENT_QUESTION_SUCCESS = "UPDATE_EVENT_QUESTION_SUCCESS";
export const UPDATE_EVENT_QUESTION_ERROR = "UPDATE_EVENT_QUESTION_ERROR";

export const UPDATE_DRESSAGE_REQ = "UPDATE_DRESSAGE_REQ";
export const UPDATE_DRESSAGE_SUCCESS = "UPDATE_DRESSAGE_SUCCESS";
export const UPDATE_DRESSAGE_ERROR = "UPDATE_DRESSAGE_ERROR";

export const UPDATE_CROSS_COUNTRY_REQ = "UPDATE_CROSS_COUNTRY_REQ";
export const UPDATE_CROSS_COUNTRY_SUCCESSS = "UPDATE_CROSS_COUNTRY_SUCCESSS";
export const UPDATE_CROSSS_COUNTRY_ERROR = "UPDATE_CROSSS_COUNTRY_ERROR";

export const UPDATE_CROSS_COUNTRY_FENCES_REQ =
  "UPDATE_CROSS_COUNTRY_FENCES_REQ";
export const UPDATE_CROSS_COUNTRY_FENCES_SUCCESS =
  "UPDATE_CROSS_COUNTRY_FENCES_SUCCESS";
export const UPDATE_CROSS_COUNTRY_FENCES_ERROR =
  "UPDATE_CROSS_COUNTRY_FENCES_ERROR";

export const UPDATE_SHOW_JUMPING_REQ = "UPDATE_SHOW_JUMPING_REQ";
export const UPDATE_SHOW_JUMPING_SUCCESS = "UPDATE_SHOW_JUMPING_SUCCESS";
export const UPDATE_SHOW_JUMPING_ERROR = "UPDATE_SHOW_JUMPING_ERROR";

export const CREATE_SHOW_JUMPING_FENCS_REQ = "CREATE_SHOW_JUMPING_FENCS_REQ";
export const CREATE_SHOW_JUMPING_FENCS_SUCCESS =
  "CREATE_SHOW_JUMPING_FENCS_SUCCESS";
export const CREATE_SHOW_JUMPING_FENCS_ERROR =
  "CREATE_SHOW_JUMPING_FENCS_ERROR";

export const UPDATE_SHOW_JUMPING_FENCES_REQ = "UPDATE_SHOW_JUMPING_FENCES_REQ";
export const UPDATE_SHOW_JUMPING_FENCES_SUCCESS =
  "UPDATE_SHOW_JUMPING_FENCES_SUCCESS";
export const UPDATE_SHOW_JUMPING_FENCES_ERROR =
  "UPDATE_SHOW_JUMPING_FENCES_ERROR";

export const UPDATE_PROFILE_REQ = "UPDATE_PROFILE_REQ";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export const GET_COMPLETE_EVENT_REQ = "GET_COMPLETE_EVENT_REQ";
export const GET_COMPLETE_EVENT_SUCCESS = "GET_COMPLETE_EVENT_SUCCESS";
export const GET_COMPLETE_EVENT_ERROR = "GET_COMPLETE_EVENT_ERROR";

export const GET_CLASS_BY_EVENT_REQ = "GET_CLASS_BY_EVENT_REQ";
export const GET_CLASS_BY_EVENT_SUCCESS = "GET_CLASS_BY_EVENT_SUCCESS";
export const GET_CLASS_BY_EVENT_ERROR = "GET_CLASS_BY_EVENT_ERROR";

export const GET_LEADERBOARD_REQ = "GET_LEADERBOARD_REQ";
export const GET_LEADERBOARD_SUCCESS = "GET_LEADERBOARD_SUCCESS";
export const GET_LEADERBOARD_ERROR = "GET_LEADERBOARD_ERROR";

export const GET_NOTIFICATION_REQ = "GET_NOTIFICATION_REQ";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

export const DELETE_USER_CLASS_REQ = "DELETE_USER_CLASS_REQ";
export const DELETE_USER_CLASS_SUCCESS = "DELETE_USER_CLASS_SUCCESS";
export const DELETE_USER_CLASS_ERROR = "DELETE_USER_CLASS_ERROR";

export const UPDATE_USER_CLASS_REQ = "UPDATE_USER_CLASS_REQ";
export const UPDATE_USER_CLASS_SUCCESS = "UPDATE_USER_CLASS_SUCCESS";
export const UPDATE_USER_CLASS_ERROR = "UPDATE_USER_CLASS_ERROR";

export const DELETE_ACCOUNT_REQ = "DELETE_ACCOUNT_REQ";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";

export const CREATE_SUBSCRIPTION_REQ = "CREATE_SUBSCRIPTION_REQ";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_ERROR = "CREATE_SUBSCRIPTION_ERROR";

export const CREATE_LINK_REQ='CREATE_LINK_REQ';
export const CREATE_LINK_SUCCESS='CREATE_LINK_SUCCESS';
export const CREATE_LINK_ERROR='CREATE_LINK_ERROR'

export const CREATE_ACCOUNT_REQ='CREATE_ACCOUNT_REQ';
export const CREATE_ACCOUNT_SUCCESS='CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR='CREATE_ACCOUNT_ERROR';

export const CHECK_ACCOUNT_REQ='CHECK_ACCOUNT_REQ';
export const CHECK_ACCOUNT_SUCCESS='CHECK_ACCOUNT_SUCCESS';
export const CHECK_ACCOUNT_ERROR='CHECK_ACCOUNT_ERROR';

export const CHANGE_PASSWORD_REQ='CHANGE_PASSWORD_REQ';
export const CHANGE_PASSWORD_SUCCESS='CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR='CHANGE_PASSWORD_ERROR';

export const CANCEL_SUBSCRIPTION='CANCEL_SUBSCRIPTION'

export const GET_SUBSCRIPTION_PLANE_REQ='GET_SUBSCRIPTION_PLANE_REQ'
export const GET_SUBSCRIPTION_PLANE_SUCCESS='GET_SUBSCRIPTION_PLANE_SUCCESS'
export const GET_SUBSCRIPTION_PLANE_ERROR='GET_SUBSCRIPTION_PLANE_ERROR'