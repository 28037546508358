import React, { Component } from "react";
import ButtonForm from "../../components/button/Button";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import VerficationCode from "../../store/actions/AuthAction";
import { AuthAction } from "../../store/actions";
import { verificationValdations } from "../../config/schemas";
import ReactCodeInput from "react-code-input";
import { Formik, Form } from "formik";
import FPContainer from "../../components/containers/FPContainer";
import BackButton from "../../components/button/BackButton";
import { BiArrowBack } from "react-icons/bi";
import Image from "../../components/image";
import DefaultButton from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";
import { Row, Col } from "antd";
import { Images, Fonts, Colors } from "../../config";
import { AuthMiddleware } from "../../store/middlewares";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      email: null,
      error: "",
    };
  }

  componentDidMount() {
    if (this.props.history.location.state != undefined) {
      if (this.props.history.location.state.email != undefined) {
        this.setState({
          email: this.props.history.location.state.email,
        });
      } else {
        this.props.history.push({
          pathname: "/forget-password",
        });
      }
    } else {
      this.props.history.push({
        pathname: "/forget-password",
      });
    }
  }

  handleErrors = (value) => {
    let error = "";
    if (value == 0) {
      error = "Please enter the code ";
    } else if (value < 4 && value > 0) {
      error = "Incomplete Code ";
    } else {
      error = "";
    }
    this.setState({ error: error });
    return error;
  };
  handleChanged = (val) => {
    this.setState({
      code: val,
      email: this.props.location.state.email,
    });
    this.handleErrors(val);
  };

  redirectNext = () => {
    this.props.history.push({
      pathname: "/reset-password",
      state: {
        email: this.state.email,
      },
    });
  };
  apiCall = () => {
    let isValid = this.state.error;
    if (isValid == "") {
      this.props.sendCode(
        {
          email: this.state.email,
          code: this.state.code,
        },
        () => this.redirectNext()
      );
    } else {
      
    }
  };

  render() {
    if (!this.props?.history?.location?.state?.email) {
      return <Redirect to="signup" />;
    }
    return (
      <FPContainer>
        <Row justify="center" gutter={[0, 40]}>
          <Col xs={18} md={8} lg={10} xl={6} align="middle">
            <Image src={logo} />
          </Col>
          <Col xs={24} sm={16} md={24} lg={16} xl={19}>
            <Row justify="center" align="middle">
              <Col xs={4} sm={3} md={3} lg={2}>
                <Link to="/forgot-password" style={{ color: "#707070" }}>
                  <BackButton icon={<BiArrowBack color="white" size={20} />} />
                </Link>
              </Col>
              <Col xs={10} sm={10} md={7} lg={6} align="start">
                <h1 style={{ color: Colors.White }}>Enter Code</h1>
              </Col>
              <Col md={18} lg={24}>
                <p style={{ color: Colors.White }}>
                  Please enter the OTP Code received on your email address
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <ReactCodeInput onChange={this.handleChanged} />
          </Col>
          <Col span={20}>
            <span style={{ color: "#f44336" }}>{this.state.error}</span>
          </Col>
          <Col xs={13} sm={10} md={8} lg={8} xl={6} align="middle">
            <ButtonForm
              onClick={() => {
                this.apiCall();
              }}
              loading={this.props.loader}
            >
              Continue
            </ButtonForm>
          </Col>
        </Row>
      </FPContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.email,
    loader: state?.AuthReducer.loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // sendMail: (payload) => {
    //   dispatch(AuthMiddleware.ResetPassword(payload));
    // },
    sendCode: (payload, cb) => {
      dispatch(AuthAction.VerficationCode(payload, cb));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
