import React, { Component } from "react";
import { Row, Col, TimePicker } from "antd";
import { BiPlusCircle } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";

import Button from "../../components/button/Button";
import DefaultInput from "../../components/input/Input";
import ImageUpload from "../../components/uploadimage";
import { Formik, Form } from "formik";
import { Metrix, Images, Fonts, Colors } from "../../config";
import { showJumpingValidation } from "../../config/schemas";
import BackButton from "../../components/button/BackButton";
import { BiArrowBack } from "react-icons/bi";
import SelectInput from "../../components/input/select";
import { connect } from "react-redux";
import EventAction from "../../store/actions/EventAction";
import Select, { components } from "react-select";
import EventHeading from "../../components/eventsHeading";
import HorizontalLine from "../../components/eventsHeading/horizontalLine";
import AddBtnWithTitle from "../../components/addWithTittle";
import ShowJumpingListing from "../../components/showJumpingListing";
import moment from "moment";
class Showjumping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverImage: "",
      uploadFile: "",
      judge: "",
      setClassId: "",
      errorForUploadImage: false,
      errorForUploadFile: false,
      errorForJudge: false,
      optimumTime: "",
      eventName: "",
      noOfFencs: "",
      values: {},
      fieldArr: [],
      isSubmit: false,
      jumpingIndex: "",
      fileObj: {},
      timeValue: moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"),
      fileDoc: {},
      imageUrl: "",
    };
  }
  uploadImage = (e) => {
    this.setState({
      coverImage: e.originFileObj,
      errorForUploadImage: false,
    });
  };
  onChangeUploadFile = (e) => {
    this.setState({
      uploadFile: e,
      errorForUploadFile: false,
    });
  };
  getJudge = (value) => {
    const { searchJudge } = this.props;
    searchJudge(value);
    this.setState({
      errorForJudge: false,
    });
  };
  componentDidMount() {
    const { getEventClass, getShowJumping, location } = this.props;
    const eventId = localStorage.getItem("eventId");
    getEventClass({
      eventId: location.state?.isUpdate
        ? location.state.eventDetail?.id
        : eventId,
    });
    getShowJumping({
      eventId: location.state?.isUpdate
        ? location.state.eventDetail?.id
        : eventId,
    });
  }
  componentDidUpdate(preprops, preState) {
    if (preState.fileObj !== this.state.fileObj) {
      const event = {
        target: {
          name: "coverImage",
          value: this.state.fileObj,
        },
      };
      this.formikHandleRef(event);
    }
    if (preState.fileDoc !== this.state.fileDoc) {
      // const event = {
      //   target: {
      //     name: "eventClassId",
      //     value:
      //       this.state.file,
      //   }
      const event = {
        target: {
          name: "document",
          value: this.state.fileDoc,
        },
      };
      this.formikHandleRef(event);
    }
    if (preprops.eventClass !== this.props.eventClass) {
      this.setState({
        setClassId: this.props.eventClass?.[0]?.UserClass?.id,
      });
    }
    if (preState.fences !== this.state.fences) {
      let arr = [];
      if (this.props?.location?.state?.isUpdate) {
        for (let i = 1; i <= this.state.fences; i++) {
          arr.push({
            fenceNum: this.props?.location?.state?.isUpdate
              ? this.props.showJumpingList[this.state.jumpingIndex]
                  ?.ShowJumpingFences[i - 1]?.fenceNum
              : "",
            showJumpingFenceId: this.props?.location?.state?.isUpdate
              ? this.props.showJumpingList[this.state.jumpingIndex]
                  ?.ShowJumpingFences[i - 1]?.id
              : "",
          });
        }
      } else {
        for (let i = 1; i <= this.state.fences; i++) {
          arr.push({
            fenceNum: this.props?.location?.state?.isUpdate
              ? this.props.showJumpingList[this.state.jumpingIndex]
                  ?.ShowJumpingFences[i - 1]?.fenceNum
              : "",
            // showJumpingFenceId: this.props?.location?.state?.isUpdate
            //   ? this.props.showJumpingList[this.state.jumpingIndex]
            //       ?.ShowJumpingFences[i - 1]?.id
            //   : "",
          });
        }
      }

      this.setState({
        fieldArr: arr,
      });
    }
    if (preState.jumpingIndex !== this.state.jumpingIndex) {
      this.createFile();
      this.createDoc();
      const event = {
        target: {
          name: "eventClassId",
          value:
            this.props.showJumpingList?.[this.state.jumpingIndex]
              ?.ShowJumpingClass?.id,
        },
      };
      const e = {
        target: {
          name: "courseLength",
          value:
            this.props.showJumpingList?.[this.state.jumpingIndex]
              ?.course_length,
        },
      };

      const elem = {
        target: {
          name: "fences",
          value:
            this.props.showJumpingList?.[this.state.jumpingIndex]
              ?.ShowJumpingFences?.length,
        },
      };
      const timeEle = {
        target: {
          name: "optimumTime",
          value:
            this.props.showJumpingList?.[this.state.jumpingIndex]?.optimum_time,
        },
      };
      const userEle = {
        target: {
          name: "userId",
          value:
            this.props.showJumpingList?.[this.state.jumpingIndex]?.Judge?.id,
        },
      };
      let d = new Date(); // Creates a Date Object using the clients current time
      if (this.state.jumpingIndex?.toString()) {
        let [hours, minutes, seconds] =
          this.props.showJumpingList?.[
            this.state.jumpingIndex
          ]?.optimum_time?.split(":"); // Using ES6 destructuring
        // "Old" ES5 version

        d.setHours(+hours); // Set the hours, using implicit type coercion
        d.setMinutes(minutes); // You can pass Number or String. It doesn't really matter
        d.setSeconds(seconds);
      }

      // If needed, adjust date and time zone

      // 
      this.setState({
        timeValue: moment(d).format("YYYY-MM-DD  HH:mm:ss"),

        eventName: {
          label:
            this.props.showJumpingList?.[this.state.jumpingIndex]
              ?.ShowJumpingClass?.UserClass?.name,
          value:
            this.props.showJumpingList?.[this.state.jumpingIndex]
              ?.ShowJumpingClass?.id,
        },

        imageUrl:
          this.props.showJumpingList?.[this.state.jumpingIndex]?.image_url,
        judge: {
          label:
            this.props.showJumpingList?.[this.state.jumpingIndex]?.Judge
              ?.firstName,
          value:
            this.props.showJumpingList?.[this.state.jumpingIndex]?.Judge?.id,
        },
      });
      this.formikHandleRef(event);
      this.formikHandleRef(e);
      this.formikHandleRef(elem);
      this.formikHandleRef(timeEle);
      this.formikHandleRef(userEle);
    }
  }
  createFile = async () => {
    if (this.props?.showJumpingList?.[this.state.jumpingIndex]?.image_url) {
      let response = await fetch(
        this.props?.showJumpingList?.[this.state.jumpingIndex]?.image_url
      );
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "test.jpg", metadata);
      // ... do something with the file or return it
      this.setState({
        fileObj: file,
      });
    }
  };
  createDoc = async () => {
    if (this.props?.showJumpingList?.[this.state.jumpingIndex]?.file_url) {
      let response = await fetch(
        this.props?.showJumpingList?.[this.state.jumpingIndex]?.file_url
      );
      let data = await response.blob();
      let metadata = {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      };
      let file = new File([data], "test.docx", metadata);
      // ... do something with the file or return it
      this.setState({
        fileDoc: file,
      });
    }
  };
  onChangeTime = (time, timeString) => {
    this.setState({
      optimumTime: timeString,
    });
  };
  clearImag = () => {};
  clearThumnail = (fun) => {
    this.clearImag = fun;
  };
  onHandleChanged = (e, index, name, judgArr) => {
    const { fieldArr } = this.state;
    const newArr = [...this.state.fieldArr];
    if (!this.state.jumpingIndex?.toString()) {
      newArr[index] = {
        ...newArr[index],
        [name]: e.target.value,
        showJumpingId: this.state.jumpingIndex?.toString()
          ? this.props.showJumpingList[this.state.jumpingIndex]?.id
          : +localStorage.getItem("showJumpingId"),
      };
      this.setState({
        ...fieldArr,
        fieldArr: newArr,
      });
      return;
    }
    newArr[index] = {
      ...newArr[index],
      [name]: e.target.value,
    };
    this.setState({
      ...fieldArr,
      fieldArr: newArr,
    });
  };
  showJumpingIndex = (index) => {
    
    this.setState({
      jumpingIndex: index,
    });
  };
  render() {
    const isUpdate = this.props?.location.state?.isUpdate;

    const judgeOptions = (this.props.judge || []).map((item) => {
      return {
        value: item.id,
        label: `${item.firstName} 
        (${item.email})
      `,
      };
    });
    const ls = JSON.parse(localStorage.getItem("eventAuth"));
    const customStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma('lightblue');
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "lightblue"
            : isFocused
            ? "#eaeaea"
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? "#eaeaea"
              ? "black"
              : "black"
            : "black",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? "lightblue" : "#eaeaea"),
          },
        };
      },
      input: (styles) => ({ ...styles }),
      placeholder: (styles) => ({ ...styles }),
      singleValue: (styles, { data }) => ({ ...styles }),
    };

    const eventClass = this.props.eventClass;
    const showJumping = this.props.showJumpingList;
    var uniqueResultOne = eventClass.filter(function (obj) {
      return !showJumping.some(function (obj2) {
        return obj.id == obj2.eventClassId;
      });
    });
    const classoptions = (uniqueResultOne || []).map((item) => {
      return { value: item.id, label: item?.UserClass?.name };
    });
    const marginTop = { marginTop: "1rem" };
    const { isLoading } = this.props;
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <AiOutlineSearch />
          </components.DropdownIndicator>
        )
      );
    };
    
    return (
      <>
        <Row justify="start">
          <Col span={24}>
            {!this.state.isSubmit ? (
              <EventHeading
                arrowBtn={true}
                goBack={() =>
                  this.props.history.push({
                    pathname: isUpdate
                      ? "/update/dressage"
                      : "/create-event/dressage",
                    state: {
                      isUpdate,
                      eventDetail: this?.props?.location?.state?.eventDetail,
                    },
                  })
                }
                title={isUpdate ? "Update Show Jumping" : "Show Jumping"}
              />
            ) : (
              <EventHeading
                arrowBtn={true}
                goBack={() => {
                  this.setState({
                    isSubmit: false,
                  });
                }}
                title={isUpdate ? "Update Show Jumping" : "Show Jumping"}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <HorizontalLine width="85%" />
          </Col>
        </Row>
        <Formik
          initialValues={{
            userId: "",
            eventClassId: "",
            optimumTime: this.state.timeValue,
            courseLength: "",
            fences: "",
            coverImage: "",
          }}
          validationSchema={showJumpingValidation}
          onSubmit={(values, { resetForm }) => {
            const obj = { ...values };
            if (!obj.coverImage) {
              delete obj.coverImage;
            }
            if (!obj.document) {
              delete obj.document;
            }
            !this.state.isSubmit &&
              !this.state.jumpingIndex?.toString() &&
              this.props.createShowJumping(obj, () =>
                this.setState({
                  show1: true,
                  judge: "",
                  eventName: "",
                  isSubmit: true,
                  fences: values.fences,
                })
              );
            !this.state.isSubmit &&
              this.state.jumpingIndex?.toString() &&
              this.props.updateShowJumping(
                obj,
                {
                  showJumpingId:
                    this.props.showJumpingList[this.state.jumpingIndex]?.id,
                },
                () => {
                  this.setState({
                    isSubmit: true,
                    fences: obj.fences,
                  });
                }
              );
            this.clearImag();
            this.afterChange = resetForm;
          }}
          render={({ values, touched, handleChange, errors }) => {
            this.formikHandleRef = handleChange;
            
            return (
              <Form style={{ width: "85%", margin: "auto" }}>
                <Row justify="start" gutter={[40, 20]}>
                  {!this.state.isSubmit && (
                    <>
                      <Col lg={5} sm={12} xs={24} md={12}>
                        <label className="label-alignlf">Class Name</label>
                        <Select
                          options={classoptions}
                          className="userSelect"
                          placeholder="Class Name"
                          name="eventClassId"
                          value={this.state.eventName}
                          onChange={(e) => {
                            this.setState({
                              eventName: e,
                            });
                            let event = {
                              target: {
                                name: "eventClassId",
                                value: e.value,
                              },
                            };
                            handleChange(event);
                          }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.eventClassId &&
                            touched.eventClassId &&
                            errors.eventClassId}
                        </label>
                      </Col>
                      <Col lg={5} sm={12} xs={24} md={12} align="middle">
                        <p className="label-alignlf">Optimum Time</p>
                        <TimePicker
                          placeholder="Select time"
                          className="time-picker"
                          name="optimumTime"
                          showNow={false}
                          allowClear={false}
                          value={moment(this.state.timeValue)}
                          onChange={(e, timeString) => {
                            
                            this.setState({
                              timeValue: moment(e).format(
                                "YYYY-MM-DD  HH:mm:ss"
                              ),
                            });
                            let event = {
                              target: {
                                name: "optimumTime",
                                value: timeString,
                              },
                            };
                            handleChange(event);
                          }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.optimumTime &&
                            touched.optimumTime &&
                            errors.optimumTime}
                        </label>
                      </Col>{" "}
                      <Col lg={5} sm={12} xs={24} md={12}>
                        <label className="label-alignlf">Course Length</label>
                        <DefaultInput
                          placeholder="Course Length"
                          name="courseLength"
                          style={marginTop}
                          value={values.courseLength}
                          onChange={handleChange}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.courseLength &&
                            touched.courseLength &&
                            errors.courseLength}
                        </label>
                      </Col>
                      <Col lg={5} sm={12} xs={24} md={12}>
                        <label className="label-alignlf">No Of Fences</label>
                        <DefaultInput
                          placeholder="No Of Fences"
                          name="fences"
                          style={marginTop}
                          value={values.fences}
                          onChange={(e) => {
                            const event = {
                              target: {
                                value: e.target.value,
                                name: "fences",
                              },
                            };
                            handleChange(event);
                          }}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.fences && touched.fences && errors.fences}
                        </label>
                      </Col>
                      <Col lg={4} sm={24} xs={24} md={24}>
                        <label className="label-alignlf showjumping-label">
                          Judge
                        </label>
                        <Select
                          className="userSelect"
                          name="userId"
                          value={this.state.judge}
                          components={{ DropdownIndicator }}
                          onChange={(e) => {
                            this.setState({
                              judge: e,
                            });
                            let event = {
                              target: {
                                name: "userId",
                                value: e.value,
                              },
                            };
                            handleChange(event);
                          }}
                          options={judgeOptions}
                          styles={customStyles}
                          onKeyDown={(e) => this.getJudge(e.target.value)}
                        />
                        <label style={{ color: Colors.Red }}>
                          {errors.userId && touched.userId && errors.userId}
                        </label>
                      </Col>
                      <Col lg={13} xl={12} md={12} xs={24} sm={24}>
                        <Row justify="start" gutter={[10, 20]}>
                          <Col lg={9} md={12} xs={24}>
                            <ImageUpload
                              style={{ borderRadius: "10px" }}
                              width="100%"
                              btnText="Upload Map Image"
                              name="coverImage"
                              type="image"
                              style={marginTop}
                              value={values.coverImage}
                              initialPreview={this.state.imageUrl}
                              clearUpload={this.clearThumnail}
                              onChange={(e) => {
                                let event = {
                                  target: {
                                    name: "coverImage",
                                    value: e.originFileObj,
                                  },
                                };
                                handleChange(event);
                              }}
                            />
                            <label style={{ color: Colors.Red }}>
                              (size 300 x 600) Max 10Mb
                            </label>
                            {/* <p style={{ color: Colors.Red }}>
                                {errors.coverImage &&
                                  touched.coverImage &&
                                  errors.coverImage}
                              </p> */}
                          </Col>
                          {/* <Col lg={10} md={12} xs={24}>
                            <ImageUpload
                              width="100%"
                              btnText="Upload Description File"
                              name="document"
                              type="document"
                              value={values.document}
                              style={marginTop}
                              onChange={(e) => {
                                
                                let event = {
                                  target: {
                                    name: "document",
                                    value: e,
                                  },
                                };
                                handleChange(event);
                              }}
                            />
                            <label style={{ color: Colors.Red }}>
                              Pdf / word Max 50Mb
                            </label>
                            <p>{values?.document?.name}</p>
                            
                          </Col> */}
                          {!this.state.isSubmit && (
                            <Col lg={12} xl={12} sm={24} xs={24} md={12}>
                              {/* <label style={{ visibility: "hidden" }}>sasassaa</label> */}
                              <AddBtnWithTitle
                                loading={isLoading}
                                htmlType="submit"
                                title="Add"
                                icon={() => <BiPlusCircle />}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </>
                  )}

                  {this.state.isSubmit &&
                    this.state.fieldArr?.length &&
                    this.state.fieldArr.map((item, index) => {
                      return (
                        <Col lg={4} sm={12} xs={24} md={12}>
                          <DefaultInput
                            placeholder="Fence Num"
                            name="fenceNum"
                            style={marginTop}
                            value={this.state.fieldArr?.[index]?.fenceNum}
                            onChange={(e) => {
                              this.onHandleChanged(e, index, "fenceNum");
                            }}
                          />
                        </Col>
                      );
                    })}
                  {this.state.isSubmit && this.state.fieldArr?.length && (
                    <AddBtnWithTitle
                      style={{ marginTop: "1rem", width: "20%" }}
                      loading={isLoading}
                      onClick={() =>
                        !this.state.jumpingIndex?.toString()
                          ? this.props.createShowJumpingFencs(
                              this.state.fieldArr,
                              () => {
                                this.setState({
                                  isSubmit: false,
                                  jumpingIndex: "",
                                });
                              },
                              () => window.location.reload()
                            )
                          : this.props.updateShowJumpingFences(
                              this.state.fieldArr,
                              this.props.showJumpingList[
                                this.state.jumpingIndex
                              ]?.id,

                              () => {
                                this.setState({
                                  isSubmit: false,
                                  jumpingIndex: "",
                                  fileDoc: {},
                                  fileObj: {},
                                });
                              },
                              () => window.location.reload()
                            )
                      }
                      title="Add"
                      icon={() => <BiPlusCircle />}
                    />
                  )}
                </Row>
              </Form>
            );
          }}
        />

        <Row>
          <Col span={24}>
            <HorizontalLine width="85%" />
          </Col>
        </Row>
        <Row style={{ width: "85%", margin: "auto" }}>
          <Col span={24}>
            {!this.state.isSubmit && (
              <ShowJumpingListing
                deletShowJumping={this.props.deletShowJumping}
                item={this.props.showJumpingList}
                isUpdate={isUpdate}
                showJumpingEdit={this.showJumpingIndex}
              />
            )}
          </Col>
        </Row>
        <Row justify="center" style={{ marginBottom: "50px" }}>
          <Col lg={4} md={8} sm={22} xs={22} xl={4}>
            <Button
              disabled={
                this.props.showJumpingList?.length !==
                  this.props.eventClass?.length || this.state.isSubmit
              }
              onClick={() =>
                !isUpdate
                  ? this.props.history.push("/create-event/crosscountry")
                  : this.props.history.push({
                      pathname: "/update/crosscountry",
                      state: {
                        isUpdate,
                        eventDetail: this.props.location?.state?.eventDetail,
                      },
                    })
              }
            >
              Continue
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    createShowJumping: (payload, cb) =>
      dispatch(EventAction.createShowJumpingReq(payload, cb)),
    searchJudge: (payload) => {
      dispatch(EventAction.searchJudgeRequest(payload));
    },
    getEventClass: (eventId) => {
      dispatch(EventAction.getEventClassReq(eventId));
    },
    getShowJumping: (eventId) =>
      dispatch(EventAction.getShowJumpingReq(eventId)),
    deletShowJumping: (payload) =>
      dispatch(EventAction.deleteShowJumpingReq(payload)),
    createShowJumpingFencs: (payload, cb, callback) =>
      dispatch(EventAction.createShowJumpingFencsReq(payload, cb, callback)),
    updateShowJumping: (payload, showjumpingId, cab) => {
      dispatch(EventAction.updateShowJumpingReq(payload, showjumpingId, cab));
    },
    updateShowJumpingFences: (payload, showjumpingId, cab, clearImage) =>
      dispatch(
        EventAction.updateShowJumpingFencsReq(
          payload,
          showjumpingId,
          cab,
          clearImage
        )
      ),
  };
};
export default connect(
  (storeState) => ({
    tests: storeState?.EventReducer?.tests,
    eventClass: storeState?.EventReducer?.eventClass,
    judge: storeState?.EventReducer.judge,
    showJumpingList: storeState?.EventReducer?.showJumping,
    isLoading: storeState?.EventReducer?.isLoading,
  }),
  (mapStateToProps, mapDispatchToProps)
)(Showjumping);
