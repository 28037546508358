import React, { Component } from "react";
import ManageClassCard from "../../components/manageClassCard";
import { connect } from "react-redux";
import EventAction from "../../store/actions/EventAction";
import { Col, Row } from "antd";
import ClassAddCard from "../../components/manageClassCard/classAddCard";

class ManageClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "",
      visible: false,
      isUpdate: false,
      id: "",
    };
  }
  componentDidMount() {
    this.props.getUserClass();
  }
  updateHandle = (value, id) => {

    this.setState({
      className: value,
      visible: true,
      isUpdate: true,
      id,
    });
  };
  onHandleChange = (e) => {
    this.setState({ className: e.target.value });
  };
  onCancelModal = () => {
    this.setState({
      visible: false,
      isUpdate: false,
      id: "",
      className: "",
    });
  };
  onOpenModal = () => {
    this.setState({
      visible: true,
    });
  };
  render() {
    return (
      <Row>
        <Col lg={18} xl={18} md={24} sm={24} xs={24}>
          <ManageClassCard
            deleteUserClass={this.props.deleteUserClass}
            userClasses={this.props.userClasses}
            updateHandle={this.updateHandle}
          />
        </Col>
        <Col
          lg={6}
          xl={6}
          md={24}
          sm={24}
          xs={24}
          style={{ padding: "3rem" }}
        >
          <ClassAddCard
            className={this.state.className}
            onHandleChange={this.onHandleChange}
            onCancelModal={this.onCancelModal}
            onOpenModal={this.onOpenModal}
            visible={this.state.visible}
            isUpdate={this.state.isUpdate}
            id={this.state.id}
            {...this.props}
          />
        </Col>
      </Row>
    );
  }
}
function mapStateToProps(store) {
  return {
    userClasses: store.EventReducer.userClass,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getUserClass: () => dispatch(EventAction.getClassRequest()),
    deleteUserClass: (id) => dispatch(EventAction.deleteUserClassReq(id)),
    updateUserClass: (payload, id, cb) =>
      dispatch(EventAction.updateUserClassReq(payload, id, cb)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageClass);
