import React, { useState } from "react";
import { Image, Menu } from "antd";
import horse from "../../assets/images/horse.svg";
import {
  AppstoreOutlined,
  HomeOutlined,
  CalendarOutlined,
  SettingFilled,
  HomeFilled,
} from "@ant-design/icons";

import { RiBarChart2Fill, RiSettings5Fill } from "react-icons/ri";
import { BiCalendarStar } from "react-icons/bi";
import "./navbar.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/app_logo.svg";
import { useSelector } from "react-redux";
import { Badge } from "antd";

function NavbarContainer(props) {
  const [current, setCurrent] = useState("home");
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const splited = window.location.pathname.split("/");
  const userSubDetail = JSON.parse(localStorage.getItem("eventAuth"));
  const data = useSelector((store) => store.UserReducer.data);

  return (
    <div>
      <Menu
        onClick={handleClick}
        selectedKeys={[`/${splited[splited.length - 1]}`]}
        mode="horizontal"
      >
        <Menu.Item className="logo">
          <Link to="/">
            {" "}
            <img src={Logo} style={{ height: 50 }} />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/"
          icon={
            current === "/" ? (
              <HomeFilled color="#00A99D" size={24} />
            ) : (
              <HomeOutlined size={24} />
            )
          }
        >
          Home
          <Link to="/" />
        </Menu.Item>
        <Menu.Item
          key={
            splited[splited.length - 1] === "my-event"
              ? "/my-event"
              : "/riderDetail"
          }
          icon={<img src={horse} />}
        >
          <Link to="/my-event" />
          My Event
        </Menu.Item>
        <Menu.Item key="/leaderboard" icon={<RiBarChart2Fill />}>
          <Link to="/leaderboard">Leaderboard</Link>
        </Menu.Item>
        <Menu.Item key="/create-event" icon={<BiCalendarStar />}>
          Create Event
          <Link
            to={
              !userSubDetail?.user?.Subscriptions?.length
                ? "/subscription"
                : "/create-event"
            }
          />
        </Menu.Item>
        <Menu.Item key="/settings" icon={<SettingFilled />}>
          <Link to="/settings">
            <Badge
              dot={data.status === "pending" || data.status === "inactive"}
            >
              Settings
            </Badge>
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}
export default NavbarContainer;
