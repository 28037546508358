import React from "react";
import "./style.css";
import DeletComponent from "../deleteComponent";
import { Tooltip } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { Row, Col } from "antd";
function ShowJumpingListing(props) {
  const { item, deleteItem, isUpdate, crossCountryEdit } = props;
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="showjumping-grid">
        <tr>
          <th className="table-heading">Class Name</th>
          <th className="table-heading">Optimum Time</th>
          <th className="table-heading">Course Length</th>
          <th className="table-heading">no. Of Fences</th>
          <th className="table-heading">Name of judge</th>
          <th className="table-heading">Map Image</th>
          <th className="table-heading">Description file</th>
        </tr>
        {(item || []).map((data, index) => {
          let imgUrl = data.image_url;
          const imgSplit = imgUrl.split("/");
          let fileName = data?.file_url;

          return (
            <tr>
              <td className="table-data">
                {data?.CrossCountryClass?.UserClass?.name}
              </td>
              <td className="table-data">{data?.optimum_time}</td>
              <td className="table-data">{data?.course_length}</td>
              <td className="table-data">{data?.CrossCountryFences?.length}</td>
              <td className="table-data">
                <Tooltip
                  title={data?.CrossCountryFences?.map((itreate) => {
                    return (
                      <p>
                        {itreate.FenceJudge?.firstName}{" "}
                        {itreate.FenceJudge?.lastName}
                      </p>
                    );
                  })}
                >
                  <p style={{ textDecoration: "underline" }}>Detail</p>
                </Tooltip>
              </td>
              <td className="table-data">
                <a href={imgUrl} target="_blank">
                  View Image
                </a>{" "}
              </td>
              <td className="table-data">
                {" "}
                <a href={fileName} target="_blank">
                  {fileName ? "View Document" : "-"}
                </a>
              </td>
              <td className="table-data">
                <Row align='middle'>
                  <Col span={4}>
                    {" "}
                    {isUpdate && (
                      <FaRegEdit
                        onClick={() => crossCountryEdit(index)}
                        color="#00A99D"
                        size={25}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    <DeletComponent
                      onClick={() => deleteItem({ crossCountryId: data.id })}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}
export default ShowJumpingListing;
