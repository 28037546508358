import React, { useState } from "react";
import { Row, Col } from "antd";
import "./styles.css";
import ButtonForm from "../../components/button/Button";
import Modal from "../../components/modal/modal";
import EventAction from "../../store/actions/EventAction";
import { useDispatch } from "react-redux";
import info from "../info";

function ClassAddCard({
  className,
  onHandleChange,
  visible,
  onOpenModal,
  onCancelModal,
  isUpdate,
  id,
  userClasses,
}) {
  const dispatch = useDispatch();
  const onSubmitModal = () => {
    let userId = JSON.parse(localStorage.getItem("eventAuth"));
    console.log("class==>", userClasses);
    const filterClass = userClasses.filter(
      (item) => item?.name?.toLowerCase() === className.toLowerCase()
    );
    console.log("filterClass==>", filterClass);
    if (filterClass.length) {
      return info("error", "class name already exists");
    }

    // const filterClass = (this.props.userClass || []).filter((item) => {
    //   return item?.name?.toLowerCase() === this.state.className.toLowerCase();
    // });
    // if (filterClass?.length) {
    //   return info("error", "class name already exists");
    // }
    //api
    !isUpdate
      ? dispatch(
          EventAction.createUserClassReq(
            { name: className, userId: userId.user.id },
            () => onCancelModal()
          )
        )
      : dispatch(
          EventAction.updateUserClassReq({ name: className }, id, () =>
            onCancelModal()
          )
        );
  };

  return (
    <Row justify="center">
      <Col lg={24} xs={24} sm={12} md={8}>
        <div class="ten">
          <ButtonForm htmlType="submit" onClick={() => onOpenModal()}>
            Add
          </ButtonForm>
        </div>
      </Col>
      <Modal
        onChange={onHandleChange}
        title="Add User Class"
        lable="Class Name"
        // confirmloading={userClassLoading}
        className={className}
        visible={visible}
        closable={true}
        maskClosable={true}
        onSubmitModal={onSubmitModal}
        onCancelModal={onCancelModal}
      />
    </Row>
  );
}
export default ClassAddCard;
