import React from "react";
import { Input } from "antd";
import Styled from "styled-components";
const { Search } = Input;

const StyledSearchButton = Styled(Search)`
& .ant-btn-primary{
    background-color: #00A99D;
    border-radius: 55px;
}
& .ant-input-affix-wrapper{
    border-radius: 23px !important;
}
& .anticon{
    color:white
}
& .ant-input-search-button{
    background-color: #00A99D;
    border-radius:20px !important;
    margin-left:0.5rem
}
 .ant-input-group-addon{
  background-color:transparent !important; 
}
`;
export default function SearchBar(props) {
  return (
    <StyledSearchButton
      {...props}
      placeholder="Search Event"
      allowClear
      size="large"
      onSearch={(value)=>props.onSearch(value)}
      value={props.value}
      onChange={(e)=>props.handleChanged(e)}
    />
  );
}
